import { useState, useEffect } from "react";
import {
    Grid,
    Box,
    TextField,
    Typography,
    Container,
    InputAdornment,
    IconButton,
    Button,
    useMediaQuery,
    useTheme,
    Modal,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useUpdatePasswordChangeMutation } from "@api/onboarding";
import { getDynamicUrl } from "@helpers/funciones";

export const NuevaContraseña = () => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
    const img = getDynamicUrl("right_7.svg");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [uuid, setUuid] = useState<string | null>(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [updatePasswordChange] = useUpdatePasswordChangeMutation();
    const location = useLocation();

    const handleCloseSuccessModal = () => setOpenSuccessModal(false);
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required("Campo requerido")
            .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/, "Formato Incorrecto"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
            .required("Campo requerido"),
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const requestUUID = queryParams.get("request");
        if (requestUUID) {
            setUuid(requestUUID);
        }
    }, [location]);

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!uuid) {
                setOpenErrorModal(true);
                return;
            }

            try {
                await updatePasswordChange({
                    request_id: uuid,
                    password: values.password,
                }).unwrap();
                setOpenSuccessModal(true);
            } catch (error) {
                
                setOpenErrorModal(true);
            }
        },
    });

    const isLengthValid = formik.values.password.length >= 8 && formik.values.password.length <= 20;
    const hasNumber = /[0-9]/.test(formik.values.password);
    const hasSpecialChar = /[!@#$%^&*]/.test(formik.values.password);
    const hasUpperCase = /[A-Z]/.test(formik.values.password);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={8} mt={4}>
                    <Box
                        sx={{
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: 5
                        }}
                    >
                        <Box my={2}>
                            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={700} color={theme?.palette?.primary?.main}>
                                Escribe y confirma tu nueva contraseña
                            </Typography>
                        </Box>

                        <Container maxWidth="sm" sx={{ mt: 2 }}>
                            <form onSubmit={formik.handleSubmit} noValidate>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Nueva contraseña"
                                    type={showPassword ? "text" : "password"}
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ my: 4 }}
                                />

                                <TextField
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label="Confirma contraseña"
                                    type={showConfirmPassword ? "text" : "password"}
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ my: 4 }}
                                />

                                <Box sx={{ mt: 1, mb: 2 }}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Tu contraseña debe contener:
                                    </Typography>
                                    <Typography mt={1} variant="body2" color={isLengthValid ? "green" : "red"} display="flex" alignItems="center">
                                        {isLengthValid ? <CheckIcon /> : <CloseIcon />} &nbsp;Entre 8 y 20 caracteres
                                    </Typography>
                                    <Typography variant="body2" color={hasNumber ? "green" : "red"} display="flex" alignItems="center">
                                        {hasNumber ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter numérico
                                    </Typography>
                                    <Typography variant="body2" color={hasSpecialChar ? "green" : "red"} display="flex" alignItems="center">
                                        {hasSpecialChar ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter especial
                                    </Typography>
                                    <Typography variant="body2" color={hasUpperCase ? "green" : "red"} display="flex" alignItems="center">
                                        {hasUpperCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una mayúscula
                                    </Typography>
                                </Box>

                                <Box textAlign={"center"} mt={6}>
                                    <Button
                                        variant="default"
                                        type="submit"
                                        disabled={!formik.isValid}
                                        sx={{
                                            padding: "10px 24px",
                                            borderRadius: "24px",
                                            textTransform: "none",
                                        }}
                                    >
                                        Continuar
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </Box>
                </Grid>

                {!isMobileOrTablet && (
                    <Grid item xs={12} md={4} textAlign={"center"} bgcolor={theme?.palette?.primary?.dark}>
                    <img src={img} alt="Descripción" style={{ height: "calc(100vh - 76px)" }} />
                  </Grid>
                )}
            </Grid>

            {/* Modal de éxito */}
            <Modal
                open={openSuccessModal}
                onClose={handleCloseSuccessModal}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        boxShadow: 24,
                        p: 4,
                        maxWidth: "400px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6" fontWeight="700" color={theme?.palette?.primary?.main} mb={2}>
                        ¡Contraseña actualizada!
                    </Typography>
                    <Typography variant="body1" mb={3}>
                        Tu contraseña ha sido actualizada exitosamente.
                    </Typography>
                    <Button
                        onClick={handleCloseSuccessModal}
                        variant="default"
                        sx={{
                            borderRadius: "24px",
                            textTransform: "none",
                            px: 4,
                            py: 1,
                        }}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Modal>

            {/* Modal de error */}
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        boxShadow: 24,
                        p: 4,
                        maxWidth: "400px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6" fontWeight="700" color="#F2704F" mb={2}>
                        Error al actualizar
                    </Typography>
                    <Typography variant="body1" mb={3} color={theme?.palette?.primary?.dark}>
                        Hubo un problema al actualizar tu contraseña. Inténtalo de nuevo.
                    </Typography>
                    <Button
                        onClick={handleCloseErrorModal}
                        variant="contained"
                        sx={{
                            backgroundColor: "#F2704F",
                            color: "#fff",
                            borderRadius: "24px",
                            textTransform: "none",
                            px: 4,
                            py: 1,
                        }}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Modal>
        </>
    );
};
