import { HOSTNAMES } from "@helpers/constantes";
import { useEffect } from "react";


const DEFAULT_TITLE = "Finbe";
const DEFAULT_FAVICON = "/fin.svg";

const DynamicMetadata = () => {
  useEffect(() => {
    const hostname = window.location.hostname.replace(/^www\./, ""); 
    const foundHost = HOSTNAMES.find(({ host }) => host === hostname);
    const value = foundHost?.value || "finbe"; 

    const config: Record<string, { title: string; favicon: string }> = {
      lendia: {
        title: "Lendia",
        favicon: "/lendia.svg",
      },
      finbe: {
        title: "Finbe",
        favicon: "/fin.svg",
      },
    };

    const settings = config[value] || { title: DEFAULT_TITLE, favicon: DEFAULT_FAVICON };

    document.title = settings.title;


    const existingFavicon = document.querySelector("link[rel='icon']");
    if (existingFavicon) {
      existingFavicon.setAttribute("href", settings.favicon);
    } else {
      const link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/svg+xml";
      link.href = settings.favicon;
      document.head.appendChild(link);
    }
  }, []);

  return null;
};

export default DynamicMetadata;
