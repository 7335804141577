/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { useEffect } from "react";

const MifielWidget = () => {
  useEffect(() => {
    const loadScript = () => {
      if (!document.getElementById("mifiel-js")) {
        const script = document.createElement("script");
        script.id = "mifiel-js";
        script.src = import.meta.env.VITE_URL_SCRIPT_MIFIEL;
        script.async = true;

        document.body.appendChild(script);
      }
    };

    const initializeMifiel = () => {
      window.mifiel = window.mifiel || [];

      const methods = ["widget"];

      methods.forEach((method) => {
        window.mifiel[method] =
          window.mifiel[method] ||
          function () {
            window.mifiel.push([method].concat(Array.prototype.slice.call(arguments, 0)));
          };
      });
    };

    initializeMifiel();
    loadScript();
  }, []);

  return null;
};

export default MifielWidget;
