import { LoanStatusCatalog } from "@interfaces/catalogs";

export const STATUS_CATALOG: LoanStatusCatalog = {
  // tofu pfae
  registro_datos_personales: {
    code: "CSRUS",
    url: "/producto/creditosimple/registro_datos_personales",
  },
  validacion_telefono: {
    code: "CSVTE",
    url: "/producto/creditosimple/validacion_telefono",
  },
  datos_empresa_pfae: {
    code: "CSDEM",
    url: "/producto/creditosimple/datos_empresa_pfae",
  },
  domicilio_pfae: {
    code: "CSDOE",
    url: "/producto/creditosimple/domicilio_pfae",
  },
  consulta_ciec: {
    code: "CSAEM",
    url: "/producto/creditosimple/consulta_ciec",
  },
  uso_de_credito: {
    code: "CSUDC",
    url: "/producto/creditosimple/uso_de_credito",
  },
  presencia_digital_pfae: {
    code: "CSPDI",
    url: "/producto/creditosimple/presencia_digital_pfae",
  },
  consulta_buro_pfae: {
    code: "CSABA",
    url: "/producto/creditosimple/consulta_buro_pfae",
  },
  // tofu pm
  datos_empresa_pm: {
    code: "CSDEM",
    url: "/producto/creditosimple/datos_empresa_pm",
  },
  presencia_digital: {
    code: "CSPDI",
    url: "/producto/creditosimple/presencia_digital",
  },
  datos_accionista: {
    code: "CSDAL",
    url: "/producto/creditosimple/datos_accionista",
  },
  rfc_curp: {
    code: "CSCDA",
    url: "/producto/creditosimple/rfc_curp",
  },
  domicilio_accionista: {
    code: "CSDAV",
    url: "/producto/creditosimple/domicilio_accionista",
  },
  consulta_buro: {
    code: "CSABA",
    url: "/producto/creditosimple/consulta_buro",
  },
  // waiting room
  waiting_room: {
    code: "CSCOF",
    url: "/producto/creditosimple/waiting_room",
  },
  oferta: {
    code: "CSAAO",
    url: "/producto/creditosimple/oferta",
  },
  waiting_room_revision: {
    code: "CREVC",
    url: "/producto/creditosimple/waiting_room_revision",
  },
  // bofu pfae
  informacion_documentos_pfae: {
    code: "CSBEX",
    url: "/producto/creditosimple/informacion_documentos_pfae",
  },
  avance_expediente_digitalPFAE: {
    code: "CSAED",
    url: "/producto/creditosimple/avance_expediente_digitalPFAE",
  },
  validando_expediente_pfae: {
    code: "CSEDC",
    url: "/producto/creditosimple/validando_expediente_pfae",
  },
  errores_expediente_pfae: {
    code: "CSECI",
    url: "/producto/creditosimple/errores_expediente_pfae",
  },
  completado: {
    code: "CSEDV",
    url: "/producto/creditosimple/completado",
  },
  agenda_visita: {
    code: "CSAVC",
    url: "/producto/creditosimple/agenda_visita",
  },
  confirmacion_domicilio: {
    code: "CSVYC",
    url: "/producto/creditosimple/confirmacion_domicilio",
  },
  confirmacion_visita_agendada: {
    code: "CSVVC",
    url: "/producto/creditosimple/confirmacion_visita_agendada",
  },
  visita_ocular_concluida: {
    code: "CSEVD",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  firma_documentos: {
    code: "CSFIR",
    url: "/producto/creditosimple/firma_documentos",
  },
  bofu_completado: {
    code: "CSVCF",
    url: "/producto/creditosimple/bofu_completado",
  },
  credito_rechazado: {
    code: "CSREC",
    url: "/producto/creditosimple/credito_rechazado",
  },
  rechazado_documentacion: {
    code: "CSRDO",
    url: "/producto/creditosimple/rechazado_documentacion",
  },
  // bofu pm
  check_list: {
    code: "CSBEX",
    url: "/producto/creditosimple/check_list",
  },
  docs_empresa: {
    code: "CDOEM",
    url: "/producto/creditosimple/docs_empresa",
  },
  avance_expediente_digital: {
    code: "CSAED",
    url: "/producto/creditosimple/avance_expediente_digital",
  },
  validando_expediente_pm: {
    code: "CSEDC",
    url: "/producto/creditosimple/validando_expediente_pm",
  },
  errores_expediente_pm: {
    code: "CSECI",
    url: "/producto/creditosimple/errores_expediente_pm",
  },
  actas_identificacion: {
    code: "CSIYA",
    url: "/producto/creditosimple/actas_identificacion",
  },
  expediente_digital_validado: {
    code: "CSEDV",
    url: "/producto/creditosimple/completado",
  },
  expediente_con_incidencia: {
    code: "CSECI",
    url: "/producto/creditosimple/errores_expediente_pm",
  },
};

export const STATUS_TOFU = [
  STATUS_CATALOG.registro_datos_personales.code,
  STATUS_CATALOG.validacion_telefono.code,
  STATUS_CATALOG.datos_empresa_pfae.code,
  STATUS_CATALOG.domicilio_pfae.code,
  STATUS_CATALOG.consulta_ciec.code,
  STATUS_CATALOG.uso_de_credito.code,
  STATUS_CATALOG.presencia_digital_pfae.code,
  STATUS_CATALOG.consulta_buro_pfae.code,
  STATUS_CATALOG.datos_empresa_pm.code,
  STATUS_CATALOG.presencia_digital.code,
  STATUS_CATALOG.datos_accionista.code,
  STATUS_CATALOG.rfc_curp.code,
  STATUS_CATALOG.domicilio_accionista.code,
  STATUS_CATALOG.consulta_buro.code,
];

export const STATUS_WAITING_ROOM = [STATUS_CATALOG.waiting_room.code];

export const STATUS_TOFU_BY_SCREEN_PFAE = {
  registro_datos_personales: [STATUS_CATALOG.registro_datos_personales.code, STATUS_CATALOG.validacion_telefono.code],
  validacion_telefono: [STATUS_CATALOG.validacion_telefono.code],
  datos_empresa_pfae: [
    STATUS_CATALOG.datos_empresa_pfae.code,
    STATUS_CATALOG.domicilio_pfae.code,
    STATUS_CATALOG.consulta_ciec.code,
  ],
  domicilio_pfae: [STATUS_CATALOG.domicilio_pfae.code, STATUS_CATALOG.consulta_ciec.code],
  consulta_ciec: [STATUS_CATALOG.consulta_ciec.code],
  uso_de_credito: [
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  presencia_digital_pfae: [STATUS_CATALOG.presencia_digital_pfae.code, STATUS_CATALOG.consulta_buro_pfae.code],
  consulta_buro_pfae: [STATUS_CATALOG.consulta_buro_pfae.code],
};

export const STATUS_TOFU_BY_SCREEN_PM = {
  registro_datos_personales: [STATUS_CATALOG.registro_datos_personales.code, STATUS_CATALOG.validacion_telefono.code],
  validacion_telefono: [STATUS_CATALOG.validacion_telefono.code],
  datos_empresa_pm: [STATUS_CATALOG.datos_empresa_pm.code, STATUS_CATALOG.consulta_ciec.code],
  consulta_ciec: [STATUS_CATALOG.consulta_ciec.code],
  uso_de_credito: [
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  presencia_digital: [
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  datos_accionista: [
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  rfc_curp: [STATUS_CATALOG.rfc_curp.code, STATUS_CATALOG.domicilio_accionista.code, STATUS_CATALOG.consulta_buro.code],
  domicilio_accionista: [STATUS_CATALOG.domicilio_accionista.code, STATUS_CATALOG.consulta_buro.code],
  consulta_buro: [STATUS_CATALOG.consulta_buro.code],
};

export const STATUS_BOFU_REJECTED = [STATUS_CATALOG.rechazado_documentacion.code];

export const STATUS_BOFU_COMPLETED = [
  STATUS_CATALOG.completado.code,
  STATUS_CATALOG.agenda_visita.code,
  STATUS_CATALOG.confirmacion_domicilio.code,
  STATUS_CATALOG.confirmacion_visita_agendada.code,
  STATUS_CATALOG.visita_ocular_concluida.code,
  STATUS_CATALOG.firma_documentos.code,
  STATUS_CATALOG.bofu_completado.code,
];

export const TAX_SYSTEMS = {
  PM: {
    code: "CS001",
  },
  PFAE: {
    code: "CS002",
  },
};

export const CLIENT_RFC_NOT_VALID = ["XAXX010101000"];

export const DOCUMENTS_CATALOG = {
  acta_constitutiva: {
    code: "DAC",
  },
  identificacion_representante_legal: {
    code: "DIORL",
  },
  acta_adicional: {
    code: "DAA",
  },
  estado_cuenta_bancario: {
    code: "DECB",
  },
  constancia_situacion_fiscal: {
    code: "DCDF",
  },
  comprobante_domicilio: {
    code: "DCD",
  },
  anexo_1: {
    code: "ANX1",
  },
  anexo_2: {
    code: "ANX2",
  },
  solicitud_credito: {
    code: "SDC",
  },
  identificacion_oficial: {
    code: "DIO",
  },
  validacion_identidad: {
    code: "VIDME",
  },
  cierre_anual: {
    code: "EFCA",
  },
  ultimo_cierre_parcial: {
    code: "EFUCP",
  },
  estados_finacieros: {
    code: "EFDEF",
  },
  estado_cuenta_bancario_zona_gris: {
    code: "DECZG",
  },
};

export const PERSON_CATALOG = {
  propietario_real: {
    code: "PTPR",
  },
  representante_legal: {
    code: "PTRL",
  },
  accionista_mayoritario: {
    code: "PTAM",
  },
  representante_legal_accionista_mayoritario: {
    code: "PRLAM",
  },
  representante_legal_propietario_real: {
    code: "PRLPR",
  },
  accionista_mayoritario_propietario_real: {
    code: "PAMPR",
  },
  representante_legal_accionista_mayoritario_propietario_real: {
    code: "PRLAP",
  },
};

export const PERSON_CODES_LEGAL_REPRESENTATIVE = [
  PERSON_CATALOG.representante_legal.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario.code,
  PERSON_CATALOG.representante_legal_propietario_real.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario_propietario_real.code,
];

export const PERSON_CODES_AVAL = [
  PERSON_CATALOG.representante_legal_accionista_mayoritario.code,
  PERSON_CATALOG.accionista_mayoritario_propietario_real.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario_propietario_real.code,
  PERSON_CATALOG.accionista_mayoritario.code,
];

export const PERSON_CODES_PROPIETARIO_REAL = [PERSON_CATALOG.propietario_real.code];

export const PEP_CODES = {
  propio: {
    code: "CPEPP",
    name: "Propio",
  },
  familiar: {
    code: "CPEPF",
    name: "Familiar",
  },
};

export const HOSTNAMES = [
  {
    host: "dev-clientes.lenditgroup.com",
    value: "lendia",
  },
  {
    host: "dev-clientes-finbe.lenditgroup.com",
    value: "finbe",
  },
  {
    host: "clientes.lendia.mx",
    value: "lendia",
  },
  {
    host: "clientes-pyme.finbeabc.com.mx",
    value: "finbe",
  },
  {
    host: "localhost",
    value: "finbe",
  },
];

export const ADDRESS_CODES = {
  particular: {
    code: "CDPA",
  },
  operativo: {
    code: "CDOP",
  },
};
