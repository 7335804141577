import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckIcon from "@mui/icons-material/Check";
import imgDictamen from "@assets/img/iconTap/dicatamen.svg";
import imgEmpresa from "@assets/img/iconTap/empresa.svg";
import imgAval from "@assets/img/iconTap/aval.svg";
import imgPropietario from "@assets/img/iconTap/propietario.svg";

export type ButtonTabLabel = "dictamen" | "empresa" | "aval" | "propietario";

export type ButtonTabStatus = "validado" | "cargado" | "faltante";

interface ButtonTabProps {
  isActive?: boolean | undefined;
  disabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  label?: ButtonTabLabel;
  status?: ButtonTabStatus;
}

const ButtonTab = (props: ButtonTabProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const getLabelText = () => {
    if (props.label === "dictamen") {
      return "Dictamen";
    } else if (props.label === "empresa") {
      return "Empresa";
    } else if (props.label === "aval") {
      return "Aval";
    } else if (props.label === "propietario") {
      return "Propietario";
    }
    return "";
  };

  const RenderStatusLabel = () => {
    const variants = {
      validado: {
        color: "#01A388",
        text: "Validado",
      },
      faltante: {
        color: "#F2704F",
        text: "Faltantes",
      },
      cargado: {
        color: "#A3D4E8",
        text: "Cargado",
      },
    };

    if (!props.status) {
      return null;
    }

    const status_variant = variants[props.status];

    if (status_variant) {
      return (
        <Typography color={status_variant.color} fontSize={isMobileOrTablet ? "9px" : "11px"} mt="0px" fontWeight={500}>
          {status_variant.text}
        </Typography>
      );
    }

    return null;
  };

  const RenderStatusIcon = () => {
    if (props.status === "validado") {
      return <CheckIcon style={{ color: "#01A388", fontSize: "22px", margin: "0px", padding: "0px" }} />;
    } else if (props.status === "faltante") {
      return <WarningAmberIcon style={{ color: "#F2704F", fontSize: "22px", margin: "0px", padding: "0px" }} />;
    } else if (props.status === "cargado") {
      return <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px", padding: "0px" }} />;
    }

    return null;
  };

  const RenderSectionIcon = () => {
    if (props.status || !props.label) {
      return null;
    }

    let icon_params = null;

    if (props.label === "dictamen") {
      icon_params = {
        icon: imgDictamen,
        size: "18px",
      };
    } else if (props.label === "empresa") {
      icon_params = {
        icon: imgEmpresa,
        size: "18px",
      };
    } else if (props.label === "aval") {
      icon_params = {
        icon: imgAval,
        size: "16px",
      };
    } else if (props.label === "propietario") {
      icon_params = {
        icon: imgPropietario,
        size: "24px",
      };
    }

    if (icon_params) {
      return (
        <img
          src={icon_params.icon}
          alt="Icono"
          className="icon"
          style={{
            width: icon_params.size,
            height: icon_params.size,
            transition: "filter 0.3s",
            marginTop: "5px",
            filter: props.disabled
              ? "brightness(0) saturate(100%) invert(75%)"
              : props.isActive
              ? "brightness(0) invert(1)"
              : "",
          }}
        />
      );
    }

    return null;
  };

  const RenderButton = () => {
    return (
      <Button
        onClick={props.onClick}
        disabled={props.disabled}
        sx={{
          backgroundColor: props.isActive ? theme?.palette?.primary?.dark : "transparent",
          color: props.isActive ? theme?.palette?.otherColors?.tabText : theme?.palette?.primary?.dark,
          borderRadius: "8px",
          textTransform: "none",
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          height: "70px",
          width: {
            xs: "100%",
            sm: "100%",
          },
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: theme?.palette?.primary?.dark,
            color: theme?.palette?.otherColors?.tabText,
            "& .icon": {
              filter: "brightness(0) invert(1)",
            },
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <RenderStatusIcon />
          {getLabelText()}
          <RenderSectionIcon />
          <RenderStatusLabel />
        </Box>
      </Button>
    );
  };

  if (props.disabled && props.onClickDisabled) {
    return (
      <Box onClick={props.onClickDisabled} sx={{ cursor: "pointer", display: "flex", height: "100%", width: "100%" }}>
        <RenderButton />
      </Box>
    );
  }

  return <RenderButton />;
};

export default ButtonTab;
