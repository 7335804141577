import {
  Box,
  BoxProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface CustomCheckListProps extends BoxProps {
  title: string;
  items: Array<string>;
  onClickInfo?: (id: string | undefined) => void;
}

export const CustomCheckList = (props: CustomCheckListProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{ backgroundColor: theme?.palette?.phoneInput?.main, borderRadius: "8px", ...props.sx }}
      px={2}
      py={1}
      display={"flex"}
      flexDirection={"column"}
    >
      <Typography
        textAlign={"left"}
        variant={isMobileOrTablet ? "body2" : "body1"}
        fontWeight={theme?.palette?.fontWeights?.semibold}
        color={theme?.palette?.primary?.dark}
      >
        {props.title}
        {props.onClickInfo && (
          <IconButton
            sx={{ margin: "-5px 2px 0px 2px", padding: 0 }}
            onClick={() => props.onClickInfo && props.onClickInfo(props.id)}
          >
            <InfoOutlinedIcon color={"error"} sx={{ width: 15, height: 15 }} />
          </IconButton>
        )}
      </Typography>
      <List>
        {props.items.map((item, index) => {
          return (
            <ListItem key={index} sx={{ p: 0 }}>
              <ListItemIcon sx={{ minWidth: "15px" }}>
                <Box width={8} height={8} sx={{ backgroundColor: theme?.palette?.primary?.main }}></Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color={theme?.palette?.primary?.dark} variant={isMobileOrTablet ? "body2" : "body1"}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
