/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { setCompanyClientReference } from "@store/slices/bofuSlice";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { usePatchReferencesMutation, usePostReferencesMutation } from "@api/referenciasApi";
import { useGetReferenceTypesQuery } from "@api/catalogs";
import { useGetClientsSuppliersByRfcQuery } from "@api/proveedorClienteApi";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl, getOrdinalText } from "@helpers/funciones";
import { CLIENT_RFC_NOT_VALID } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const ReferenciasClientes = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const img = getDynamicUrl("img_tofu/img_tofu_11.svg");

  const companyData = useSelector((state: RootState) => state.bofu.digital_file.company);
  const client = useSelector((state: RootState) => state.register.personal_data);
  const company = useSelector((state: RootState) => state.register.company);

  const [triggerPostReferences] = usePostReferencesMutation();
  const [triggerPatchReferences] = usePatchReferencesMutation();
  const proveedorReferenceType = useGetReferenceTypesQuery();
  const clientReferences = useGetClientsSuppliersByRfcQuery(String(company?.rfc));

  const formik = useFormik({
    initialValues: {
      referencia: [
        {
          country_code: "+52",
          phone: "",
          email: "",
          cliente: "",
        },
        {
          country_code: "+52",
          phone: "",
          email: "",
          cliente: "",
        },
        {
          country_code: "+52",
          phone: "",
          email: "",
          cliente: "",
        },
      ],
    },
    validationSchema: yup.object().shape({
      referencia: yup.array().of(
        yup.object().shape({
          cliente: yup.string().required("Campo requerido"),
          country_code: yup.string().required("Campo requerido"),
          phone: yup
            .string()
            .required("Campo requerido")
            .matches(PHONE_NUMBER, "Número de teléfono inválido")
            .test("unique", "El teléfono ya se encuentra registrado.", function (value) {
              const array_items = formik.values.referencia.map((item) => item.phone || "");
              const num_results: number = array_items.filter((item) => item === value).length;
              return num_results > 1 ? false : true;
            }),
          email: yup
            .string()
            .required("Campo requerido")
            .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)")
            .test("unique", "El email ya se encuentra registrado.", function (value) {
              const array_items = formik.values.referencia.map((item) => item.email || "");
              const num_results: number = array_items.filter((item) => item === value).length;
              return num_results > 1 ? false : true;
            }),
        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!formik.isValid) {
          return;
        }
        setSubmitting(true);

        const reference_type = proveedorReferenceType.data?.find((item) => item.code === "CRTCL");
        const has_references = companyData.references.clients.length > 0;

        for (let index = 0; index < values.referencia.length; index++) {
          const reference = values.referencia[index];
          const clientItem = clientReferences.data?.data?.clients?.find((client) => client.rfc === reference.cliente);
          let response = undefined;

          const referenceData = {
            client_details_id: client.id,
            email: reference.email,
            id_ext: "3333",
            phone_number: reference.phone,
            reference_type_id: reference_type?.id,
            code_phone_number: reference.country_code,
            company_name: clientItem?.name,
            client_rfc: clientItem?.rfc,
          };

          if (has_references) {
            response = await triggerPatchReferences({
              referenceId: companyData.references.clients.at(index)?.id,
              body: referenceData,
            }).unwrap();
          } else {
            response = await triggerPostReferences(referenceData).unwrap();
          }

          dispatch(
            setCompanyClientReference({
              clientNumber: index,
              reference: {
                id: response.data.id,
                client_id: reference.cliente,
                email: reference.email,
                code_phone: reference.country_code,
                phone_number: reference.phone,
              },
            }),
          );
        }

        navigate("/producto/creditosimple/referencias_proveedores");
      } catch (error) {
        setModalErrorVisible({ open: true, error: error as ApiError });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onChangeEmail = (index: number, value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue(`referencia[${index}].email`, low);
  };

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.referencia?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  useEffect(() => {
    if (clientReferences.isSuccess && companyData.references.clients.length !== 0) {
      formik.setFieldValue(
        "referencia",
        companyData.references.clients.map((item) => {
          return {
            country_code: item.code_phone,
            phone: item.phone_number,
            email: item.email,
            cliente: item.client_id,
          };
        }),
      );
    }
  }, [clientReferences, companyData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box my={8} maxWidth="sm" mx="auto" mt={4} px={2}>
            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={700}
              color={theme?.palette?.primary?.dark}
            >
              Referencias
            </Typography>

            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={400}
              color={theme?.palette?.primary?.dark}
              mt={2}
            >
              <strong>1</strong> de 2
            </Typography>

            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={600}
              color={theme?.palette?.primary?.main}
              mt={2}
            >
              Ahora, escribe 3 referencias que sean tus clientes, considera que podríamos contactarlos.
            </Typography>

            <form onSubmit={formik.handleSubmit} noValidate>
              {formik.values.referencia.map((_, index) => (
                <div key={index}>
                  <Box mt={4} mb={2}>
                    <Typography
                      textAlign={"left"}
                      variant={isMobileOrTablet ? "body2" : "body1"}
                      fontWeight={400}
                      color={theme?.palette?.primary?.dark}
                    >
                      {getOrdinalText(index + 1)} referencia
                    </Typography>
                  </Box>
                  <FormControl
                    fullWidth
                    required
                    error={Boolean(formik.touched.referencia?.[index]?.cliente && getFieldError("cliente", index))}
                  >
                    <InputLabel id="cliente">Clientes</InputLabel>
                    <Select
                      labelId="clientes"
                      name={`referencia[${index}].cliente`}
                      id={`cliente${index}`}
                      fullWidth
                      required
                      label="Clientes"
                      value={formik.values.referencia?.[index]?.cliente}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.referencia?.[index]?.cliente && getFieldError("cliente", index))}
                      disabled={formik.isSubmitting}
                    >
                      <MenuItem value="">Selecciona</MenuItem>

                      {clientReferences.isSuccess &&
                        clientReferences.data?.data?.clients
                          ?.filter(
                            (supplier) =>
                              !formik.values.referencia.some(
                                (ref, refIndex) => refIndex !== index && ref.cliente === supplier.rfc,
                              ),
                          )
                          .filter((supplier) => !CLIENT_RFC_NOT_VALID.includes(supplier.rfc))
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((supplier) => (
                            <MenuItem key={supplier.rfc} value={supplier.rfc}>
                              {supplier.name.toUpperCase()}
                            </MenuItem>
                          ))}
                    </Select>

                    {formik.touched.referencia?.[index]?.cliente && getFieldError("cliente", index) && (
                      <FormHelperText>{getFieldError("cliente", index)}</FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    name={`referencia[${index}].email`}
                    id={`email${index}`}
                    label="Correo electrónico"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => onChangeEmail(index, e.target.value)}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.referencia?.[index]?.email && getFieldError("email", index))}
                    helperText={formik.touched.referencia?.[index]?.email && getFieldError("email", index)}
                    value={formik.values.referencia?.[index]?.email}
                    disabled={formik.isSubmitting}
                  />

                  <Grid container spacing={1} mt={1}>
                    <Grid item xs={4} sm={4} md={4}>
                      <CustomContryCode
                        label="Código"
                        name={`referencia[${index}].country_code`}
                        value={formik.values.referencia?.[index]?.country_code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.country_code && getFieldError("country_code", index),
                        )}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>

                    <Grid item xs={8} sm={8} md={8}>
                      <TextField
                        name={`referencia[${index}].phone`}
                        id={`phone${index}`}
                        type="text"
                        variant="outlined"
                        label="Teléfono"
                        fullWidth
                        required
                        value={formik.values.referencia?.[index]?.phone}
                        onChange={(event) => {
                          formik.setFieldValue(`referencia[${index}].phone`, event.target.value.replace(/[^0-9]/g, ""));
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.referencia?.[index]?.phone && getFieldError("phone", index))}
                        helperText={formik.touched.referencia?.[index]?.phone && getFieldError("phone", index)}
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric",
                        }}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    variant="blue_outlined"
                    type="button"
                    fullWidth
                    sx={{ padding: "0px", height: "100%" }}
                    onClick={() => {
                      navigate("/producto/creditosimple/avance_expediente_digital");
                    }}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReferenciasClientes;
