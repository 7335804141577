/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { store } from "./redux/store";
import { LenditApp } from "./LenditApp";
import { CustomTheme as lendiaTheme } from "./theme/CustomThemeLendia";
import { CustomTheme as finbeTheme } from "./theme/CustomThemeFinbe";

import "./styles/main-styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Amplify } from "aws-amplify";
import { HOSTNAMES } from "@helpers/constantes";
import DynamicMetadata from "@components/DynamicMetadata";
import ScriptsComponent from "@components/ScriptsComponent";
import { getBaseDomain } from "@helpers/funciones";

const getConfig = (): any => {
  const hostname = window.location.hostname;
  const foundHost = HOSTNAMES.find(({ host }) => host === hostname);
  let userPoolId = undefined;
  let userPoolClientId = undefined;
  if (foundHost?.value === "lendia") {
    userPoolId = import.meta.env.VITE_AWS_USER_POOL_ID;
    userPoolClientId = import.meta.env.VITE_AWS_CLIENT_ID;
  } else if (foundHost?.value === "finbe") {
    userPoolId = import.meta.env.VITE_AWS_USER_POOL_ID_FINBE;
    userPoolClientId = import.meta.env.VITE_AWS_CLIENT_ID_FINBE;
  }
  return {
    Auth: {
      Cognito: {
        userPoolId: userPoolId,
        userPoolClientId: userPoolClientId,
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
        userAttributes: {
          email: {
            required: true,
          },
        },
      },
    },
  };
};

const amplifyConfig = getConfig();
Amplify.configure(amplifyConfig);

const getThemeByDomain = () => {
  const hostname = getBaseDomain(window.location.href);
  const foundHost = HOSTNAMES.find(({ host }) => host === hostname);

  const themesByValue: Record<string, any> = {
    lendia: lendiaTheme,
    finbe: finbeTheme,
  };

  return foundHost && themesByValue[foundHost.value] ? themesByValue[foundHost.value] : lendiaTheme;
};

const selectedTheme = getThemeByDomain();

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <DynamicMetadata />
    <ScriptsComponent />
    <Provider store={store}>
      <PersistGate loading={<></>} persistor={persistor}>
        <ThemeProvider theme={selectedTheme}>
          <BrowserRouter>
            <LenditApp />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
