/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { signOut } from "aws-amplify/auth";

interface InactivityHandlerProps {
  inactivityLimit?: number; // En segundos (default: 10 minutos)
  countdownLimit?: number; // En segundos (default: 30 segundos)
}

export const InactivityHandler: React.FC<InactivityHandlerProps> = ({ inactivityLimit = 600, countdownLimit = 30 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();

  const [isInactiveModalOpen, setIsInactiveModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(countdownLimit);

  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);
  const countdownInterval = useRef<NodeJS.Timeout | null>(null);
  const modalAlreadyOpened = useRef(false);
  const channel = useRef<BroadcastChannel | null>(null);

  const inactivityTimeoutMs = inactivityLimit * 1000; // Milisegundos
  const countdownTimeoutMs = countdownLimit * 1000; // Milisegundos

  const startCountdown = () => {
    if (!modalAlreadyOpened.current) {
      setIsInactiveModalOpen(true);
      modalAlreadyOpened.current = true;
      setCountdown(countdownLimit);

      countdownInterval.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            handleSignOut();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const initializeInactivityTimeout = () => {
    if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(() => {
      startCountdown(); 
    }, inactivityTimeoutMs - countdownTimeoutMs);
  };

  const resetInactivityTimeout = () => {
    if (!isInactiveModalOpen) {
      initializeInactivityTimeout(); 
    }
  };

  const onUserActivity = () => {
    resetInactivityTimeout();
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "click"];
    events.forEach((event) => window.addEventListener(event, onUserActivity));

    channel.current = new BroadcastChannel("session_channel");
    channel.current.onmessage = (event) => {
      if (event.data === "logout") {
        navigate("/login");
      }
    };
    initializeInactivityTimeout();

    return () => {
      if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
      if (countdownInterval.current) clearInterval(countdownInterval.current);
      events.forEach((event) => window.removeEventListener(event, onUserActivity));
      channel.current?.close();
    };
  }, [location.pathname]);

  const handleSignOut = async () => {
    channel.current?.postMessage("logout");
    await signOut();
    navigate("/login");
  };

  const handleContinueSession = () => {
    setIsInactiveModalOpen(false);
    modalAlreadyOpened.current = false;
    if (countdownInterval.current) clearInterval(countdownInterval.current);
    initializeInactivityTimeout();
  };

  return (
    <Dialog
      open={isInactiveModalOpen}
      onClose={handleSignOut}
      PaperProps={{
        style: {
          backgroundColor: "#FFFFFF",
          padding: isMobile ? "10px" : "20px",
          borderRadius: "8px",
          maxWidth: isMobile ? "90%" : "500px",
          margin: isMobile ? "0 auto" : undefined,
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant={isMobile ? "subtitle1" : "h6"}
          style={{ color: theme?.palette?.primary?.dark, fontWeight: 600, textAlign: "center" }}
        >
          ¿Deseas continuar en la sesión?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          style={{
            color: theme?.palette?.primary?.dark,
            textAlign: "justify",
            lineHeight: 1.5,
            marginBottom: isMobile ? "10px" : "20px",
            fontSize: isMobile ? "0.9rem" : "1rem",
          }}
        >
          Tu sesión está a punto de cerrarse por inactividad. Haz clic en 'Continuar' para seguir conectado o en 'Cerrar
          sesión' para salir.
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "#F2704F",
            textAlign: "center",
            fontWeight: 600,
            fontSize: isMobile ? "1rem" : "1.25rem",
          }}
        >
          Tiempo restante: {countdown} segundos
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={handleSignOut}
          style={{
            backgroundColor: "#F2704F",
            color: "#FFFFFF",
            fontWeight: 500,
            textTransform: "none",
            fontSize: isMobile ? "0.8rem" : "1rem",
          }}
          variant="contained"
        >
          Cerrar sesión
        </Button>
        <Button
          onClick={handleContinueSession}
          style={{
            backgroundColor: theme?.palette?.primary?.main,
            color: "#FFFFFF",
            fontWeight: 500,
            textTransform: "none",
            fontSize: isMobile ? "0.8rem" : "1rem",
            marginLeft: isMobile ? "5px" : "10px",
          }}
          variant="contained"
          autoFocus
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
