/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  PayloadBankAccount,
  PayloadPaymentDetails,
  PayloadPaymentMethod,
  Response,
  SolicitudCompleted,
  SolicitudUpdateStatus,
  ScheduleResponse,
  GetPaymentMethodByIdResponse,
  BankAccountResponse,
  BankAccount,
  ConditionResponse,
  LoanApplication,
} from "../types";
import { LoanCondiciones, PayloadCondition } from "@interfaces/condiciones";
import { LoanStatus } from "@interfaces/catalogs";
import { setLoanStatus } from "@store/slices/registerSlice";

const solicitudApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postLoanUse: build.query<Response, any>({
      query: (data: { solicitud_id: string; body: any }) => ({
        url: `solicitudes/${data.solicitud_id}/destino_recursos`,
        method: "POST",
        body: data.body,
      }),
    }),
    postValidateNextStage: build.mutation<
      undefined,
      { applicationId: string; statusCode: LoanStatus; username: string }
    >({
      query: ({ applicationId, statusCode, username }) => ({
        url: `solicitudes/${applicationId}/progreso`,
        method: "POST",
        body: {
          status_code: statusCode,
          user_name: username,
        },
      }),
      async onQueryStarted(arg, api) {
        const { statusCode } = arg;
        api.dispatch(setLoanStatus(statusCode));
      },
    }),
    getApplicationByApplicationId: build.query<any, string>({
      query: (soldId) => `solicitudes?application_id=${soldId}`,
    }),
    getLoanApplicationById: build.query<LoanApplication, string>({
      query: (soldId) => `solicitudes?application_id=${soldId}`,
      transformResponse: (response: { data: LoanApplication[] }) => {
        if (response.data.length === 0) {
          throw { status: 404, data: { message: "No se encontró la solicitud" } };
        }
        return response.data[0];
      },
    }),
    getScheduleByApplicationId: build.query<ScheduleResponse, string>({
      query: (solicitud_id) => ({
        url: `solicitudes/${solicitud_id}/agendar_visita`,
        method: "GET",
      }),
    }),
    getBankAccounts: build.query<BankAccount[], string>({
      query: (solicitudId: UUID) => ({
        url: `solicitudes/${solicitudId}/cuentas_bancarias`,
        method: "GET",
      }),
      transformResponse: (response: BankAccountResponse) => {
        return response.data;
      },
    }),
    postBankAccounts: build.mutation<Response, { solicitudId: UUID | string; body: PayloadBankAccount }>({
      query: (data: { solicitudId: UUID; body: PayloadBankAccount }) => ({
        url: `solicitudes/${data.solicitudId}/cuentas_bancarias`,
        method: "POST",
        body: data.body,
      }),
    }),
    putBankAccount: build.mutation<
      Response,
      { solicitudId: UUID | string; bankAccountId: UUID | string; body: PayloadBankAccount }
    >({
      query: (data: { solicitudId: UUID | string; bankAccountId: UUID | string; body: PayloadBankAccount }) => ({
        url: `solicitudes/${data.solicitudId}/cuentas_bancarias/${data.bankAccountId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    deleteBankAccount: build.mutation<Response, { solicitudId: UUID | string; accountId: string }>({
      query: ({ solicitudId, accountId }) => ({
        url: `solicitudes/${solicitudId}/cuentas_bancarias/${accountId}`,
        method: "DELETE",
      }),
    }),
    postPaymentMethod: build.mutation<Response, { solicitudId: UUID | string; body: PayloadPaymentMethod }>({
      query: (data: { solicitudId: UUID; body: PayloadPaymentMethod }) => ({
        url: `solicitudes/${data.solicitudId}/forma_pagos`,
        method: "POST",
        body: data.body,
      }),
    }),
    getPaymentMethodById: build.query<GetPaymentMethodByIdResponse, { solicitudId: string }>({
      query: ({ solicitudId }) => ({
        url: `solicitudes/${solicitudId}/forma_pagos`,
        method: "GET",
      }),
    }),
    postPaymentDetails: build.mutation<Response, { solicitudId: UUID | string; body: PayloadPaymentDetails }>({
      query: (data: { solicitudId: UUID; body: PayloadPaymentDetails }) => ({
        url: `solicitudes/${data.solicitudId}/detalles`,
        method: "POST",
        body: data.body,
      }),
    }),
    getPaymentDetails: build.query<Response, { solicitudId: string }>({
      query: (data: { solicitudId: string }) => ({
        url: `solicitudes/${data.solicitudId}/detalles`,
        method: "GET",
      }),
    }),
    putPaymentDetails: build.mutation<
      Response,
      { solicitudId: UUID | string; detailId: string; body: PayloadPaymentDetails }
    >({
      query: (data: { solicitudId: UUID; detailId: UUID; body: PayloadPaymentDetails }) => ({
        url: `solicitudes/${data.solicitudId}/detalles/${data.detailId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    putApplicationCompleted: build.mutation<Response, { id_sol: string; body: SolicitudCompleted }>({
      query: ({ id_sol, body }) => ({
        url: `solicitudes/${id_sol}`,
        method: "PUT",
        body,
      }),
    }),
    putApplicationUpdateStatus: build.mutation<Response, { id_sol: string; body: SolicitudUpdateStatus }>({
      query: ({ id_sol, body }) => ({
        url: `solicitudes/${id_sol}`,
        method: "PUT",
        body,
      }),
    }),
    getConditionsById: build.query<LoanCondiciones[], string>({
      query: (soldId) => `solicitudes/${soldId}/condiciones?q=active:true`,
      transformResponse: (response: { data: any }) => {
        return response.data.data;
      },
    }),
    postCondition: build.mutation<ConditionResponse, PayloadCondition>({
      query: ({ loan_application, loan_amount, requested_term }) => ({
        url: `solicitudes/${loan_application}/condiciones`,
        method: "POST",
        body: {
          loan_amount: loan_amount,
          requested_term: requested_term,
        },
      }),
    }),
  }),
});

export const {
  useLazyPostLoanUseQuery,
  usePostValidateNextStageMutation,
  useLazyGetScheduleByApplicationIdQuery,
  useGetApplicationByApplicationIdQuery,
  useLazyGetApplicationByApplicationIdQuery,
  usePostBankAccountsMutation,
  usePutBankAccountMutation,
  usePostPaymentMethodMutation,
  usePostPaymentDetailsMutation,
  usePutPaymentDetailsMutation,
  useDeleteBankAccountMutation,
  usePutApplicationCompletedMutation,
  usePutApplicationUpdateStatusMutation,
  useLazyGetPaymentDetailsQuery,
  useLazyGetPaymentMethodByIdQuery,
  useLazyGetBankAccountsQuery,
  useLazyGetConditionsByIdQuery,
  usePostConditionMutation,
  useLazyGetLoanApplicationByIdQuery,
} = solicitudApi;
