/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetLoanUseQuery } from "@api/catalogs";
import { useLazyPostLoanUseQuery, usePostValidateNextStageMutation } from "@api/applications";
import { ProgressBar } from "@components/ProgressBar";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus, setCreditUse } from "@store/slices/registerSlice";
import { STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";
import { getDynamicUrl } from "@helpers/funciones";
import { ApiError } from "@interfaces/response";

const UsoDeCredito = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const [listCreditUse, setListCreditUse] = useState<any>([]);
  const [idOtherUse, setIdOtherUse] = useState<string>("");

  const img = getDynamicUrl("right_7.svg");

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [triggerSaveUseSelected] = useLazyPostLoanUseQuery();
  const [getListCreditUse] = useLazyGetLoanUseQuery();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const creditUseData = useSelector((state: RootState) => state.register.credit_use);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const formik = useFormik({
    initialValues: {
      uses: creditUseData.selected.length ? creditUseData.selected : [],
      reason: creditUseData.selected.length ? creditUseData.reason : "",
    },
    validationSchema: yup.object().shape(
      {
        uses: yup.array().min(1, "").required(""),
        reason: yup.string().when("reason", (_) => {
          if (formik.values.uses.includes(idOtherUse)) {
            return yup.string().trim().required("Campo requerido");
          } else {
            return yup.string().notRequired();
          }
        }),
      },
      [["reason", "reason"]],
    ),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);

          const resources = formik.values.uses.map((id) => {
            return {
              resources_use_id: id,
              reason: id === idOtherUse ? formik.values.reason : "",
            };
          });

          await triggerSaveUseSelected({
            solicitud_id: applicationData.id,
            body: {
              resource_uses: resources,
            },
          }).unwrap();

          const isPFAE = applicationData.type === TAX_SYSTEMS.PFAE.code;
          const next_status = isPFAE
            ? STATUS_CATALOG.presencia_digital_pfae.code
            : STATUS_CATALOG.presencia_digital.code;
          const next_url = isPFAE ? STATUS_CATALOG.presencia_digital_pfae.url : STATUS_CATALOG.presencia_digital.url;

          if (creditUseData.selected.length === 0) {
            await triggerPostNextStage({
              applicationId: applicationData.id,
              statusCode: next_status,
              username: `TOFU - ${accountUser.email}`,
            }).unwrap();
          }

          dispatch(
            setCreditUse({
              selected: values.uses,
              reason: resources.find((item: any) => item.resources_use_id === idOtherUse)?.reason ?? "",
            }),
          );

          dispatch(setLoanStatus(next_status));

          navigate(next_url);
        } catch (error) {
          dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
        } finally {
          setSubmitting(false);
        }
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const list_credit_use = await getListCreditUse().unwrap();
      const id_other = list_credit_use.find((item: any) => item.other)?.id;
      setListCreditUse(list_credit_use);
      setIdOtherUse(id_other ?? "");
    };

    fetchData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={500}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Selección de uso de crédito
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={applicationData.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 5 : 6} />
          </Container>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              {listCreditUse.length > 0
                ? listCreditUse.map((item: any, index: number) => {
                    return (
                      <Box mt={1} key={index}>
                        <FormControlLabel
                          sx={{ color: theme?.palette?.primary?.dark }}
                          control={
                            <Checkbox
                              id={item.name.replace(" ", "_").toLowerCase()}
                              name="uses"
                              value={item.id}
                              checked={formik.values.uses.includes(
                                item.id as `${string}-${string}-${string}-${string}-${string}`,
                              )}
                              onChange={formik.handleChange}
                              disabled={formik.isSubmitting}
                            />
                          }
                          label={item.name}
                        />
                        {item.id === idOtherUse && formik.values.uses.includes(item.id) && (
                          <TextField
                            id="reason"
                            name="reason"
                            label="Especifica otro"
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.reason}
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            helperText={formik.touched.reason && formik.errors.reason}
                            disabled={formik.isSubmitting}
                          />
                        )}
                      </Box>
                    );
                  })
                : [...Array(6).keys()].map((_, index) => (
                    <Box mt={2} key={index} py={1}>
                      <Skeleton variant="text" animation={"wave"} />
                    </Box>
                  ))}

              <Box display={"flex"} justifyContent={"center"} mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 78px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default UsoDeCredito;
