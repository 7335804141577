/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { RootState } from "@store/store";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import Confetti from "react-confetti";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/funciones";

const SECONDS_TO_FETCH = 10 * 1000;
const MINUTES_TO_NOTIFY = 30 * 60 * 1000;

interface WaitingRoomProps {
  type: "WaitingRoom" | "WaitingRoomRevision";
}

const WaitingRoom = (props: WaitingRoomProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const application = useSelector((state: RootState) => state.register.application);
  const img = getDynamicUrl("speed_icon.svg");
  const [getLoadApplication] = useLazyGetApplicationByApplicationIdQuery();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const logOut = async () => {
    await signOut();
    navigate("/login");
  };

  useEffect(() => {
    const intervalFetch = setInterval(async () => {
      try {
        const response = await getLoadApplication(application.id);
        const url_actual = response?.data?.data ? response?.data?.data[0]?.status_catalog?.url_actual || "" : "";
        if (url_actual.includes("/oferta")) {
          navigate("/producto/creditosimple/oferta");
        } else if (url_actual.includes("/credito_rechazado")) {
          navigate("/producto/creditosimple/credito_rechazado");
        }else if (url_actual.includes("/en_revision")) {
          navigate("/producto/creditosimple/en_revision");
        }
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      }
    }, SECONDS_TO_FETCH);

    return () => {
      intervalFetch && clearInterval(intervalFetch);
      document.body.style.backgroundColor = "";
    };
  }, []);

  useEffect(() => {
    if (props.type === "WaitingRoom") {
      const timer = setTimeout(() => {
        setShowNotification(true);
      }, MINUTES_TO_NOTIFY);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <Grid container sx={{ position: "relative" }} height="90%">
        <InactivityHandler />
        {props.type === "WaitingRoom" ? (
          <Confetti className="confetti-container" numberOfPieces={2000} tweenDuration={20000} recycle={false} />
        ) : (
          <></>
        )}

        <Grid item xs={12} md={12} mt={4}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container sx={{ mt: 2, mb: 2 }}>
              <Typography
                fontSize={"31px"}
                fontWeight={"600"}
                align="center"
                color={theme?.palette?.primary?.dark}
                lineHeight={"39.06px"}
              >
                ¡Concluiste exitosamente tu solicitud!
              </Typography>
            </Container>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography
                fontWeight={300}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.dark}
                align="center"
                width={isMobileOrTablet ? "280px" : "400px"}
              >
                {props.type === "WaitingRoom" ? (
                  <>Estamos procesando tu solicitud, esto puede tomar varios minutos.</>
                ) : (
                  <>
                    Estamos procesando tus documentos y realizando el análisis de tu solicitud esto puede tomar hasta 24
                    horas hábiles.
                  </>
                )}
              </Typography>
            </Box>

            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={img} alt="" />
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography
                fontWeight={"300"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.dark}
                align="center"
                width={isMobileOrTablet ? "280px" : "400px"}
              >
                ¡Despreocúpate! Puedes salir de esta pantalla, tan pronto este lista tu oferta personalizada podrás
                consultarla aquí mismo y te la enviaremos vía WhatsApp
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Button variant="default" onClick={logOut} sx={{ px: 6, py: 1, mt: 2 }}>
                Salir
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={showNotification}
        onClose={() => {
          setShowNotification(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 3, mb: 1 }}>
            <Typography
              fontWeight={600}
              variant={isMobileOrTablet ? "body2" : "body1"}
              align="center"
              width={isMobileOrTablet ? "280px" : "400px"}
            >
              Continuamos procesando tu solicitud, sin embargo está tomando un poco más de tiempo del esperado.
            </Typography>

            <Typography
              fontWeight={300}
              variant={isMobileOrTablet ? "body2" : "body1"}
              align="center"
              width={isMobileOrTablet ? "280px" : "400px"}
              mt={4}
            >
              No te preocupes, esto normalmente se debe a intermitencias con alguno de nuestros proveedores de
              información. En menos de 2 horas un ejecutivo se pondrá en contacto contigo para informarte del estatus de
              tu solicitud.
            </Typography>
          </Box>

          <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}>
            <Button variant="default" onClick={() => setShowNotification(false)} sx={{ px: 6, py: 1, mt: 2 }}>
              Aceptar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WaitingRoom;
