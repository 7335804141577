/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/store";
import { setLoanStatus } from "@store/slices/registerSlice";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { useLazyGetPersonsQuery } from "@api/personasApi";
import { DocumentResponse, PersonaStructure, RealOwnerWithDocs } from "@interfaces/index";
import {
  DOCUMENTS_CATALOG,
  PERSON_CODES_AVAL,
  PERSON_CODES_LEGAL_REPRESENTATIVE,
  PERSON_CODES_PROPIETARIO_REAL,
  STATUS_CATALOG,
} from "@helpers/constantes";
import CustomButtonTabExpediente, { ButtonTabStatus } from "@components/CustomButtonTabExpediente";
import { ModalExpediente } from "@components/CustomModalExpediente";

export const TabStepBofuPm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [documentsDictamenUploaded, setDocumentsDictamenUploaded] = useState<boolean>(false);
  const [dictamenStatus, setDictamenStatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [empresaStatus, setEmpresaStatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [avalStatus, setAvalEstatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [propietarioStatus, setPropietarioEstatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [showModalCompanyNotAvailable, setShowModalCompanyNotAvailable] = useState(false);
  const [showModalDictamenNotAvailable, setShowModalDictamenNotAvailable] = useState(false);
  const [showModalBeneficialOwnerNotAvailable, setShowModalBeneficialOwnerNotAvailable] = useState(false);

  const [isSuccessData, setIsSuccessData] = useState<boolean>(false);
  const [loanApplication, setLoanApplication] = useState<any | null>(null);
  const [avalselected, setAvalSelected] = useState<PersonaStructure | null>(null);
  const [realOwners, setRealOwners] = useState<PersonaStructure[]>([]);
  const [docsAccount, setDocsAccount] = useState<DocumentResponse[]>([]);
  const [docsAval, setDocsAval] = useState<DocumentResponse[]>([]);
  const [docsOwner, setDocsOwner] = useState<DocumentResponse[]>([]);
  const [docsRepresentative, setDocsRepresentative] = useState<DocumentResponse[]>([]);

  const applicationData = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [getPersonsByCode] = useLazyGetPersonsQuery();
  const [getLoanAplication] = useLazyGetApplicationByApplicationIdQuery();

  const handleButtonClick = (seccion: string) => {
    if (seccion === "empresa") {
      if (!documentsDictamenUploaded) {
        setShowModalCompanyNotAvailable(true);
        return;
      }
    } else if (seccion === "aval") {
      if (!loanApplication?.decision_validated) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
    } else if (seccion === "propietario") {
      if (!loanApplication?.decision_validated) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
      if (realOwners.length === 0) {
        setShowModalBeneficialOwnerNotAvailable(true);
        return;
      }
    }
  };

  // tab_dictamen
  useEffect(() => {
    const { acta_constitutiva, identificacion_representante_legal, acta_adicional } = DOCUMENTS_CATALOG;

    const doc_acta_constitutiva = docsAccount.find((item) => item.file_code === acta_constitutiva.code);
    const doc_identificacion = docsAccount.find((item) => item.file_code === identificacion_representante_legal.code);
    const doc_acta_adicional = docsAccount.filter((item) => item.file_code === acta_adicional.code);

    if (!doc_acta_constitutiva || !doc_identificacion) {
      return;
    }

    const list_documents = [...doc_acta_adicional, doc_acta_constitutiva, doc_identificacion];
    const all_docs_valid = list_documents.every((item) => item?.validated === true);
    const some_doc_invalid = list_documents.some((item) => item?.validated === false);
    const all_docs_uploaded = list_documents.every((item) => item);

    if (all_docs_valid) {
      setDictamenStatus("validado");
    } else if (some_doc_invalid) {
      setDictamenStatus("faltante");
    } else if (all_docs_uploaded) {
      setDictamenStatus("cargado");
    } else {
      setDictamenStatus(undefined);
    }
  }, [docsAccount]);

  // tab_empresa
  useEffect(() => {
    const {
      solicitud_credito,
      comprobante_domicilio,
      acta_constitutiva,
      identificacion_representante_legal,
      validacion_identidad,
    } = DOCUMENTS_CATALOG;

    const doc_solicitud = docsAccount.find((file) => file.file_code === solicitud_credito.code && file.status);
    const doc_comprobante_dom = docsAccount.find(
      (file) => file.file_code === comprobante_domicilio.code && file.status,
    );
    const doc_acta_constitutiva = docsAccount.find((item) => item.file_code === acta_constitutiva.code && item.status);
    const doc_identificacion = docsAccount.find(
      (item) => item.file_code === identificacion_representante_legal.code && item.status,
    );
    const doc_validacion_ident = docsRepresentative.find(
      (item) => item.file_code === validacion_identidad.code && item.status,
    );

    const docs_dictamen_uploaded = doc_acta_constitutiva && doc_identificacion ? true : false;
    const list_documents = [doc_solicitud, doc_comprobante_dom, doc_validacion_ident];
    const all_docs_valid = list_documents.every((item) => item?.validated === true);
    const some_doc_invalid = list_documents.some((item) => item?.validated === false);
    const all_docs_uploaded = list_documents.every((item) => item);

    setDocumentsDictamenUploaded(docs_dictamen_uploaded);

    if (all_docs_valid) {
      setEmpresaStatus("validado");
    } else if (some_doc_invalid) {
      setEmpresaStatus("faltante");
    } else if (all_docs_uploaded) {
      setEmpresaStatus("cargado");
    } else {
      setEmpresaStatus(undefined);
    }
  }, [loanApplication, docsAccount]);

  // tab_aval
  useEffect(() => {
    (async () => {
      try {
        if (!avalselected) return;

        const { constancia_situacion_fiscal, comprobante_domicilio, anexo_1 } = DOCUMENTS_CATALOG;

        const doc_constancia_sf = docsAval.find((item) => item.file_code === constancia_situacion_fiscal.code);
        const doc_comprobante_dom = docsAval.find((item) => item.file_code === comprobante_domicilio.code);
        const doc_anexo_1 = docsAval.find((item) => item.file_code === anexo_1.code);

        const is_all_docs_uploaded = doc_constancia_sf && doc_comprobante_dom ? true : false;
        const is_all_forms_completed =
          avalselected.information_person_completed != null &&
          avalselected.identity_validation_completed !== null &&
          avalselected.bank_references_completed !== null;
        const some_form_invalid =
          avalselected.information_person_completed === false ||
          avalselected.identity_validation_completed === false ||
          avalselected.bank_references_completed === false;

        const list_documents = [doc_anexo_1, doc_comprobante_dom, doc_constancia_sf];
        const is_all_docs_valid = list_documents.every((doc) => doc?.validated === true);
        const some_doc_invalid = list_documents.some((doc) => doc?.validated === false);

        if (is_all_forms_completed && is_all_docs_uploaded) {
          if (is_all_docs_valid) {
            setAvalEstatus("validado");
          } else if (some_doc_invalid || some_form_invalid) {
            setAvalEstatus("faltante");
          } else {
            setAvalEstatus("cargado");
          }
        } else {
          setAvalEstatus(undefined);
        }
      } catch (error) {
        console.log("Ha ocurrido un error al generar el tab de aval", error);
      }
    })();
  }, [avalselected, docsAval]);

  // tab_propietario
  useEffect(() => {
    (async () => {
      try {
        if (!realOwners.length) {
          return;
        }

        const { identificacion_oficial, constancia_situacion_fiscal, anexo_2 } = DOCUMENTS_CATALOG;

        const docs_propietario: RealOwnerWithDocs[] = [];
        realOwners.forEach((owner) => {
          const docs_by_owner = docsOwner.filter((item) => item.person_id === owner.id);
          const newItem: RealOwnerWithDocs = {
            owner,
            anexo_2: docs_by_owner.find((item) => item.file_code === anexo_2.code) || undefined,
            identificacion: docs_by_owner.find((item) => item.file_code === identificacion_oficial.code) || undefined,
            constancia: docs_by_owner.find((item) => item.file_code === constancia_situacion_fiscal.code) || undefined,
          };
          docs_propietario.push(newItem);
        });

        const all_identificacion_uploaded = docs_propietario.every((owner) => owner.identificacion !== undefined);
        const all_constancia_uploaded = docs_propietario.every((owner) => owner.constancia !== undefined);
        const all_anexo_2_uploaded = docs_propietario.every((owner) => owner.anexo_2 !== undefined);
        const all_forms_completed = realOwners.every((owner) => owner.information_person_completed !== null);
        const all_docs_uploaded =
          all_identificacion_uploaded && all_constancia_uploaded && all_anexo_2_uploaded ? true : false;

        const all_identificacion_valid = docs_propietario.every((owner) => owner.identificacion?.validated === true);
        const all_constancia_valid = docs_propietario.every((owner) => owner.constancia?.validated === true);
        const all_doc_anexo_2_valid = docs_propietario.every((owner) => owner.anexo_2?.validated === true);
        const all_docs_valid = all_doc_anexo_2_valid && all_identificacion_valid && all_constancia_valid ? true : false;
        const some_doc_invalid = docs_propietario.some((owner) => {
          const { identificacion, constancia, anexo_2 } = owner;
          return identificacion?.validated === false || constancia?.validated === false || anexo_2?.validated === false
            ? true
            : false;
        });

        if (all_forms_completed && all_docs_uploaded) {
          if (all_docs_valid) {
            setPropietarioEstatus("validado");
          } else if (some_doc_invalid) {
            setPropietarioEstatus("faltante");
          } else {
            setPropietarioEstatus("cargado");
          }
        } else {
          setPropietarioEstatus(undefined);
        }
      } catch (error) {
        console.log("Ha ocurrido un error al generar el tab de propietario", error);
      }
    })();
  }, [realOwners]);

  useEffect(() => {
    (async () => {
      try {
        setIsSuccessData(false);
        const [loan_aplications, persons, documents] = await Promise.all([
          getLoanAplication(applicationData.id).unwrap(),
          getPersonsByCode({
            loanApplicationId: applicationData.id,
          }).unwrap(),
          getDocumentsByAppId({
            loanApplicationId: applicationData.id,
            status: true,
          }).unwrap(),
        ]);

        const loan_application = loan_aplications.data[0];

        // validar si la solicitud fue rechazada por documentacion
        const { rechazado_documentacion } = STATUS_CATALOG;

        if (loan_application?.status_catalog?.code === rechazado_documentacion.code) {
          dispatch(setLoanStatus(rechazado_documentacion.code));
          navigate(rechazado_documentacion.url);
          return;
        }

        const representantes = persons.filter((item) =>
          PERSON_CODES_LEGAL_REPRESENTATIVE.includes(item.person_type?.code || ""),
        );
        const avales = persons.filter((item) => PERSON_CODES_AVAL.includes(item?.person_type?.code || ""));
        const propietarios = persons.filter((item) =>
          PERSON_CODES_PROPIETARIO_REAL.includes(item.person_type?.code || ""),
        );

        const representante = representantes[0];
        const aval = avales[0];

        const docs_account = documents.filter((item) => item.client_details_id === clientDetails.id);
        const docs_representante = representante ? documents.filter((item) => item.person_id === representante.id) : [];
        const docs_aval = aval ? documents.filter((item) => item.person_id === aval.id) : [];
        const owner_ids = propietarios.map((item) => item.id);
        const docs_owner = documents.filter((item) => owner_ids.includes(item.person_id as string));

        if (location.pathname === "/producto/creditosimple/docs_aval") {
          if (!loan_application?.decision_validated) {
            navigate("/producto/creditosimple/docs_empresa");
          }
        } else if (location.pathname === "/producto/creditosimple/propietario_real_pm") {
          if (!loan_application?.decision_validated || propietarios.length === 0) {
            navigate("/producto/creditosimple/docs_empresa");
          }
        }

        setLoanApplication(loan_application);
        setAvalSelected(aval);
        setRealOwners(propietarios);
        setDocsAccount(docs_account);
        setDocsRepresentative(docs_representante);
        setDocsAval(docs_aval);
        setDocsOwner(docs_owner);
        setIsSuccessData(true);
      } catch (error) {
        console.log("Ha ocurrido un error al cargar toda la informacion", error);
      }
    })();
  }, []);

  return (
    <>
      {isSuccessData ? (
        <Box
          sx={{
            width: "100%",
            height: "80px",
            bgcolor: theme?.palette?.backgroundRight?.tab,
            p: 1,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="dictamen"
                status={dictamenStatus}
                isActive={location.pathname === "/producto/creditosimple/docs_empresa"}
                onClick={() => {
                  navigate("/producto/creditosimple/docs_empresa");
                }}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="empresa"
                status={empresaStatus}
                isActive={location.pathname === "/producto/creditosimple/avance_expediente_digital"}
                onClick={() => navigate("/producto/creditosimple/avance_expediente_digital")}
                disabled={!documentsDictamenUploaded}
                onClickDisabled={() => handleButtonClick("empresa")}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="aval"
                status={avalStatus}
                isActive={location.pathname === "/producto/creditosimple/docs_aval"}
                onClick={() => navigate("/producto/creditosimple/docs_aval")}
                disabled={!loanApplication?.decision_validated}
                onClickDisabled={() => handleButtonClick("aval")}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="propietario"
                status={propietarioStatus}
                isActive={location.pathname === "/producto/creditosimple/propietario_real_pm"}
                onClick={() => navigate("/producto/creditosimple/propietario_real_pm")}
                disabled={!loanApplication?.decision_validated || realOwners.length === 0}
                onClickDisabled={() => handleButtonClick("propietario")}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Skeleton variant="rectangular" sx={{ height: "90px", width: "100%", borderRadius: 2 }} />
      )}

      <ModalExpediente
        type="company_not_available"
        openModal={showModalCompanyNotAvailable}
        closeModal={() => {
          setShowModalCompanyNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="dictamen_not_available"
        openModal={showModalDictamenNotAvailable}
        closeModal={() => {
          setShowModalDictamenNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="beneficial_owner_not_available"
        openModal={showModalBeneficialOwnerNotAvailable}
        closeModal={() => {
          setShowModalBeneficialOwnerNotAvailable(false);
        }}
      />
    </>
  );
};
