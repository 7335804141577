import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconError404 from "@components/Images/IconError404";

const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      width={"100%"}
      height={"100%"}
      sx={{
        backgroundColor: "#E3F2F8",
      }}
    >
      <Grid
        item
        md={12}
        sx={{
          mt: isMobileOrTablet ? 4 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant={isMobileOrTablet ? "h2" : "h1"}
          textAlign="center"
          mt={2}
          color={theme.palette.primary.dark}
        >
          ¡Ups!
        </Typography>

        <Typography
          variant={isMobileOrTablet ? "h5" : "h4"}
          textAlign="center"
          mt={2}
          color={theme.palette.primary.dark}
        >
          Parece que la página que buscas no existe o no está disponible
        </Typography>

        <Box mt={5}>
          <IconError404 fill={theme.palette.primary.main} width={isMobileOrTablet ? "150" : "180"} />
        </Box>

        <Typography
          variant={isMobileOrTablet ? "h5" : "h4"}
          textAlign="center"
          mt={5}
          color={theme.palette.primary.dark}
        >
          Verifica la dirección que buscas o regresa al inicio
        </Typography>

        <Box mt={7}>
          <Button variant="default" sx={{ width: "171px" }} onClick={handleClick}>
            Inicio
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFound;
