import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Modal,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SxProps,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentContract, DocumentSigner } from "@interfaces/response";

interface ModalFirmarMiFielsProps {
  handleClose: () => void;
  handleSuccess?: (signer: DocumentSigner) => void;
  document_contract: DocumentContract;
}

const sxModal: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1280px",
  maxWidth: "90%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "24px",
};

const ModalFirmarMiFiel = (props: ModalFirmarMiFielsProps) => {
  const { document_contract, handleClose, handleSuccess } = props;
  const [externalSigners, setExternalSigners] = useState<DocumentSigner[]>([]);
  const [signerId, setSignerId] = useState<string>("");

  const handleSingMifiel = () => {
    const signer_selected = externalSigners.find((signer) => signer.id === signerId);
    if (!signer_selected) return;

    window.mifiel.widget({
      widgetId: signer_selected.widget_id || "QYCnDC2Ge1",
      appendTo: "mifiel-widget",
      successBtnText: "Finalizar",
      onSuccess: {
        callToAction: function () {
          handleSuccess?.(signer_selected);
        },
      },
      onError: {
        callToAction: function () {
          handleClose();
        },
      },
    });
  };

  const handleSingLegalario = () => {
    const signer_selected = externalSigners.find((signer) => signer.id === signerId);
    if (!signer_selected) return;

    window.open(
      `https://sandbox.legalario.com/portal/invitacion/${signer_selected.id}`,
      "_blank",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=900",
    );
  };

  useEffect(() => {
    const signers = document_contract.signers_reference.filter((item) => item.type === "external");
    setExternalSigners(signers);
  }, [document_contract]);

  if (document_contract.sign_service_name === "MIFIEL") {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box
          sx={{
            ...sxModal,
            maxWidth: "70%",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} mt={1}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id={"signerId"}>Firmante</InputLabel>
                <Select
                  labelId="signerId"
                  name="signerId"
                  id="signerId"
                  fullWidth
                  required
                  label="signerId"
                  value={signerId}
                  onChange={(event) => setSignerId(event.target.value)}
                >
                  <MenuItem value="">Selecciona</MenuItem>
                  {externalSigners.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.rfc} | {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "100%" }}
                onClick={handleSingMifiel}
                disabled={!signerId}
                fullWidth
              >
                Firmar
              </Button>
            </Grid>
          </Grid>
          <Box>
            <Box id="mifiel-widget"></Box>
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box
          sx={{
            ...sxModal,
            width: "400px",
            maxWidth: "70%",
            height: "150px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id={"signerId"}>Firmante</InputLabel>
                <Select
                  labelId="signerId"
                  name="signerId"
                  id="signerId"
                  fullWidth
                  required
                  label="signerId"
                  value={signerId}
                  onChange={(event) => setSignerId(event.target.value)}
                >
                  <MenuItem value="">Selecciona</MenuItem>
                  {externalSigners.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.rfc} | {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "100%" }}
                onClick={handleSingLegalario}
                disabled={!signerId}
                fullWidth
              >
                Firmar
              </Button>
            </Grid>
          </Grid>

          <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-start" gap={2} maxWidth="sm"></Box>
        </Box>
      </Modal>
    );
  }
};

export default ModalFirmarMiFiel;
