/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import { getDynamicUrl } from "@helpers/index";
import { STATUS_CATALOG } from "@helpers/constantes";

const VisitaOcularConcluida = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const imgVisita = getDynamicUrl("img_tofu/img_tofu_20.svg");
  const img = getDynamicUrl("img_tofu/img_tofu_6.svg");

  const applicationData = useSelector((state: RootState) => state.register.application);

  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const loan_application = await getLoanApplication(applicationData.id).unwrap();
        if (loan_application?.status_catalog?.code === STATUS_CATALOG.firma_documentos.code) {
          clearInterval(interval);
          navigate(STATUS_CATALOG.firma_documentos.url);
        }
      } catch (error) {
        console.error("Ha ocurrido un error al validar el status");
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />

        <Box mx={4}>
          <Typography
            mt={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h5" : "h4"}
            color={theme?.palette?.primary?.dark}
            fontWeight={700}
          >
            Validando visita y preparando documentos contractuales
          </Typography>

          <NavBarOcularVisit currentStep={3} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Tu visita concluyo, validaremos el resultado y prepararemos los documentos para firma, volveremos contigo a
            la brevedad
          </Typography>
        </Box>

        <Box mt={4} display="flex" justifyContent="center">
          <img src={imgVisita} width={isMobileOrTablet ? "100px" : "150px"} />
        </Box>

        <Typography
          mt={4}
          textAlign={"center"}
          variant={isMobileOrTablet ? "body2" : "body1"}
          color={theme?.palette?.primary?.dark}
        >
          ¡Despreocúpate!
        </Typography>

        <Typography
          mt={1}
          textAlign={"center"}
          variant={isMobileOrTablet ? "body2" : "body1"}
          color={theme?.palette?.primary?.dark}
        >
          Puedes salir de esta pantalla, tan pronto terminemos, te contactaremos via WhatsApp y puedes volver a iniciar
          sesión después
        </Typography>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: theme?.palette?.primary?.dark,
            }}
          >
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default VisitaOcularConcluida;
