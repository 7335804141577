/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { setAddressData, setLoanStatus } from "@store/slices/registerSlice";
import { ProgressBar } from "@components/ProgressBar";
import { CODIGO_POSTAL } from "@utils/expreciones";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { usePatchAddressMutation, usePostAddressMutation } from "@api/address";
import { useGetAddressTypeQuery } from "@api/catalogs";
import { Address } from "@interfaces/index";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { STATUS_CATALOG } from "@helpers/constantes";
import { getDynamicUrl } from "@helpers/funciones";
import { ApiError } from "@interfaces/response";

const DomicilioAccionista = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const img = getDynamicUrl("right_11.svg");

  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const addressData = useSelector((state: RootState) => state.register.address_data);
  const shareholder = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getCp] = useLazyGetByCpQuery();
  const { data: addressesData } = useGetAddressTypeQuery();
  const [postAddress] = usePostAddressMutation();
  const [patchAddress] = usePatchAddressMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [listColonias, setListColonias] = useState([]);
  const [addresType, setAddresType] = useState("");
  const [cpError, setCpError] = useState("");

  const formik = useFormik({
    initialValues: {
      cp: "",
      calle: "",
      num_ext: "",
      num_int: "",
      state_id: "",
      city_id: "",
      colony_id: "",
      state_name: "",
      city_name: "",
      municipality_name: "",
      otro: "",
    },
    validationSchema: yup.object().shape({
      cp: yup.string().required("Campo requerido").matches(CODIGO_POSTAL, "Código Postal inválido"),
      calle: yup.string().required("Campo requerido"),
      num_ext: yup.string().required("Campo requerido"),
      state_name: yup.string().required("Campo requerido"),
      city_name: yup.string().required("Campo requerido"),
      municipality_name: yup.string().required("Campo requerido"),
      colony_id: yup.string().required("Campo requerido"),
      otro: yup.string().when("colony_id", {
        is: "-1",
        then: (schema) => schema.required("Campo requerido"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);
          const dataAddress = {
            street: values.calle,
            no_ext: values.num_ext,
            no_int: values.num_int,
            colonia_id: Number(values.colony_id) === -1 ? null : +values.colony_id,
            state_id: +values.state_id,
            city_id: +values.city_id,
            country_id: 1,
            cp: values.cp,
            client_details_id: clientDetails.id,
            person_id: shareholder.id,
            address_type_id: addresType,
            other_neighborhood: values.otro,
          };

          const next_status = STATUS_CATALOG.consulta_buro.code;
          const next_url = STATUS_CATALOG.consulta_buro.url;
          let resultAddress = null;

          if (addressData && addressData.id) {
            resultAddress = await patchAddress({ addressId: addressData.id, data: dataAddress }).unwrap();
          } else {
            resultAddress = await postAddress(dataAddress).unwrap();

            await triggerPostNextStage({
              applicationId: applicationData.id,
              statusCode: next_status,
              username: `TOFU - ${accountUser.email}`,
            }).unwrap();
          }

          dispatch(
            setAddressData({
              id: resultAddress.data.id,
              street: values.calle,
              no_ext: values.num_ext,
              no_int: values.num_int,
              colony_id: +values.colony_id,
              state_id: +values.state_id,
              city_id: +values.city_id,
              postal_code: values.cp,
              state_name: values.state_name,
              city_name: values.city_name,
              other_neighborhood: values.otro,
            }),
          );

          dispatch(setLoanStatus(next_status));

          navigate(next_url);
        } catch (error) {
          setModalErrorVisible({ open: true, error: error as ApiError });
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  const handleCp = async (value: string) => {
    try {
      const cpData = await getCp(value);
      if (cpData) {
        const hasCities = cpData.data?.cities && cpData.data?.cities.length > 0;
        const hasStates = cpData.data?.states && cpData.data?.states.length > 0;
        const hasColonias = cpData.data?.colonias && cpData.data?.colonias.length > 0;

        if (!hasCities || !hasStates || !hasColonias) {
          formik.setFieldValue("state_id", "");
          formik.setFieldValue("city_id", "");
          formik.setFieldValue("colony_id", "");
          formik.setFieldValue("state_name", "");
          formik.setFieldValue("city_name", "");
          formik.setFieldValue("municipality_name", "");
          setCpError(`No existe el código postal`);
          setListColonias([]);
          return;
        }
        setCpError("");
        formik.setFieldValue("state_id", cpData.data?.states[0].id);
        formik.setFieldValue("city_id", cpData.data?.cities[0].id);
        formik.setFieldValue("colony_id", cpData.data?.colonias[0].id);
        formik.setFieldValue("state_name", cpData.data?.states[0].name);
        formik.setFieldValue("city_name", cpData.data?.cities[0].name);
        formik.setFieldValue("municipality_name", cpData.data?.cities[0].name);
        setListColonias(cpData.data?.colonias);
      }
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  useEffect(() => {
    if (addressesData) {
      const filteredAddresses = addressesData.filter((address: Address) => address.code === "CDPA");
      setAddresType(filteredAddresses[0].id);
    }
  }, [addressesData]);

  useEffect(() => {
    if (addressData && addressData.id) {
      handleCp(addressData.postal_code);
      formik.setFieldValue("cp", addressData.postal_code);
      formik.setFieldValue("calle", addressData.street);
      formik.setFieldValue("num_ext", addressData.no_ext);
      formik.setFieldValue("num_int", addressData.no_int);
      setTimeout(() => {
        formik.setFieldValue("colony_id", addressData.colony_id);
        formik.setFieldValue("otro", addressData.other_neighborhood);
      }, 800);
    }
  }, [addressData]);

  useEffect(() => {
    if (String(formik.values.colony_id) !== "-1") {
      formik.setFieldValue("otro", "");
    }
  }, [formik.values.colony_id]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={500}
              textAlign="center"
              color={theme?.palette?.primary?.dark}
            >
              Registro del accionista mayoritario
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={9} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3}>
              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                textAlign={"center"}
                color={theme?.palette?.primary?.dark}
              >
                Ingresa el domicilio del accionista mayoritario
              </Typography>
            </Box>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="cp"
                    name="cp"
                    label="Código Postal"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const cp = e.target.value.replace(/[^0-9]/g, "");
                      formik.setFieldValue("cp", cp);
                      if (cp.length === 5) {
                        handleCp(cp);
                      }
                    }}
                    error={!!cpError || !!formik.errors.cp}
                    helperText={cpError || (formik.touched.cp && formik.errors.cp)}
                    value={formik.values.cp}
                    inputProps={{ maxLength: 5 }}
                    disabled={formik.isSubmitting}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="calle"
                    name="calle"
                    label="Calle o avenida"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.calle && Boolean(formik.errors.calle)}
                    helperText={formik.touched.calle && formik.errors.calle}
                    value={formik.values.calle}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      mt: 2,
                    }}
                    error={formik.touched.colony_id && Boolean(formik.errors.colony_id)}
                  >
                    <InputLabel id="colony_id">Colonia</InputLabel>
                    <Select
                      labelId="colony_id"
                      id="colony_id"
                      name="colony_id"
                      fullWidth
                      required
                      label="Colonia"
                      value={formik.values.colony_id}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.colony_id && Boolean(formik.errors.colony_id)}
                      disabled={formik.isSubmitting}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listColonias.map((colonia: { id: number; name: string }) => (
                        <MenuItem key={colonia.id} value={colonia.id}>
                          {colonia.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.colony_id && formik.errors.colony_id && (
                      <FormHelperText>{formik.errors.colony_id}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <TextField
                    id="num_ext"
                    name="num_ext"
                    label="No. Exterior"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.num_ext && Boolean(formik.errors.num_ext)}
                    helperText={formik.touched.num_ext && formik.errors.num_ext}
                    value={formik.values.num_ext}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <TextField
                    id="num_int"
                    name="num_int"
                    label="No. Interior"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.num_int}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>

              {String(formik.values.colony_id) === "-1" && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="otro"
                        name="otro"
                        label="Otra Colonia"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.otro && Boolean(formik.errors.otro)}
                        helperText={formik.touched.otro && formik.errors.otro}
                        value={formik.values.otro}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="city_name"
                    name="city_name"
                    label="Ciudad"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.city_name && Boolean(formik.errors.city_name)}
                    helperText={formik.touched.city_name && formik.errors.city_name}
                    value={formik.values.city_name}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="municipality_name"
                    name="municipality_name"
                    label="Municipio"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.municipality_name && Boolean(formik.errors.municipality_name)}
                    helperText={formik.touched.municipality_name && formik.errors.municipality_name}
                    value={formik.values.municipality_name}
                    disabled
                  />
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <TextField
                  id="state_name"
                  name="state_name"
                  label="Entidad federativa"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.state_name && Boolean(formik.errors.state_name)}
                  helperText={formik.touched.state_name && formik.errors.state_name}
                  value={formik.values.state_name}
                  disabled
                />
              </FormControl>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 78px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DomicilioAccionista;
