import { CustomImageProps } from "@interfaces/response";

const IconError404 = (props: CustomImageProps) => {
  return (
    <svg
      width={props.width || "206"}
      height={props.height || "175"}
      viewBox="0 0 206 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9058 33.6407H4.31603C3.30375 33.6407 2.3456 34.0927 1.70413 34.8749C1.05995 35.6544 0.800113 36.6829 0.99499 37.6736L25.8473 164.479C26.1586 166.065 27.5498 167.21 29.1683 167.21H157.111V160.444H31.9535L8.4247 40.4073H197.551L178.729 133.605L168.687 123.563C177.2 106.473 174.336 85.1398 160.096 70.9002C142.273 53.077 113.334 53.077 95.5106 70.9002C77.6874 88.7234 77.6874 117.663 95.5106 135.486C113.334 153.309 142.273 153.309 160.096 135.486C161.961 133.621 163.628 131.637 165.106 129.55L190.578 155.022C193.599 158.043 193.599 162.942 190.578 165.963L190.573 165.968C187.552 168.989 182.653 168.989 179.633 165.968L158.876 145.208L154.09 149.993C154.09 149.993 166.376 162.279 174.847 170.753C180.512 176.416 189.693 176.416 195.358 170.753L195.361 170.748C201.026 165.083 201.026 155.902 195.361 150.24L184.472 139.348L205.002 37.6926C205.202 36.6992 204.945 35.6653 204.304 34.8804C203.662 34.0954 202.701 33.6407 201.686 33.6407H22.6697L22.3774 20.9249H135.536C136.66 20.9249 137.71 20.3673 138.34 19.439L146.907 6.76658H184.388V26.2462H191.155V3.38329C191.155 1.51571 189.642 0 187.772 0H145.11C143.986 0 142.936 0.557533 142.308 1.48862L133.742 14.1584H18.9264C18.0197 14.1584 17.1482 14.5265 16.5121 15.1734C15.8761 15.8229 15.5269 16.6999 15.5458 17.6093L15.9058 33.6407ZM100.293 75.6855C115.477 60.5013 140.129 60.5013 155.311 75.6855C170.492 90.867 170.492 115.519 155.311 130.701C140.129 145.885 115.477 145.885 100.293 130.701C85.1117 115.519 85.1117 90.867 100.293 75.6855ZM61.7778 135.405L78.7917 118.391L74.0064 113.608L56.9925 130.622L61.7778 135.405ZM31.542 109.459H72.4988V102.692H31.542V109.459ZM55.263 80.9527L72.2769 97.9665L77.0622 93.1812L60.0483 76.1673L55.263 80.9527Z"
        fill={props.fill || "#528CD6"}
      />
    </svg>
  );
};

export default IconError404;
