import logo_lendia from "@assets/img/icon_lendia.svg";
import logo_finbe from "@assets/img/finbe/icon_logo_menu.svg";
import { getBaseDomain } from "@helpers/funciones";
import { HOSTNAMES } from "@helpers/constantes";

interface LogoSmallProps {
  width?: string;
  height?: string;
}

const LogoSmall = (props: LogoSmallProps) => {
  const hostname = getBaseDomain(window.location.href);
  const foundHost = HOSTNAMES.find(({ host }) => host === hostname);

  if (foundHost?.value === "finbe") {
    return <img src={logo_finbe} alt="logo" width={props.width || "40px"} height={props.height || "45px"} />;
  } else {
    return <img src={logo_lendia} alt="logo" width={props.width || "40px"} height={props.height || "45px"} />;
  }
};

export default LogoSmall;
