/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetScheduleByApplicationIdQuery } from "@api/applications";
import { useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import { usePostValidateNextStageMutation } from "@api/applications";
import { getDynamicUrl } from "@helpers/funciones";
import { STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";

const ConfirmacionDomicilio = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const img = getDynamicUrl("img_tofu/img_tofu_6.svg");
  const timerAgendarVisita = useRef<NodeJS.Timeout | null>(null);

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const applicationData = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getSchedule] = useLazyGetScheduleByApplicationIdQuery();
  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [documentsRequired, setDocumentsRequired] = useState<string[]>([]);

  const validateForStatusChange = async () => {
    try {
      const [loan_application, schedule_response] = await Promise.all([
        getLoanApplication(applicationData.id).unwrap(),
        getSchedule(applicationData.id).unwrap(),
      ]);

      const schedule_data = schedule_response?.data.data[0];

      if (loan_application?.ocular_visit && schedule_data) {
        const { code: next_status, url: next_url } = STATUS_CATALOG.confirmacion_visita_agendada;

        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();

        navigate(next_url);
        return true;
      }

      return false;
    } catch (error) {
      console.error("Ha ocurrido un error al validar el status");
      return false;
    }
  };

  useEffect(() => {
    const startValidations = async () => {
      const result = await validateForStatusChange();
      if (!result) {
        timerAgendarVisita.current = setInterval(() => {
          validateForStatusChange();
        }, 60000);
      }
    };

    startValidations();

    return () => {
      timerAgendarVisita.current && clearInterval(timerAgendarVisita.current);
    };
  }, []);

  useEffect(() => {
    if (applicationData.type === TAX_SYSTEMS.PFAE.code) {
      setDocumentsRequired([
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    } else {
      setDocumentsRequired([
        "Caratula",
        "Acta constitutiva",
        "Actas adicionales",
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box mx={4}>
          <Typography
            mt={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h5" : "h4"}
            color={theme?.palette?.primary?.dark}
            fontWeight={700}
          >
            Agendando tu visita ocular
          </Typography>

          <NavBarOcularVisit currentStep={1} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Gracias por confirmar tu domicilio, tu visita está en proceso de ser agendada
          </Typography>

          <Box
            sx={{
              backgroundColor: theme?.palette?.phoneInput?.main,
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
              mt: 4,
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={600}
              color={theme?.palette?.primary?.dark}
              textAlign="center"
            >
              Recuerda que el día de la visita deberás presentar los siguientes documentos
            </Typography>

            <List>
              {documentsRequired.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "15px" }}>
                    <Box width={12} height={12} mr={1} sx={{ backgroundColor: theme?.palette?.primary?.main }}></Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant={isMobileOrTablet ? "body2" : "body1"}>{item}</Typography>}
                    sx={{
                      fontSize: isMobileOrTablet ? "13px" : "16px",
                      color: theme?.palette?.primary?.dark,
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            ¡Despreocúpate!
          </Typography>

          <Typography
            mt={1}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            Puedes salir de esta pantalla, tan pronto terminemos, te contactaremos via WhatsApp y puedes volver a
            iniciar sesión después
          </Typography>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmacionDomicilio;
