import { UUID } from "crypto";

import {
  AccountUser,
  CompanyData,
  CreditUseSelected,
  LoanApplication,
  PersonalData,
  ShareHolderData,
  AddressDataSession,
  DigitalPresence,
} from "@interfaces/store";
import { LoanStatus } from "@interfaces/catalogs";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { STATUS_BOFU_COMPLETED, STATUS_BOFU_REJECTED, STATUS_TOFU, STATUS_WAITING_ROOM } from "@helpers/constantes";

export interface RegisterState {
  application: LoanApplication;
  account_user: AccountUser;
  personal_data: PersonalData;
  address_data: AddressDataSession;
  company: CompanyData;
  credit_use: CreditUseSelected;
  digital_presence: DigitalPresence;
  shareholder: ShareHolderData;
  loan_status: string;
  routes_group: "TOFU" | "BOFU" | "WAITING_ROOM" | "BOFU_COMPLETED" | "BOFU_REJECTED" | "";
}

const initialState: RegisterState = {
  application: {
    id: "00000000-0000-0000-0000-000000000000",
    type: "",
    loan_amount: 0,
    loan_term: 0,
    amount_monthly: 0,
    tax_system_id: undefined,
    product_id: undefined,
    id_application: 0,
    approved_amount: 0,
    approved_term: 0,
    interest_rate: 0,
    application_condition_id: "",
    client_details_id: "00000000-0000-0000-0000-000000000000",
    completed: false,
    company_application_code: "",
    is_correct_ciec: false,
    source_code: "",
    ocular_visit: false,
  },
  account_user: {
    id: 0,
    email: "",
    password: "",
  },
  personal_data: {
    id: "00000000-0000-0000-0000-000000000000",
    name: "",
    first_lastname: "",
    second_lastname: "",
    country_code: "",
    phone: "",
    person_type_id: "",
    rol_detail: "",
    addressId: "",
    cell_phone_verified: false,
  },
  address_data: {
    id: "",
    street: "",
    no_ext: "",
    no_int: "",
    colony_id: 0,
    state_id: 0,
    city_id: 0,
    postal_code: "",
    state_name: "",
    city_name: "",
    other_neighborhood: "",
  },
  company: {
    id: "",
    rfc: "",
    name: "",
    legal_name: "",
    role: "",
    ciec_pass: "",
    authorize_sat: false,
    is_pfae: false,
    pfae_name: "",
    pfae_last_name: "",
    pfae_last_name_2: "",
    is_correct_ciec: false,
  },
  credit_use: {
    selected: [],
    reason: "",
  },
  digital_presence: {
    id: undefined,
    has_web_page: false,
    web_page: "",
    has_social_media: false,
    linkedin: "",
    facebook: "",
    instagram: "",
    twitter: "",
  },
  shareholder: {
    id: "",
    name: "",
    first_lastname: "",
    second_lastname: "",
    date_birth: "",
    birth_state: "",
    sex: "",
    email: "",
    rfc: "",
    rfc_homoclave: "",
    curp: "",
    addressId: "",
    person_type: {
      id: "0-0-0-0-0",
      code: "",
    },
    bureau_inquiry: {
      prestamo_hipotecario: false,
      credito_auto: false,
      tarjeta_credito: false,
      tarjeta: "",
      autorizacion_buro: false,
    },
    check_sat_bureau: false,
  },
  loan_status: "",
  routes_group: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setTypePerson: (state, action: PayloadAction<string>) => {
      state.application.type = action.payload;
    },
    setConditionLoan: (
      state,
      action: PayloadAction<{ loanTerm: number; loanAmount: number; amountMonthly: number }>,
    ) => {
      state.application.loan_term = action.payload.loanTerm;
      state.application.loan_amount = action.payload.loanAmount;
      state.application.amount_monthly = action.payload.amountMonthly;
    },
    setNewConditionLoan: (
      state,
      action: PayloadAction<{
        loanTerm: number;
        loanAmount: number;
        amountMonthly: number;
        approved_amount: number;
        approved_term: number;
        interest_rate: number;
      }>,
    ) => {
      state.application.loan_term = action.payload.loanTerm;
      state.application.loan_amount = action.payload.loanAmount;
      state.application.amount_monthly = action.payload.amountMonthly;
      state.application.approved_amount = action.payload.approved_amount;
      state.application.approved_term = action.payload.approved_term;
      state.application.interest_rate = action.payload.interest_rate;
    },
    setLoanId: (state, action: PayloadAction<string>) => {
      state.application.id = action.payload;
    },
    setClientDetailsId: (state, action: PayloadAction<string>) => {
      state.application.client_details_id = action.payload;
    },
    setTaxProductId: (state, action: PayloadAction<{ tax_system_id: UUID; product_id: UUID; source_code: string }>) => {
      state.application.tax_system_id = action.payload.tax_system_id;
      state.application.product_id = action.payload.product_id;
      state.application.source_code = action.payload.source_code;
    },
    setLoanApplication: (state, action: PayloadAction<LoanApplication>) => {
      state.application = action.payload;
    },
    setId_application: (state, action: PayloadAction<number>) => {
      state.application.id_application = action.payload;
    },
    set_Id_applicationCondition: (state, action: PayloadAction<string>) => {
      state.application.application_condition_id = action.payload;
    },
    setAccountUser: (state, action: PayloadAction<RegisterState["account_user"]>) => {
      state.account_user.id = action.payload.id;
      state.account_user.email = action.payload.email;
      state.account_user.password = action.payload.password;
    },
    setAccountUserId: (state, action: PayloadAction<RegisterState["account_user"]["id"]>) => {
      state.account_user.id = action.payload;
    },
    setPersonalData: (state, action: PayloadAction<RegisterState["personal_data"]>) => {
      state.personal_data.id = action.payload.id;
      state.personal_data.name = action.payload.name;
      state.personal_data.first_lastname = action.payload.first_lastname;
      state.personal_data.second_lastname = action.payload.second_lastname;
      state.personal_data.country_code = action.payload.country_code;
      state.personal_data.phone = action.payload.phone;
      state.personal_data.person_type_id = action.payload.person_type_id;
      state.personal_data.rol_detail = action.payload.rol_detail;
      state.personal_data.cell_phone_verified = action.payload.cell_phone_verified;
    },
    setAddressData: (state, action: PayloadAction<RegisterState["address_data"]>) => {
      state.address_data.id = action.payload.id;
      state.address_data.street = action.payload.street;
      state.address_data.no_ext = action.payload.no_ext;
      state.address_data.no_int = action.payload.no_int;
      state.address_data.colony_id = action.payload.colony_id;
      state.address_data.state_id = action.payload.state_id;
      state.address_data.city_id = action.payload.city_id;
      state.address_data.postal_code = action.payload.postal_code;
      state.address_data.state_name = action.payload.state_name;
      state.address_data.city_name = action.payload.city_name;
      state.address_data.other_neighborhood = action.payload.other_neighborhood;
    },
    setCompanyData: (state, action: PayloadAction<RegisterState["company"]>) => {
      state.company.id = action.payload.id;
      state.company.rfc = action.payload.rfc;
      state.company.name = action.payload.name;
      state.company.legal_name = action.payload.legal_name;
      state.company.is_pfae = action.payload.is_pfae;
      state.company.pfae_name = action.payload.pfae_name;
      state.company.pfae_last_name = action.payload.pfae_last_name;
      state.company.pfae_last_name_2 = action.payload.pfae_last_name_2;
      state.company.is_correct_ciec = action.payload.is_correct_ciec;
    },
    setCreditUse: (state, action: PayloadAction<RegisterState["credit_use"]>) => {
      state.credit_use.selected = action.payload.selected;
      state.credit_use.reason = action.payload.reason;
    },
    setDigitalPresence: (state, action: PayloadAction<RegisterState["digital_presence"]>) => {
      state.digital_presence.id = action.payload.id;
      state.digital_presence.has_web_page = action.payload.has_web_page;
      state.digital_presence.web_page = action.payload.web_page;
      state.digital_presence.has_social_media = action.payload.has_social_media;
      state.digital_presence.linkedin = action.payload.linkedin;
      state.digital_presence.facebook = action.payload.facebook;
      state.digital_presence.instagram = action.payload.instagram;
      state.digital_presence.twitter = action.payload.twitter;
    },
    setShareHolderData: (state, action: PayloadAction<RegisterState["shareholder"]>) => {
      state.shareholder.id = action.payload.id;
      state.shareholder.name = action.payload.name;
      state.shareholder.first_lastname = action.payload.first_lastname;
      state.shareholder.second_lastname = action.payload.second_lastname;
      state.shareholder.date_birth = action.payload.date_birth;
      state.shareholder.birth_state = action.payload.birth_state;
      state.shareholder.sex = action.payload.sex;
      state.shareholder.email = action.payload.email;
      state.shareholder.rfc = action.payload.rfc;
      state.shareholder.email = action.payload.email;
      state.shareholder.person_type = action.payload.person_type;
    },
    setShareHolderRfcCurp: (state, action: PayloadAction<{ rfc: string; rfc_homoclave: string; curp: string }>) => {
      state.shareholder.rfc = action.payload.rfc;
      state.shareholder.rfc_homoclave = action.payload.rfc_homoclave;
      state.shareholder.curp = action.payload.curp;
    },
    setShareHolderDataBureauInquiry: (state, action: PayloadAction<RegisterState["shareholder"]["bureau_inquiry"]>) => {
      state.shareholder.bureau_inquiry.prestamo_hipotecario = action.payload.prestamo_hipotecario;
      state.shareholder.bureau_inquiry.credito_auto = action.payload.credito_auto;
      state.shareholder.bureau_inquiry.tarjeta_credito = action.payload.tarjeta_credito;
      state.shareholder.bureau_inquiry.tarjeta = action.payload.tarjeta;
      state.shareholder.bureau_inquiry.autorizacion_buro = action.payload.autorizacion_buro;
    },
    updateApplicationCompleted: (state, action: PayloadAction<boolean>) => {
      state.application.completed = action.payload;
    },
    setLoanStatus: (state, action: PayloadAction<LoanStatus>) => {
      state.loan_status = action.payload;

      if (STATUS_TOFU.includes(action.payload)) {
        state.routes_group = "TOFU";
      } else if (STATUS_WAITING_ROOM.includes(action.payload)) {
        state.routes_group = "WAITING_ROOM";
      } else if (STATUS_BOFU_COMPLETED.includes(action.payload)) {
        state.routes_group = "BOFU_COMPLETED";
      } else if (STATUS_BOFU_REJECTED.includes(action.payload)) {
        state.routes_group = "BOFU_REJECTED";
      } else {
        state.routes_group = "BOFU";
      }
    },
    setCheckSatBureau: (state, action: PayloadAction<{ check_sat_bureau: boolean }>) => {
      state.shareholder.check_sat_bureau = action.payload.check_sat_bureau;
    },
  },
});

export const {
  setTypePerson,
  setConditionLoan,
  setLoanId,
  setClientDetailsId,
  setId_application,
  setLoanApplication,
  setAccountUser,
  setAccountUserId,
  setPersonalData,
  setCompanyData,
  setCreditUse,
  setDigitalPresence,
  setShareHolderData,
  setTaxProductId,
  setShareHolderRfcCurp,
  set_Id_applicationCondition,
  updateApplicationCompleted,
  setNewConditionLoan,
  setLoanStatus,
  setAddressData,
  setCheckSatBureau,
} = registerSlice.actions;
export default registerSlice.reducer;
