/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  DocumentResponse,
  // ContractualDocumentResponse,
  DocumentContract,
  DocumentContractResponse,
  PayloadDocument,
  PayloadDocumentGenerate,
  PayloadSignUrl,
  PayloadUploadFile,
  Response,
  SignedUrlFileResponse,
  UrlDownloadDocument,
} from "src/types";

const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSignUrlToUpload: build.query<SignedUrlFileResponse, PayloadSignUrl>({
      query: (data: PayloadSignUrl) => ({
        url: `generar_url/subir`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: SignedUrlFileResponse }) => {
        return response.data;
      },
    }),
    getUrlToDownloadDoc: build.mutation<string, string>({
      query: (fileKey) => ({
        url: `generar_url/descargar`,
        method: "POST",
        body: {
          file_key: fileKey,
        },
      }),
      transformResponse: (response: UrlDownloadDocument) => {
        return response.data;
      },
    }),
    getUrlToDownloadZip: build.mutation<string, string>({
      query: (files) => ({
        url: `generar_url/descargar_zip`,
        method: "POST",
        body: {
          files: files,
        },
      }),
      transformResponse: (response: UrlDownloadDocument) => {
        return response.data;
      },
    }),
    uploadFile: build.mutation<Response, PayloadUploadFile>({
      query: (data: PayloadUploadFile) => ({
        url: data.url,
        method: "POST",
        body: data.body,
        formData: true,
      }),
    }),
    postDocument: build.mutation<DocumentResponse, PayloadDocument>({
      query: (data: PayloadDocument) => ({
        url: "documentos",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: DocumentResponse }) => {
        return response.data;
      },
    }),
    deleteDocument: build.mutation<Response, UUID>({
      query: (id: UUID) => ({
        url: `documentos/${id}`,
        method: "DELETE",
      }),
    }),
    patchDocument: build.mutation<Response, { documentId: UUID | undefined; body: any }>({
      query: ({ documentId, body }) => ({
        url: `documentos/${documentId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    postDocumentGenarate: build.mutation<Response, PayloadDocumentGenerate>({
      query: (data: PayloadDocumentGenerate) => ({
        url: "generar_documento",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: { data: Response }) => {
        return response.data;
      },
    }),
    getContractualDocumentLoanApplication: build.query<
      DocumentContract[],
      { loanApplicationId: string; clientId: string }
    >({
      query: ({ loanApplicationId, clientId }) => ({
        url: `documentos/documents_contract?q=loan_application_id:${loanApplicationId}&q=client_details_id:${clientId}&q=active:true`,
      }),
      transformResponse: (response: DocumentContractResponse) => {
        return response.data.data;
      },
    }),
    getDocumentAppId: build.query<
      DocumentResponse[],
      { loanApplicationId: string; clientDetailsId?: string; personId?: string; status?: boolean }
    >({
      query: ({ loanApplicationId, clientDetailsId, personId, status }) => {
        let url = `documentos?loan_application_id=${loanApplicationId}`;

        if (clientDetailsId) url += `&client_details_id=${clientDetailsId}`;
        if (personId) url += `&person_id=${personId}`;
        if (status !== undefined) url += `&status=${status}`;

        return {
          url,
        };
      },
      transformResponse: (response: { data: DocumentResponse[] }) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyGetSignUrlToUploadQuery,
  useUploadFileMutation,
  usePostDocumentMutation,
  useDeleteDocumentMutation,
  usePatchDocumentMutation,
  usePostDocumentGenarateMutation,
  useGetUrlToDownloadDocMutation,
  useLazyGetContractualDocumentLoanApplicationQuery,
  useGetUrlToDownloadZipMutation,
  useLazyGetDocumentAppIdQuery,
} = accountApi;
