import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface CustomDatePickerProps {
  label: string;
  id?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  error?: boolean;
  helperText?: string | undefined | boolean;
  disabled?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DatePicker
        label={props.label}
        value={props.value ? dayjs(props.value) : null}
        onChange={(date) => {
          const newEvent = {
            target: {
              name: props.name,
              value: date ? date.format("YYYY-MM-DD") : "",
            },
          } as React.ChangeEvent<HTMLInputElement>;
          props.onChange(newEvent);
        }}
        minDate={props.minDate}
        maxDate={props.maxDate}
        disabled={props.disabled}
        slotProps={{
          textField: {
            id: props.id,
            name: props.name,
            error: props.error,
            helperText: props.helperText,
            onBlur: props.onBlur,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
