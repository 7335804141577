/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useLazyGetScheduleByApplicationIdQuery, useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { InactivityHandler } from "@components/InactivityHandler";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { getDynamicUrl } from "@helpers/funciones";
import { STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";

const ConfirmacionVisitaAgendada = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const img = getDynamicUrl("img_tofu/img_tofu_5.svg");

  const applicationData = useSelector((state: RootState) => state.register.application);

  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();
  const [getSchedule, { data: scheduleData, isLoading, error, isError }] = useLazyGetScheduleByApplicationIdQuery();

  const [documentsRequired, setDocumentsRequired] = useState<string[]>([]);
  const [visitDate, setVisitDate] = useState<string | null>(null);
  const [visitTime, setVisitTime] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("Cargando...");

  function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
    return typeof error === "object" && error !== null && "status" in error;
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const loan_application = await getLoanApplication(applicationData.id).unwrap();
        if (loan_application?.status_catalog?.code === STATUS_CATALOG.visita_ocular_concluida.code) {
          navigate(STATUS_CATALOG.visita_ocular_concluida.url);
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Ha ocurrido un error al validar el estado de la solicitud");
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [applicationData.id]);

  useEffect(() => {
    const solicitudId = applicationData.id;
    if (solicitudId) {
      getSchedule(applicationData.id);
    }
  }, [getSchedule, applicationData.id]);

  // TODO: Falta refactorizar este useEffect
  useEffect(() => {
    if (scheduleData && scheduleData.data) {
      if (!scheduleData.data.data || scheduleData.data.data.length === 0) {
        setMessage("No se encontró ninguna visita programada.");
      } else if (scheduleData.data.data.length >= 0) {
        const schedule = scheduleData.data.data[0];
        if (schedule && schedule.date) {
          const date = new Date(schedule.date);
          const formattedDate = date.toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "long",
          });
          const formattedTime = date.toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "UTC",
          });

          setVisitDate(formattedDate);
          setVisitTime(formattedTime);
        }
      }
    } else if (isError && isFetchBaseQueryError(error)) {
      if (error.status === 404) {
        setVisitDate(null);
        setVisitTime(null);
        setMessage("No se encontró ninguna visita programada.");
      } else {
        setVisitDate(null);
        setVisitTime(null);
        setMessage("Error al cargar los datos. Inténtalo de nuevo más tarde.");
      }
    } else if (isError) {
      setVisitDate(null);
      setVisitTime(null);
      setMessage("Error desconocido. Inténtalo de nuevo más tarde.");
    }
  }, [scheduleData, isError, error]);

  useEffect(() => {
    if (applicationData.type === TAX_SYSTEMS.PFAE.code) {
      setDocumentsRequired([
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    } else {
      setDocumentsRequired([
        "Caratula",
        "Acta constitutiva",
        "Actas adicionales",
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box mx={4}>
          <Typography
            mt={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h5" : "h4"}
            color={theme?.palette?.primary?.dark}
            fontWeight={700}
          >
            Visita agendada
          </Typography>

          <NavBarOcularVisit currentStep={2} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Te visitaremos el día
          </Typography>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: theme?.palette?.primary?.dark,
                borderRadius: "10px",
                width: isMobileOrTablet ? "165px" : "200px",
                height: isMobileOrTablet ? "65px" : "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                fontWeight={700}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.light}
              >
                {isLoading ? "Cargando..." : visitDate ? visitDate : message} <br />
              </Typography>
              <Typography
                align="center"
                fontWeight={700}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.light}
              >
                {isLoading ? "" : visitTime ? visitTime : ""}
              </Typography>
            </Box>
          </Box>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Recibirás un recordatorio vía WhatsApp una hora antes de tu visita y cuando el ejecutivo de visitas se
            encuentre en el domicilio
          </Typography>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            En caso de que necesites reagendar tu vista, contacta a tu asesor
          </Typography>

          <Box
            sx={{
              backgroundColor: theme?.palette?.phoneInput?.main,
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
            }}
            mt={3}
            mb={3}
          >
            <Typography fontWeight={600} color={theme?.palette?.primary?.dark} fontSize={"14px"} align="center">
              Recuerda que el día de la visita deberás presentar los siguientes documentos
            </Typography>

            <List>
              {documentsRequired.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "15px" }}>
                    <Box width={12} height={12} mr={1} sx={{ backgroundColor: theme?.palette?.primary?.main }}></Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant={isMobileOrTablet ? "body2" : "body1"}>{item}</Typography>}
                    sx={{
                      color: theme?.palette?.primary?.dark,
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmacionVisitaAgendada;
