import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CustomCheckmark from "@assets/img/icon_check.svg";

const steps = [
  { label: "Agendar \nvisita" },
  { label: "Visita" },
  { label: "Análisis visita y \ndocumentos" },
  { label: "Firma" },
];

interface ProgressNavbarProps {
  currentStep: number;
}

export const NavBarOcularVisit: React.FC<ProgressNavbarProps> = ({ currentStep }) => {
  const totalStepsForBar = 4;
  const adjustedProgress = currentStep === 5 ? 95 : (currentStep / totalStepsForBar) * 100;
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width="90%" mx="auto" display="flex" flexDirection="column" alignItems="center" mt={4}>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
        {steps.map((step, index) => (
          <Box key={index} display="flex" flexDirection="column" alignItems="center" width="100%">
            <Box
              sx={{
                width: isMobileOrTablet ? 22 : 30,
                height: isMobileOrTablet ? 22 : 30,
                borderRadius: "50%",
                backgroundColor:
                  currentStep === 0
                    ? "#fff"
                    : currentStep > index + 1
                    ? theme?.palette?.primary?.dark
                    : currentStep === index + 1
                    ? theme?.palette?.primary?.dark
                    : "#fff",
                border: `2px solid ${
                  currentStep > index ? theme?.palette?.primary?.dark : theme?.palette?.primary?.main
                }`,
                color:
                  currentStep === index + 1 ? "#fff" : currentStep > index + 1 ? "#fff" : theme?.palette?.primary?.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 16,
                fontWeight: currentStep > index || currentStep === index + 1 ? "bold" : "normal",
                fontFamily: "Outfit, sans-serif",
              }}
            >
              {currentStep > index + 1 ? (
                <Box
                  component="img"
                  src={CustomCheckmark}
                  alt="Checkmark"
                  sx={{
                    width: isMobileOrTablet ? 16 : 18,
                    height: isMobileOrTablet ? 16 : 18,
                    color: "#00A024",
                  }}
                ></Box>
              ) : (
                index + 1
              )}
            </Box>
            <Typography
              mt={1}
              textAlign="center"
              variant={isMobileOrTablet ? "body2" : "body1"}
              whiteSpace="pre-wrap"
              fontWeight={currentStep === index + 1 ? "700" : "300"}
              color={
                currentStep > index || currentStep === index + 1
                  ? theme?.palette?.primary?.dark
                  : theme?.palette?.primary?.main
              }
            >
              {step.label}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor: theme?.palette?.backgroundRight?.tab,
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: currentStep > 0 ? `${adjustedProgress}%` : "0%",
              backgroundColor: theme?.palette?.primary?.dark,
              transition: "width 0.3s ease",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
