/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetMetaMapDataQuery } from "@api/metaMapApi";
import { useEffect, useRef, useState } from "react";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { getDynamicUrl } from "@helpers/funciones";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const ValidarIndentidadPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const applicationData = useSelector((state: RootState) => state.register.application);
  const personal = useSelector((state: RootState) => state.register.personal_data);
  const img = getDynamicUrl("img_tofu/img_tofu_9.svg");

  const [getDataMetaMap, { data, isSuccess }] = useLazyGetMetaMapDataQuery();

  const [getLoadApplication, { data: appData, isSuccess: successSol }] = useLazyGetApplicationByApplicationIdQuery();

  const metamapButtonRef = useRef<HTMLDivElement>(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isIdentityCompleted, setIsIdentityCompleted] = useState(null);

  const [attempts, setAttempts] = useState(0);

  const dispatch = useAppDispatch();

  const [metaDataJson, setMetaDataJson] = useState({
    client_details_id: "",
    loan_application_id: "",
    regimen: "",
    upload_type: "",
    attempts: 0,
  });

  useEffect(() => {
    if (personal?.id) {
      metaMapData(String(personal.id));
    }
  }, [personal]);

  useEffect(() => {
    getApplication();
  }, []);

  useEffect(() => {
    if (data?.data && isSuccess) {
      setMetaDataJson({
        client_details_id: data?.data?.client_details_id,
        loan_application_id: data?.data?.loan_application_id,
        regimen: data?.data?.regimen,
        attempts: data?.data?.attempts,
        upload_type: data?.data?.upload_type,
      });
      setAttempts(data?.data?.attempts);
      setIsLoading(false);
    }
  }, [data?.data, isSuccess]);

  useEffect(() => {
    if (appData && successSol) {
      setIsIdentityCompleted(appData?.data[0]?.identity_validation_completed);
    }
  }, [appData?.data, successSol]);

  useEffect(() => {
    if (isIdentityCompleted) {
      setIsDisabled(true);
    } else {
      if (attempts > 3) {
        setIsDisabled(true);
      }
    }
  }, [attempts, isIdentityCompleted]);

  const metaMapData = async (id: string) => {
    try {
      await getDataMetaMap(id).unwrap();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  const getApplication = async () => {
    try {
      await getLoadApplication(applicationData.id).unwrap();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  useEffect(() => {
    const handleUserFinishedSdk = (_event: CustomEvent) => {
      setIsDisabled(true);
    };

    const metamapButton = metamapButtonRef.current;

    if (metamapButton) {
      metamapButton.addEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
    }

    return () => {
      if (metamapButton) {
        metamapButton.removeEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
      }
    };
  }, []);

  const goBack = () => {
    window.location.href = "/producto/creditosimple/avance_expediente_digitalpfae";
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={1}>
              <Typography align={"center"} fontSize={"16px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
                Validación identidad
              </Typography>
            </Box>

            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={theme?.palette?.primary?.main}
              align="center"
              mt={3}
              mb={3}
            >
              {`${personal.name} ${personal.first_lastname} ${personal.second_lastname}`}
            </Typography>

            <Typography
              align={"center"}
              fontSize={"14px"}
              fontWeight={600}
              color={theme?.palette?.primary?.dark}
              mt={3}
              mb={3}
            >
              Validaremos tu identidad por medio de fotografías faciales y tu identificación oficial
            </Typography>

            <Box mt={2} mb={2} sx={{ pointerEvents: isLoading ? "none" : "auto", opacity: isLoading ? 0.5 : 1 }}>
              <div style={{ pointerEvents: isDisabled ? "none" : "auto", opacity: isDisabled ? 0.5 : 1 }}>
                <metamap-button
                  ref={metamapButtonRef}
                  clientid={import.meta.env.VITE_META_CLIENT_ID}
                  flowId={import.meta.env.VITE_META_FLOW_ID}
                  merchantToken={import.meta.env.VITE_MERCHANT_TOKEN}
                  language="es"
                  metadata={JSON.stringify(metaDataJson)}
                  color={theme?.palette?.primary?.main}
                />
              </div>
            </Box>

            <Box display="flex" justifyContent="center" mt={4}>
              <Button variant="blue_outlined" type="button" onClick={() => goBack()}>
                Regresar al expediente
              </Button>
            </Box>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 78px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ValidarIndentidadPfae;
