/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomCheckList } from "@components/CustomCheckList";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePutApplicationUpdateStatusMutation } from "@api/applications";
import { useGetStatusQuery } from "@api/catalogs";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/funciones";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const InfoDocumentosPFAE = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const navigate = useNavigate();
  const img = getDynamicUrl("img_tofu/img_tofu_1.svg");
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [upDateStatus] = usePutApplicationUpdateStatusMutation();
  const { data: statusList } = useGetStatusQuery();

  useEffect(() => {
    if (statusList) {
      const aproved = statusList.find((item: { code: string }) => item.code === "CSAED");
      setStatus(String(aproved?.id));
    }
  }, [statusList]);

  const handleOnClickNext = async () => {
    try {
      setIsLoading(true);
      navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
      const data = {
        status_id: status,
      };
      await upDateStatus({ id_sol: application.id, body: data });
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography
              mt={4}
              mb={3}
              textAlign={"center"}
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={600}
              color={theme?.palette?.primary?.dark}
            >
              ¡Hola {personalData.name}!
            </Typography>
            <Typography
              mb={3}
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={theme?.palette?.primary?.main}
              fontWeight={600}
            >
              Ahora completa el expediente digital.
            </Typography>
            <Typography
              mb={3}
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={theme?.palette?.primary?.dark}
            >
              Conforme avances, deberás cargar los siguientes documentos, si no los tienes a la mano puedes guardar tu
              avance y completarlo después
            </Typography>
            <CustomCheckList
              id="empresa"
              sx={{ mt: 3 }}
              title="Del dueño"
              items={["Constancia de situación fiscal", "Identificación oficial vigente (INE, Pasaporte,FM2)"]}
            />

            <Box mt={2}>
              <Button variant="default" onClick={handleOnClickNext} disabled={isLoading}>
                Continuar
              </Button>
            </Box>
          </Box>
        </Grid>
        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={theme?.palette?.primary?.light}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 78px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InfoDocumentosPFAE;
