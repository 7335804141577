/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  setAccountUser,
  setCompanyData,
  setLoanApplication,
  setPersonalData,
  setShareHolderData,
  setLoanStatus,
  setAddressData,
  setShareHolderRfcCurp,
  setDigitalPresence,
  setCreditUse,
} from "@store/slices/registerSlice";
import { restoreBofuCompanyData, restoreBofuGuaranteeData, restoreBofuOwnersData } from "@store/slices/bofuSlice";
import { setProcessPage } from "@store/slices/appSlice";
import { PayloadRequestPhoneCode, PayloadValidatePhoneCode, SessionInfoResponse } from "@interfaces/index";
import { restoreBofuPfaeData } from "@store/slices/bofuSlicePfae";
import { TAX_SYSTEMS } from "@helpers/constantes";

const sessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSessionInfo: build.query<SessionInfoResponse, UUID | string>({
      query: (sso_id: UUID | string) => ({
        url: "session_info",
        params: {
          sso_id: sso_id,
        },
      }),
      transformResponse: (response: SessionInfoResponse) => {
        return response;
      },
      async onQueryStarted(_arg, api) {
        const { data } = await api.queryFulfilled;

        api.dispatch(setLoanStatus(data.data?.current_screen.code));
        api.dispatch(setProcessPage(data.data?.current_screen.url));
        api.dispatch(setLoanApplication(data.data?.loan_application_data));
        api.dispatch(setAccountUser(data.data?.user_data));
        api.dispatch(setPersonalData(data.data?.account_data));

        if (data.data.credit_use) {
          api.dispatch(
            setCreditUse({
              selected: data.data.credit_use.map((item: any) => item.resources_use_id),
              reason: data.data.credit_use.find((item) => item.reason)?.reason || "",
            }),
          );
        }

        if (data.data?.social_media) {
          api.dispatch(
            setDigitalPresence({
              id: data.data?.social_media.id,
              has_web_page: data.data?.social_media?.has_web_page,
              web_page: data.data?.social_media?.web_page,
              has_social_media: data.data?.social_media.has_social_media,
              linkedin: data.data?.social_media.linkedin,
              facebook: data.data?.social_media.facebook,
              instagram: data.data?.social_media.instagram,
              twitter: data.data?.social_media.twitter,
            }),
          );
        }

        if (data.data?.loan_application_data?.type === TAX_SYSTEMS.PM.code) {
          api.dispatch(
            setCompanyData({
              id: data.data?.account_data?.id,
              name: data.data?.account_data?.company_name,
              rfc: data.data?.account_data?.company_rfc,
              legal_name: data.data?.account_data?.company_legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_correct_ciec: data.data?.loan_application_data.is_correct_ciec,
            }),
          );

          const shareholder_data = data.data?.shareholder_data;

          if (shareholder_data) {
            api.dispatch(setShareHolderData(shareholder_data));

            if (shareholder_data.rfc && shareholder_data.rfc.length > 10) {
              api.dispatch(
                setShareHolderRfcCurp({
                  rfc: shareholder_data.rfc.slice(0, 10),
                  rfc_homoclave: shareholder_data.rfc.slice(10, 13),
                  curp: shareholder_data.curp,
                }),
              );
            }

            if (shareholder_data.address) {
              const shareholder_address = shareholder_data.address;
              api.dispatch(
                setAddressData({
                  id: shareholder_address.id,
                  street: shareholder_address.street,
                  no_ext: shareholder_address.no_ext,
                  no_int: shareholder_address.no_int,
                  colony_id: shareholder_address.colonia_id,
                  state_id: +shareholder_address.state_id,
                  city_id: +shareholder_address.city_id,
                  postal_code: shareholder_address.postal_code,
                  state_name: shareholder_address.state,
                  city_name: shareholder_address.municipality,
                  other_neighborhood: shareholder_address.other_neighborhood,
                }),
              );
            }
          }

          if (data.data?.bofu_data) {
            if (data.data?.bofu_data?.company) {
              api.dispatch(
                restoreBofuCompanyData({
                  files: data.data?.bofu_data?.company.files,
                  bank_accounts: data.data?.bofu_data?.company?.bank_accounts,
                  loan_application: data.data?.bofu_data?.company?.loan_application,
                }),
              );
            }

            if (data.data?.bofu_data?.guarantee) {
              api.dispatch(
                restoreBofuGuaranteeData({
                  files: data.data?.bofu_data?.guarantee?.files,
                  bank_accounts: data.data?.bofu_data?.guarantee?.bank_accounts,
                }),
              );
            }

            if (data.data?.bofu_data?.actual_owner) {
              api.dispatch(restoreBofuOwnersData(data.data?.bofu_data?.actual_owner));
            }
          }
        } else if (data.data?.loan_application_data?.type === TAX_SYSTEMS.PFAE.code) {
          api.dispatch(
            setCompanyData({
              id: data.data?.account_data?.id,
              name: data.data?.account_data?.company_name,
              rfc: data.data?.account_data?.company_rfc,
              legal_name: data.data?.account_data?.company_legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_pfae: data.data?.account_data?.is_pfae,
              pfae_name: data.data?.account_data?.pfae_name,
              pfae_last_name: data.data?.account_data?.pfae_last_name,
              pfae_last_name_2: data.data?.account_data?.pfae_last_name_2,
              is_correct_ciec: data.data?.loan_application_data.is_correct_ciec,
            }),
          );
          if (data.data.bofu_data)
            api.dispatch(
              restoreBofuPfaeData({
                files: data.data.bofu_data.company.files,
                bank_accounts: data.data.bofu_data.company.bank_accounts,
                transactional_info: data.data.bofu_data.company.loan_application.transactional_info,
                loan_application: data.data.bofu_data.company.loan_application,
              }),
            );
        }
      },
    }),
    requestPhoneCodeLogin: build.mutation<Response, any>({
      query: (data: PayloadRequestPhoneCode) => ({
        url: `telefono/enviar_codigo_login`,
        method: "POST",
        body: data,
      }),
    }),
    validatePhoneCodeLogin: build.mutation<Response, any>({
      query: (data: PayloadValidatePhoneCode) => ({
        url: `telefono/validar_codigo_login`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLazyGetSessionInfoQuery, useRequestPhoneCodeLoginMutation, useValidatePhoneCodeLoginMutation } =
  sessionApi;
