/* eslint-disable react-hooks/exhaustive-deps */
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export type buttonStatus = "validado" | "completo" | "editar" | "llenar";

export interface buttonProps {
  disabled: boolean;
  buttonLabel: string;
  backgroundColor: string;
  color?: string;
}

export const getFormStatus = (form_status: boolean | null, document_status: string | any): buttonStatus => {
  if (form_status === true && document_status === true) {
    return "validado";
  }

  if (form_status === true) {
    return "completo";
  } else if (form_status === false) {
    return "editar";
  } else {
    return "llenar";
  }
};

export interface CustomButtonExpedienteProps {
  disabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  formStatus?: boolean | null;
  documentStatus?: boolean | null;
  fakeDisabled?: boolean;
  type?: "form" | "validation";
}

const CustomButtonExpediente: React.FC<CustomButtonExpedienteProps> = ({
  formStatus,
  documentStatus,
  onClick,
  disabled,
  fakeDisabled,
  type,
}) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [btnParams, setBtnParams] = useState<buttonProps>({
    disabled: false,
    buttonLabel: "Llenar",
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.otherColors?.main,
  });

  useEffect(() => {
    if (formStatus === true && documentStatus === true) {
      setBtnParams({
        disabled: true,
        buttonLabel: "Validado",
        backgroundColor: theme?.palette?.primary?.light,
        color: theme?.palette?.primary?.main,
      });
    } else {
      if (formStatus === true) {
        setBtnParams({
          disabled: true,
          buttonLabel: "Completo",
          backgroundColor: theme?.palette?.primary?.dark,
          color: theme?.palette?.primary?.light,
        });
      } else if (formStatus === false) {
        setBtnParams({
          disabled: false,
          buttonLabel: type === "validation" ? "Volver a validar" : "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        });
      } else {
        setBtnParams({
          disabled: false,
          buttonLabel: type === "validation" ? "Validar" : "Llenar",
          backgroundColor: theme?.palette?.primary?.main,
          color: "#FFFFFF",
        });
      }
    }
  }, [formStatus, documentStatus]);

  return (
    <Button
      variant="expediente"
      size={isMobileOrTablet ? "small" : "medium"}
      onClick={onClick}
      disabled={btnParams.disabled || disabled}
      sx={{
        backgroundColor: btnParams.backgroundColor,
        color: btnParams.color,
        opacity: fakeDisabled ? 0.5 : 1,
        "&:hover": {
          backgroundColor: btnParams.backgroundColor,
        },
        "&.Mui-disabled": {
          backgroundColor: btnParams.backgroundColor,
          color: btnParams.color,
          opacity: 1,
        },
      }}
    >
      {btnParams.buttonLabel}
    </Button>
  );
};

export default CustomButtonExpediente;
