/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "@components/index";
import { useGetAccountUserByIdQuery } from "@api/accountUser";
import { signOut } from "aws-amplify/auth";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/funciones";

const Rechazo = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const personalData = useSelector((state: RootState) => state.register.personal_data);

  const icon = getDynamicUrl("img_rechazo.svg");
  const img = getDynamicUrl("right_14.svg");

  const {
    data: accountData,
    error: accountError,
    isLoading: accountIsLoading,
  } = useGetAccountUserByIdQuery(personalData.id);

  useEffect(() => {
    if (accountError) {
      console.error("Error al obtener la data:", accountError);
    }

    if (accountData) {
      const fullName = `${accountData.data.name} ${accountData.data.last_name} ${accountData.data.last_name_2}`;

      setName(fullName);
    }
  }, [accountData, accountError, accountIsLoading]);

  const goTo = async () => {
    await signOut();
    document.body.style.backgroundColor = "";
    navigate("/login");
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme?.palette?.primary?.dark || "";
  }, []);

  return (
    <>
      <Spinner open={accountIsLoading} />
      <Grid container>
        <Grid item xs={12} md={8} mt={1}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography color={theme?.palette?.primary?.light} fontWeight={"500"} align="center" fontSize={"22px"}>
                ¡Hola {name}!
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.otherColors?.main}
                align="center"
                fontWeight={300}
              >
                Lamentamos informarte que, por el momento, no pudimos autorizar tu solicitud de crédito
              </Typography>
            </Box>

            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography color={theme?.palette?.otherColors?.main} fontWeight={"500"} align="center" variant="body1">
                ¡No te desanimes! <br />
                Puedes volver a intentarlo en 3 meses
              </Typography>
            </Box>

            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={icon} alt="" />
            </Box>

            <Box sx={{ mt: 1, mb: 2 }}>
              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.otherColors?.main}
                align="center"
                fontWeight={300}
              >
                Para cualquier duda o aclaración contáctanos a: <br />
                Tel:{" "}
                <Link href="tel:+525591044964" color={theme?.palette?.otherColors?.main}>
                  55 9104 4964
                </Link>
              </Typography>

              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.otherColors?.main}
                align="center"
                fontWeight={300}
              >
                Correo:{" "}
                <Link href="mailto:contacto@lendia.mx" color={theme?.palette?.otherColors?.main}>
                  contacto@lendia.mx
                </Link>
              </Typography>
            </Box>

            <Box sx={{ mt: 1 }}>
              <Button variant="default" onClick={goTo} sx={{ width: "130px", height: "40px" }}>
                Salir
              </Button>
            </Box>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 78px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Rechazo;
