/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StyledSlider } from "./CustomSlider";
import { useEffect, useState } from "react";
import { amountToText, calculateAmountMonthly, formatCurrency } from "@helpers/funciones";
import { LoanCondiciones } from "@interfaces/condiciones";
import { TaxSystem } from "@interfaces/catalogs";

// se le suma 0.01 para que el slider no se quede en el minimo, ya que el slider no permite valores iguales
const MAGIC_QUANTITY = 0.01;

interface QuotationProps {
  condition: LoanCondiciones | null;
  taxSystems: TaxSystem | null;
  onUpdateValues: (amount: number, term: number, monthlyRate: number) => void;
}

interface SliderParams {
  value: number;
  min: number;
  max: number;
  step: number;
  marks: { value: number; label: string }[];
}

export const QuotationOferta = (props: QuotationProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { condition, taxSystems } = props;
  const [monthlyRate, setMonthlyRate] = useState<number>(0);

  const [paramsTerm, setParamsTerm] = useState<SliderParams>({
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    marks: [],
  });
  const [paramsAmount, setParamsAmount] = useState<SliderParams>({
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    marks: [],
  });

  const handleAmountChange = (_: any, newValue: number | number[]) => {
    setParamsAmount((prev) => ({ ...prev, value: newValue as number }));
  };

  const handleTermChange = (_: any, newValue: number | number[]) => {
    setParamsTerm((prev) => ({ ...prev, value: newValue as number }));
  };

  useEffect(() => {
    const calculatePayment = () => {
      if (!condition || !taxSystems) {
        return;
      }

      const monthly_payment = calculateAmountMonthly(paramsAmount.value, paramsTerm.value, condition.interest_rate);
      props.onUpdateValues(paramsAmount.value, paramsTerm.value, monthly_payment);
      setMonthlyRate(monthly_payment);
    };

    calculatePayment();
  }, [paramsTerm.value, paramsAmount.value]);

  useEffect(() => {
    const initialValues = () => {
      if (!condition || !taxSystems) {
        return;
      }

      const { min_amount, max_amount, jump_amount, min_term, max_term, jump_term } = taxSystems;
      const { approved_amount, approved_term, loan_amount, requested_term } = condition;
      const result_amount: SliderParams = {
        value: 0,
        min: 0,
        max: 0,
        step: jump_amount,
        marks: [],
      };
      const result_term: SliderParams = {
        value: 0,
        min: 0,
        max: 0,
        step: jump_term,
        marks: [],
      };

      if (approved_amount < min_amount) {
        // monto aprobado menor al minimo, se queda en 0
        result_amount.value = 0;
        result_amount.min = 0 - MAGIC_QUANTITY;
        result_amount.max = 0;
        result_amount.marks = [
          { value: 0 - MAGIC_QUANTITY, label: "0" },
          { value: 0, label: "0" },
        ];
      } else if (approved_amount == min_amount) {
        // monto aprobado igual al minimo
        result_amount.value = approved_amount;
        result_amount.min = min_amount - MAGIC_QUANTITY;
        result_amount.max = min_amount;
        result_amount.marks = [
          { value: min_amount - MAGIC_QUANTITY, label: amountToText(min_amount) },
          { value: min_amount, label: amountToText(min_amount) },
        ];
      } else {
        // monto aprobado mayor al minimo
        const max_value = approved_amount <= max_amount ? approved_amount : max_amount;
        result_amount.value = loan_amount;
        result_amount.min = min_amount;
        result_amount.max = max_value;
        result_amount.marks = [];

        for (let i = min_amount; i <= max_value; i += jump_amount) {
          result_amount.marks.push({ value: i, label: "" });
        }

        const first_item = result_amount.marks[0];
        const last_item = result_amount.marks[result_amount.marks.length - 1];

        result_amount.marks[0] = {
          value: first_item.value,
          label: amountToText(first_item.value),
        };

        result_amount.marks[result_amount.marks.length - 1] = {
          value: last_item.value,
          label: amountToText(last_item.value),
        };
      }

      if (approved_term < min_term) {
        // plazo aprobado menor al minimo, se queda en 0
        result_term.value = 0;
        result_term.min = 0 - MAGIC_QUANTITY;
        result_term.max = 0;
        result_term.marks = [
          { value: 0 - MAGIC_QUANTITY, label: "0" },
          { value: 0, label: "0" },
        ];
      } else if (approved_term == min_term) {
        // plazo aprobado igual al minimo
        result_term.value = approved_term;
        result_term.min = min_term - MAGIC_QUANTITY;
        result_term.max = min_term;
        result_term.marks = [
          { value: min_term - MAGIC_QUANTITY, label: min_term.toString() },
          { value: min_term, label: min_term.toString() },
        ];
      } else {
        // plazo aprobado mayor al minimo
        const max_value = approved_term <= max_term ? approved_term : max_term;
        result_term.value = requested_term;
        result_term.min = min_term;
        result_term.max = max_value;
        result_term.marks = [];

        for (let i = min_term; i <= max_value; i += jump_term) {
          result_term.marks.push({ value: i, label: i.toString() });
        }
      }

      setParamsTerm(result_term);
      setParamsAmount(result_amount);
    };

    initialValues();
  }, [condition, taxSystems]);

  return (
    <Box textAlign={"center"} width={"100%"}>
      <Container sx={{ mt: 2, mb: 2 }}>
        <Typography
          fontWeight={theme?.palette?.fontWeights?.medium}
          variant={isMobileOrTablet ? "body2" : "body1"}
          mt={2}
          color={theme?.palette?.primary?.dark}
        >
          Selecciona el monto
        </Typography>
        <Typography
          textAlign={"center"}
          variant={isMobileOrTablet ? "body2" : "body1"}
          fontWeight={theme?.palette?.fontWeights?.semibold}
          color={theme?.palette?.primary?.dark}
        >
          {amountToText(paramsAmount.value)} MXN
        </Typography>
        {condition && taxSystems ? (
          <>
            <StyledSlider
              aria-label="amount"
              value={paramsAmount.value}
              min={paramsAmount.min}
              max={paramsAmount.max}
              step={paramsAmount.step}
              marks={paramsAmount.marks}
              onChange={handleAmountChange}
            />
          </>
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}

        <Typography
          fontWeight={theme?.palette?.fontWeights?.medium}
          variant={isMobileOrTablet ? "body2" : "body1"}
          mt={4}
          color={theme?.palette?.primary?.dark}
        >
          Selecciona el plazo en meses
        </Typography>
        {condition && taxSystems ? (
          <StyledSlider
            aria-label="term"
            value={paramsTerm.value}
            min={paramsTerm.min}
            max={paramsTerm.max}
            step={paramsTerm.step}
            marks={paramsTerm.marks}
            onChange={handleTermChange}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" />
        )}
      </Container>

      <Typography
        textAlign={"center"}
        color={theme?.palette?.primary?.main}
        fontWeight={theme?.palette?.fontWeights?.bold}
        variant={isMobileOrTablet ? "body2" : "body1"}
      >
        Pago mensual estimado
      </Typography>
      <Typography
        mb={3}
        textAlign={"center"}
        variant="h4"
        fontWeight={theme?.palette?.fontWeights?.semibold}
        color={theme?.palette?.primary?.dark}
      >
        {isNaN(monthlyRate) || !isFinite(monthlyRate) ? "0" : formatCurrency(monthlyRate)}{" "}
        <Typography
          variant="h5"
          component={"span"}
          fontWeight={theme?.palette?.fontWeights?.semibold}
          color={theme?.palette?.primary?.dark}
        >
          MXN
        </Typography>
      </Typography>

      <Typography
        textAlign={"center"}
        color={theme?.palette?.primary?.dark}
        variant={isMobileOrTablet ? "body3" : "body2"}
      >
        *Mensualidad estimada
      </Typography>
    </Box>
  );
};
