/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RootState, useAppDispatch } from "@store/store";
import { LoanConditions } from "@components/LoanConditions";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/funciones";
import { STATUS_CATALOG } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const ExpedienteDigitalCompleto = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const img = getDynamicUrl("img_tofu/img_tofu_12.svg");
  const icon = getDynamicUrl("speed_icon.svg");

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const handleOnClickNext = async () => {
    try {
      const { code: next_status, url: next_url } = STATUS_CATALOG.agenda_visita;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: next_status,
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();

      navigate(next_url);
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box mx={4}>
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            textAlign={"center"}
            color={theme?.palette?.primary?.dark}
          >
            ¡Felicidades {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}
            !
          </Typography>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
            textAlign="center"
          >
            Tu expediente digital ha sido autorizado
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <img src={icon} />
          </Box>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.dark}
          >
            Cada vez estas más cerca de obtener tu crédito
          </Typography>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
          >
            Revisa las condiciones del crédito que estas solicitando
          </Typography>

          <LoanConditions />

          <Box display={"flex"} justifyContent={"center"} mt={4}>
            <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
              Modifica tu cotización
            </Button>
          </Box>

          <Grid container mt={4} columnSpacing={2} px={2} justifyContent={"center"}>
            <Grid item xs={"auto"}>
              <Button variant="default" onClick={handleOnClickNext}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 78px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ExpedienteDigitalCompleto;
