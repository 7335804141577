/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLazyGetContractualDocumentLoanApplicationQuery } from "@api/documents";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import ModalFirma from "@components/ModalFirmaMifiel";
import { DocumentContract } from "@interfaces/response";
import MifielScript from "@components/MifielScript";
import { getDynamicUrl } from "@helpers/funciones";
import { STATUS_CATALOG } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const FirmaDocumentos: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const img = getDynamicUrl("img_tofu/img_tofu_10.svg");

  const sxButton: SxProps = {
    width: isMobileOrTablet ? "90px" : "300px",
    height: isMobileOrTablet ? "23px" : "35px",
    fontSize: isMobileOrTablet ? 13 : 16,
  };

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getDocumentByApplicationClient] = useLazyGetContractualDocumentLoanApplicationQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [isAllSigned, setIsAllSigned] = useState(false);
  const [documents, setDocuments] = useState<DocumentContract[]>([]);
  const [documentToSign, setDocumentToSign] = useState<DocumentContract | null>(null);

  const navigate = useNavigate();

  const handleContinue = async () => {
    try {
      if (!isAllSigned) {
        return;
      }

      const { code: next_status, url: next_url } = STATUS_CATALOG.bofu_completado;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: next_status,
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();

      navigate(next_url);
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!application.id || !clientDetails.id) {
          return;
        }

        const documents: DocumentContract[] = await getDocumentByApplicationClient({
          loanApplicationId: application.id,
          clientId: clientDetails.id,
        }).unwrap();

        const isAllSigned = documents.every((doc) => doc.sign_validated);

        setIsAllSigned(isAllSigned);
        setDocuments(documents);
      } catch (error) {
        console.error("Ha ocurrido un error al obtener los documentos para firmar");
      }
    })();
  }, [application.id, clientDetails.id]);

  return (
    <Grid container sx={{ height: "calc(100vh - 80px)" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <MifielScript />

        <Box mx={4}>
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Firma de documentos
          </Typography>

          <NavBarOcularVisit currentStep={4} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            En caso de que tengas alguna duda no dudes en contactar a tu asesor
          </Typography>

          <Box mt={4}>
            {documents.map((doc, index) => (
              <Box
                key={index}
                alignItems="center"
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Box width={isMobileOrTablet ? "100%" : "300px"}>
                  <Typography
                    variant={isMobileOrTablet ? "body1" : "h5"}
                    fontWeight="bold"
                    color={theme?.palette?.primary?.dark}
                  >
                    {doc.file_category.name}
                  </Typography>

                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
                    {doc.file_name}
                  </Typography>
                </Box>

                <Box>
                  {doc.sign_client ? (
                    <Button variant="blue_demon" disabled sx={sxButton}>
                      Firmado
                    </Button>
                  ) : (
                    <Button
                      variant="default"
                      disabled={!doc.sign_service_name || !doc.sign_document_reference_id || !doc.signers_reference}
                      sx={sxButton}
                      onClick={() => {
                        setDocumentToSign(doc);
                      }}
                    >
                      Firmar
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="default"
              type="submit"
              disabled={!isAllSigned || documents.length === 0}
              sx={{ mt: 2, padding: "12px", width: isMobileOrTablet ? "100%" : "200px", textTransform: "none" }}
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </Box>

          <Grid container columnSpacing={1} my={4} justifyContent="center">
            <Grid item xs={6} sm={4} md={3}></Grid>
          </Grid>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}

      {documentToSign && <ModalFirma document_contract={documentToSign} handleClose={() => setDocumentToSign(null)} />}
    </Grid>
  );
};

export default FirmaDocumentos;
