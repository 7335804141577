/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { usePatchReferencesMutation, usePostReferencesMutation } from "@api/referenciasApi";
import { useGetReferenceTypesQuery } from "@api/catalogs";
import { useGetClientsSuppliersByRfcQuery } from "@api/proveedorClienteApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { setPafeSupplierReference, setReferencePafeComplete } from "@store/slices/bofuSlicePfae";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { getDynamicUrl, getOrdinalText } from "@helpers/funciones";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const validationSchema = yup.object().shape({
  referencia: yup.array().of(
    yup.object().shape({
      country_code: yup.string().required("Campo requerido"),
      phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
      proveedor: yup.string().required("Campo requerido"),
      email: yup
        .string()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
    }),
  ),
});

const ReferenciasProveedoresPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const img = getDynamicUrl("img_tofu/img_tofu_7.svg");

  const applicationData = useSelector((state: RootState) => state.register.personal_data);
  const pfaeBofu = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const applicationSol = useSelector((state: RootState) => state.register.application);
  const company = useSelector((state: RootState) => state.register.company);

  const [postReferencias] = usePostReferencesMutation();
  const [triggerPatchReferences] = usePatchReferencesMutation();
  const proveedorReferences = useGetClientsSuppliersByRfcQuery(String(company?.rfc));
  const proveedorReferenceType = useGetReferenceTypesQuery();
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const initialValues = {
    referencia: [
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
    ],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);

          const has_references = pfaeBofu.references.suppliers.length > 0;
          const reference_type = proveedorReferenceType.data?.find((item) => item.code === "CRTPR");

          for (let index = 0; index < values.referencia.length; index++) {
            const reference = values.referencia[index];
            const supplierItem = proveedorReferences.data?.data?.suppliers.find(
              (item) => item.rfc === reference.proveedor,
            );
            let response = undefined;

            const referenceData = {
              client_details_id: applicationData.id,
              email: reference.email,
              id_ext: "3333",
              phone_number: reference.phone,
              reference_type_id: reference_type?.id,
              code_phone_number: reference.country_code,
              supplier_name: supplierItem?.name,
              supplier_rfc: supplierItem?.rfc,
            };

            if (has_references) {
              response = await triggerPatchReferences({
                referenceId: String(pfaeBofu.references.suppliers.at(index)?.id) as UUID,
                body: referenceData,
              }).unwrap();
            } else {
              response = await postReferencias(referenceData).unwrap();
              dispatch(setReferencePafeComplete({ complete: true }));
            }

            dispatch(
              setPafeSupplierReference({
                clientNumber: index,
                reference: {
                  id: response.data.id,
                  client_id: reference.proveedor,
                  email: reference.email,
                  code_phone: reference.country_code,
                  phone_number: reference.phone,
                },
              }),
            );
          }

          const data = {
            references_completed: true,
          };

          await putAapplicationCompleted({ id_sol: applicationSol.id, body: data }).unwrap();

          navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
        } catch (error) {
          dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  const onChangeEmail = (index: number, value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue(`referencia[${index}].email`, low);
  };

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.referencia?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  useEffect(() => {
    if (proveedorReferenceType.isSuccess && pfaeBofu.references.suppliers.length !== 0) {
      formik.setFieldValue(
        "referencia",
        pfaeBofu.references.suppliers.map((item) => {
          return {
            country_code: item.code_phone,
            phone: item.phone_number,
            email: item.email,
            proveedor: item.client_id,
          };
        }),
      );
    }
  }, [proveedorReferences, pfaeBofu]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={800} color={theme?.palette?.primary?.dark}>
                Referencias
              </Typography>
            </Box>

            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={400} color={theme?.palette?.primary?.dark}>
                <span style={{ fontWeight: 800 }}>2</span> de 2
              </Typography>
            </Box>

            <Box my={1}>
              <Typography textAlign={"center"} fontSize={"14px"} fontWeight={600} color={theme?.palette?.primary?.main}>
                Ahora, escribe 3 referencias que sean tus proveedores, considera que podríamos contactarlos.
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                {formik.values.referencia.map((_, index) => (
                  <div key={index}>
                    <Box my={2} mb={2}>
                      <Typography
                        textAlign={"left"}
                        fontSize={"14px"}
                        fontWeight={400}
                        color={theme?.palette?.primary?.dark}
                      >
                        {getOrdinalText(index)} referencia
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        mt: 1,
                      }}
                      error={Boolean(
                        formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                      )}
                    >
                      <InputLabel id={`${index}proveedor`}>Proveedores</InputLabel>
                      <Select
                        labelId="proveedor"
                        name={`referencia[${index}].proveedor`}
                        id={`proveedor${index}`}
                        fullWidth
                        required
                        label="Proveedores"
                        value={formik.values.referencia?.[index]?.proveedor}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                        )}
                        disabled={formik.isSubmitting}
                      >
                        <MenuItem value="">Selecciona</MenuItem>
                        {proveedorReferences.isSuccess &&
                          proveedorReferences?.data?.data?.suppliers
                            ?.filter(
                              (supplier) =>
                                !formik.values.referencia.some(
                                  (ref, refIndex) => refIndex !== index && ref.proveedor === supplier.rfc,
                                ),
                            )
                            .map((supplier) => (
                              <MenuItem key={supplier.rfc} value={supplier.rfc}>
                                {supplier.name.toUpperCase()}
                              </MenuItem>
                            ))}
                      </Select>

                      {formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index) && (
                        <FormHelperText>{getFieldError("proveedor", index)}</FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      name={`referencia[${index}].email`}
                      id={`email${index}`}
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => onChangeEmail(index, e.target.value)}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.touched.referencia?.[index]?.email && getFieldError("email", index))}
                      helperText={formik.touched.referencia?.[index]?.email && getFieldError("email", index)}
                      value={formik.values.referencia?.[index]?.email}
                      disabled={formik.isSubmitting}
                    />

                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomContryCode
                          label="Código"
                          name={`referencia[${index}].country_code`}
                          value={formik.values.referencia?.[index]?.country_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.referencia?.[index]?.country_code && getFieldError("country_code", index),
                          )}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          name={`referencia[${index}].phone`}
                          id={`phone${index}`}
                          type="text"
                          variant="outlined"
                          label="Teléfono"
                          fullWidth
                          required
                          value={formik.values.referencia?.[index]?.phone}
                          disabled={formik.isSubmitting}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `referencia[${index}].phone`,
                              event.target.value.replace(/[^0-9]/g, ""),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.touched.referencia?.[index]?.phone && getFieldError("phone", index))}
                          helperText={formik.touched.referencia?.[index]?.phone && getFieldError("phone", index)}
                          inputProps={{
                            maxLength: 10,
                            inputMode: "numeric",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Button variant="default" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReferenciasProveedoresPfae;
