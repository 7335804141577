/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Link,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { RootState, useAppDispatch } from "@store/store";
import { setLoanStatus, setShareHolderData } from "@store/slices/registerSlice";
import { useGetPersonTypeQuery, useLazyGetStatesQuery } from "@api/catalogs";
import { useSaveShareholderDataMutation } from "@api/onboarding";
import { EMAIL } from "@utils/expreciones";
import { ProgressBar } from "@components/index";
import { getDynamicUrl, transformTitleCase } from "@helpers/index";
import {
  CatalogStates,
  PayloadPersonPatch,
  PayloadSaveShareholderData,
  ResponseSaveShareholderData,
} from "@interfaces/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePatchPersonMutation } from "@api/personasApi";
import { STATUS_CATALOG } from "@helpers/constantes";
import { ApiError } from "@interfaces/response";

const maxDate = dayjs().subtract(18, "years");

const DatosAccionista = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const img = getDynamicUrl("right_9.svg");

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const shareholderData = useSelector((state: RootState) => state.register.shareholder);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const dispatch = useAppDispatch();
  const [getStatesTrigger] = useLazyGetStatesQuery();
  const [saveShareholderData] = useSaveShareholderDataMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [patchPerson] = usePatchPersonMutation();
  const { data: personTypeData } = useGetPersonTypeQuery();

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [personTypeId, setPersonTypeId] = useState("");
  const [shareholderId, setShareholderId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [placesCatalog, setPlacesCatalog] = useState<CatalogStates[]>([]);

  useEffect(() => {
    if (personTypeData) {
      const filteredItem = personTypeData.find((item) => item.code === "PTAM");
      setPersonTypeId(String(filteredItem?.id));
    }
  }, [personTypeData]);

  const formik = useFormik({
    initialValues: {
      majority_shareholder: false,
      name: shareholderData.name || "",
      first_lastname: shareholderData.first_lastname || "",
      second_lastname: shareholderData.second_lastname || "",
      date_birth: "",
      birth_state: shareholderData.birth_state || "",
      email: shareholderData.email || "",
      sex: shareholderData.sex || "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Campo requerido"),
      first_lastname: yup.string().required("Campo requerido"),
      second_lastname: yup.string(),
      date_birth: yup
        .string()
        .required("Campo requerido")
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .test("is-adult", "Debes ser mayor de 18 años", (value) => {
          if (!value) return false;

          const birthDate = dayjs(value);
          const age = dayjs().diff(birthDate, "years");

          return age >= 18;
        }),
      birth_state: yup.string().required("Campo requerido"),
      email: yup
        .string()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      sex: yup.string().oneOf(["MASCULINO", "FEMENINO"], "Debe seleccionar un género").required("Campo requerido"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const next_status = STATUS_CATALOG.rfc_curp.code;
        const next_url = STATUS_CATALOG.rfc_curp.url;

        if (shareholderId) {
          const payloadShareholderData: PayloadPersonPatch = {
            person_type_id: personTypeId,
            name: values.name,
            last_name: values.first_lastname,
            last_name_2: values.second_lastname,
            birth_date: dayjs(values.date_birth).format("YYYY-MM-DD"),
            birth_state: Number(values.birth_state),
            email: values.email,
            sex: values.sex,
            rfc: "",
            curp: "",
            loan_application_id: application.id,
          };

          await patchPerson({
            personId: shareholderId,
            body: payloadShareholderData,
          }).unwrap();

          dispatch(
            setShareHolderData({
              id: shareholderId,
              name: values.name,
              first_lastname: values.first_lastname,
              second_lastname: values.second_lastname,
              date_birth: values.date_birth,
              birth_state: values.birth_state,
              email: values.email,
              sex: values.sex,
              rfc: "",
              rfc_homoclave: "",
              curp: "",
              bureau_inquiry: {
                prestamo_hipotecario: false,
                credito_auto: false,
                tarjeta_credito: false,
                tarjeta: "",
                autorizacion_buro: false,
              },
            }),
          );
        } else {
          const payloadShareholderData: PayloadSaveShareholderData = {
            person_type_id: personTypeId,
            name: values.name,
            last_name: values.first_lastname,
            last_name_2: values.second_lastname,
            birth_date: dayjs(values.date_birth).format("YYYY-MM-DD"),
            birth_state: Number(values.birth_state),
            email: values.email,
            sex: values.sex,
            rfc: "",
            curp: "",
            loan_application_id: application.id,
          };
          const responseShareholderData: ResponseSaveShareholderData = await saveShareholderData(
            payloadShareholderData,
          ).unwrap();

          dispatch(
            setShareHolderData({
              id: responseShareholderData.data.id,
              name: values.name,
              first_lastname: values.first_lastname,
              second_lastname: values.second_lastname,
              date_birth: values.date_birth,
              birth_state: values.birth_state,
              email: values.email,
              sex: values.sex,
              rfc: "",
              rfc_homoclave: "",
              curp: "",
              bureau_inquiry: {
                prestamo_hipotecario: false,
                credito_auto: false,
                tarjeta_credito: false,
                tarjeta: "",
                autorizacion_buro: false,
              },
            }),
          );

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: next_status,
            username: `TOFU - ${accountUser.email}`,
          }).unwrap();
        }

        dispatch(setLoanStatus(next_status));

        navigate(next_url);
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleMajorityShareholderChange = (checked: boolean) => {
    if (checked) {
      formik.setFieldValue("name", clientDetails.name);
      formik.setFieldValue("first_lastname", clientDetails.first_lastname);
      formik.setFieldValue("second_lastname", clientDetails.second_lastname);
      formik.setFieldValue("email", accountUser.email);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("first_lastname", "");
      formik.setFieldValue("second_lastname", "");
      formik.setFieldValue("email", "");
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const responseStates = await getStatesTrigger().unwrap();
        setPlacesCatalog(responseStates);
      } catch (error) {
        console.log("Error al obtener los datos iniciales");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (shareholderData?.id !== "" && shareholderData?.id !== undefined) {
      setShareholderId(String(shareholderData?.id));
      setSelectedDate(dayjs(shareholderData?.date_birth));
      formik.setFieldValue("date_birth", shareholderData?.date_birth);
    }
  }, [shareholderData]);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={500}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Registro del accionista mayoritario
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={7} />
          </Container>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <FormControl fullWidth>
                <FormControlLabel
                  sx={{ color: theme?.palette?.primary?.dark }}
                  control={<Checkbox />}
                  label="Soy el accionista mayoritario"
                  name="majority_shareholder"
                  checked={formik.values.majority_shareholder}
                  onChange={(_, checked: boolean) => {
                    formik.setFieldValue("majority_shareholder", checked);
                    handleMajorityShareholderChange(checked);
                  }}
                  disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label="Nombre(s)"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue("name", transformTitleCase(e.target.value))}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                  inputProps={{ maxLength: 20 }}
                  disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="first_lastname"
                  name="first_lastname"
                  label="Apellido paterno"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue("first_lastname", transformTitleCase(e.target.value))}
                  value={formik.values.first_lastname}
                  error={formik.touched.first_lastname && Boolean(formik.errors.first_lastname)}
                  helperText={formik.touched.first_lastname ? formik.errors.first_lastname : ""}
                  inputProps={{ maxLength: 20 }}
                  disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="second_lastname"
                  name="second_lastname"
                  label="Apellido materno"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue("second_lastname", transformTitleCase(e.target.value))}
                  value={formik.values.second_lastname}
                  error={formik.touched.second_lastname && Boolean(formik.errors.second_lastname)}
                  helperText={formik.touched.second_lastname ? formik.errors.second_lastname : ""}
                  inputProps={{ maxLength: 20 }}
                  disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <DatePicker
                    label="Fecha de nacimiento"
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");

                      formik.setFieldValue("date_birth", formattedDate);

                      formik.validateField("date_birth");
                    }}
                    maxDate={maxDate}
                    slotProps={{
                      textField: {
                        name: "date_birth",
                        onBlur: formik.handleBlur,
                        error: formik.touched.date_birth && Boolean(formik.errors.date_birth),
                        helperText: formik.touched.date_birth ? formik.errors.date_birth : "",
                        required: true,
                      },
                    }}
                    disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                fullWidth
                required
                sx={{ mt: 2 }}
                error={formik.touched.birth_state && Boolean(formik.errors.birth_state)}
              >
                <InputLabel id="birth_state-label">Lugar de nacimento</InputLabel>
                <Select
                  labelId="birth_state-label"
                  id="birth_state"
                  name="birth_state"
                  fullWidth
                  required
                  label="Lugar de nacimento"
                  value={formik.values.birth_state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.birth_state && Boolean(formik.errors.birth_state)}
                  disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {placesCatalog.map((item: CatalogStates) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.touched.birth_state) && Boolean(formik.errors.birth_state) && (
                  <FormHelperText>{formik.errors.birth_state}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  inputProps={{ maxLength: 30 }}
                  disabled={formik.isSubmitting}
                />
              </FormControl>

              <FormControl
                component="fieldset"
                sx={{ mt: 1 }}
                error={formik.touched.sex && formik.errors.sex ? true : false}
              >
                <FormLabel component="legend">Sexo como aparece en tu INE</FormLabel>
                <RadioGroup aria-label="sex" name="sex" value={formik.values.sex} onChange={formik.handleChange} row>
                  <FormControlLabel
                    sx={{ color: theme?.palette?.primary?.dark }}
                    value="MASCULINO"
                    control={<Radio />}
                    label="Masculino"
                    disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                  />
                  <FormControlLabel
                    sx={{ color: theme?.palette?.primary?.dark }}
                    value="FEMENINO"
                    control={<Radio />}
                    label="Femenino"
                    disabled={formik.isSubmitting || shareholderData.check_sat_bureau}
                  />
                </RadioGroup>
                {formik.touched.sex && formik.errors.sex ? <FormHelperText>Selecciona tu sexo</FormHelperText> : null}
              </FormControl>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || isLoading || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>

            <Box sx={{ mt: isMobileOrTablet ? 2 : 3 }} mb={2}>
              <Typography textAlign={"center"}>
                Al continuar estas autorizando a Lendit Group a consultar tu información ante el SAT. Consulta{" "}
                <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                  <Typography component={"span"}>términos y condiciones</Typography>
                </Link>{" "}
                y{" "}
                <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                  <Typography component={"span"}>aviso de privacidad</Typography>
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#A3D4E8"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DatosAccionista;
