import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAccountUserByIdQuery } from "@api/accountUser";
import { signOut } from "aws-amplify/auth";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/index";

const RechazoManual = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const img = getDynamicUrl("img_tofu/img_tofu_11.svg");
  const icon = getDynamicUrl("img_tofu/icon_rechazo.svg");

  const { data: accountData } = useGetAccountUserByIdQuery(personalData.id);

  useEffect(() => {
    if (accountData) {
      const fullName = `${accountData.data.name} ${accountData.data.last_name} ${accountData.data.last_name_2}`;

      setName(fullName);
    }
  }, [accountData]);

  const handleClick = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={5}>
        <InactivityHandler />
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            color={theme?.palette?.primary?.main}
            fontWeight={500}
            align="center"
          >
            ¡Hola!
          </Typography>

          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            color={theme?.palette?.primary?.main}
            fontWeight={500}
            align="center"
          >
            {name}
          </Typography>

          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            align="center"
            mt={3}
          >
            Lo sentimos mucho, lamentamos informarte que tu solicitud de crédito ha sido rechazada
          </Typography>

          <Box sx={{ marginTop: "3em", marginBottom: "3em" }}>
            <img src={icon} alt="" />
          </Box>

          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            align="center"
          >
            Para mayor información, contacta a tu asesor
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Button variant="default" onClick={handleClick} sx={{ width: "130px", height: "40px" }}>
              Salir
            </Button>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 78px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RechazoManual;
