/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomDialog, InactivityHandler } from "@components/index";
import { getDynamicUrl } from "@helpers/funciones";
import { UUID } from "crypto";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLazyGetBanksByCodeQuery } from "@api/catalogs";
import {
  useDeleteBankAccountMutation,
  useLazyGetBankAccountsQuery,
  usePostBankAccountsMutation,
  usePostValidateNextStageMutation,
  usePutBankAccountMutation,
} from "@api/applications";
import { BankAccount, DocumentResponse } from "@interfaces/response";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { DOCUMENTS_CATALOG, STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import { PayloadBankAccount } from "@interfaces/request";
import { useLazyGetDocumentAppIdQuery, usePatchDocumentMutation } from "@api/documents";

interface BankAccountProps {
  id: string | undefined;
  clabe: string;
  bank_id: UUID | string;
  bank_name: string;
  bank_statment_first_documents_id: string;
  bank_statment_second_documents_id: string;
  bank_statment_third_documents_id: string;
}

interface AnotherDocumentProps {
  id: string | undefined;
  financial_statements_id: string;
}

const ZonaGris = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const img = getDynamicUrl("right_8.svg");

  const emptyBankAccount: BankAccountProps = {
    id: "",
    clabe: "",
    bank_id: "",
    bank_name: "",
    bank_statment_first_documents_id: "",
    bank_statment_second_documents_id: "",
    bank_statment_third_documents_id: "",
  };

  const emptyAnotherDocument: AnotherDocumentProps = {
    id: "",
    financial_statements_id: "",
  };

  const applicationData = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const companySection = useSelector((state: RootState) => state.register.company);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerGetBanks] = useLazyGetBanksByCodeQuery();
  const [getBankAccounts] = useLazyGetBankAccountsQuery();
  const [triggerPutBankAccount] = usePutBankAccountMutation();
  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [triggerPostBankAccount] = usePostBankAccountsMutation();
  const [listDocuments, setListDocuments] = useState<DocumentResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [acountToDelete, setAcountToDelete] = useState<number | undefined>(undefined);
  const [deleteBankAccount] = useDeleteBankAccountMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      accounts: [{ ...emptyBankAccount }],
      annual_closing_id: "",
      last_partial_closing_id: "",
      another_document: [],
    },
    validationSchema: yup.object().shape({
      accounts: yup.array().of(
        yup.object().shape({
          clabe: yup
            .string()
            .min(18, "Formato incorrecto, debe tener 18 dígitos")
            .required("Campo requerido.")
            .test("unique", "La cuenta bancaria ya se encuentra registrada.", function (value) {
              const num_results: number = formik.values.accounts.filter((item: any) => item.clabe === value).length;
              return num_results > 1 ? false : true;
            }),
          bank_id: yup.string().required("Clabe bancaria incorrecta, validar nuevamente."),
          bank_statment_first_documents_id: yup.string().required("Campo requerido."),
          bank_statment_second_documents_id: yup.string().required("Campo requerido."),
          bank_statment_third_documents_id: yup.string().required("Campo requerido."),
        }),
      ),
      annual_closing_id: yup.string().required("Campo requerido."),
      last_partial_closing_id: yup.string().required("Campo requerido."),

      another_document: yup.array().of(
        yup.object().shape({
          financial_statements_id: yup.string(),
        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        for (let index = 0; index < values.accounts.length; index++) {
          const account = values.accounts[index];
          const payload: PayloadBankAccount = {
            loan_application_id: applicationData.id,
            client_details_id: personalData.id,
            person_id: null,
            account: 0,
            account_holder: "",
            clabe: account.clabe,
            bank_id: account.bank_id as UUID,
            bank_statment_first_documents_id: account.bank_statment_first_documents_id,
            bank_statment_second_documents_id: account.bank_statment_second_documents_id,
            bank_statment_third_documents_id: account.bank_statment_third_documents_id,
          };

          if (account.id) {
            await triggerPutBankAccount({
              solicitudId: applicationData.id,
              bankAccountId: account.id,
              body: payload,
            });
          } else {
            await triggerPostBankAccount({
              solicitudId: applicationData.id,
              body: payload,
            });
          }
        }

        const next_status = STATUS_CATALOG.waiting_room_revision.code;
        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `TOFU - ${accountUser.email}`,
        }).unwrap();

        handleNextPage();
      } catch (error) {
        console.log("Error al guardar la información bancaria:", error);
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const getBankNameByCode = useCallback(
    async (bank_code: string) => {
      try {
        const bank_info = await triggerGetBanks(bank_code).unwrap();
        if (bank_info) {
          return {
            id: bank_info.id,
            name: bank_info.name,
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error al obtener el banco:", error);
        return null;
      }
    },
    [listDocuments],
  );

  const handleChangeCLABE = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const only_numbers = value.replace(/[^0-9]/g, "");
    formik.setFieldValue(name, only_numbers);

    const bank_code = only_numbers.substring(0, 3);
    const bank_info = bank_code.length === 3 ? await getBankNameByCode(bank_code) : null;
    if (bank_info) {
      formik.setFieldValue(`accounts[${index}].bank_id`, bank_info?.id || "");
      formik.setFieldValue(`accounts[${index}].bank_name`, bank_info?.name || "");
    } else {
      formik.setFieldValue(`accounts[${index}].bank_id`, "");
      formik.setFieldValue(`accounts[${index}].bank_name`, "");
    }
  };

  const handleOnAddAccountBank = () => {
    formik.setFieldValue("accounts", [...formik.values.accounts, { ...emptyBankAccount }]);
  };

  const handleOnAddAnotherDocument = () => {
    if (formik.values.another_document.length >= 3) {
      setIsDisabled(true);
      return;
    }
    formik.setFieldValue("another_document", [...formik.values.another_document, { ...emptyAnotherDocument }]);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const list_bank_accounts: BankAccount[] = await getBankAccounts(applicationData.id).unwrap();
        let bank_accounts: BankAccount[] = [];
        let documents: DocumentResponse[] = [];

        bank_accounts = list_bank_accounts.filter((item) => item.client_details_id === personalData.id);
        documents = await getDocumentsByAppId({
          loanApplicationId: applicationData.id,
          clientDetailsId: personalData.id,
          status: true,
        }).unwrap();

        const accounts: BankAccountProps[] = [];

        for (let index = 0; index < bank_accounts.length; index++) {
          const cuenta_clabe = (bank_accounts[index].clabe || "").toString().padStart(18, "0");
          const bank_code = cuenta_clabe.toString().substring(0, 3);
          const bank_info = await getBankNameByCode(bank_code);

          accounts.push({
            id: bank_accounts[index].id,
            clabe: cuenta_clabe,
            bank_id: bank_info?.id || "",
            bank_name: bank_info?.name || "",
            bank_statment_first_documents_id: bank_accounts[index].bank_statment_first_documents_id,
            bank_statment_second_documents_id: bank_accounts[index].bank_statment_second_documents_id,
            bank_statment_third_documents_id: bank_accounts[index].bank_statment_third_documents_id,
          });
        }

        const cierre_anual =
          documents.find((file) => file.file_code === DOCUMENTS_CATALOG.cierre_anual.code)?.id || null;
        const ultimo_cierre_parcial =
          documents.find((file) => file.file_code === DOCUMENTS_CATALOG.ultimo_cierre_parcial.code)?.id || null;

        const estados_finacieros = documents
          .filter((file) => file.file_code === DOCUMENTS_CATALOG.estados_finacieros.code)
          .map((file) => file.id);

        const anotherDocument: AnotherDocumentProps[] = [];

        for (let index = 0; index < estados_finacieros.length; index++) {
          anotherDocument.push({
            id: `${index}`,
            financial_statements_id: estados_finacieros[index],
          });
        }

        setListDocuments(documents);

        formik.setFieldValue("annual_closing_id", cierre_anual);
        formik.setFieldValue("last_partial_closing_id", ultimo_cierre_parcial);
        if (accounts.length) {
          formik.setFieldValue("accounts", accounts);
        }
        if (anotherDocument.length) {
          formik.setFieldValue("another_document", anotherDocument);
        }
      } catch (error) {
        console.log("Error al obtener la información bancaria:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleOnOpenModal = (index: number) => {
    setAcountToDelete(index);
  };

  const handleOnCloseModal = () => {
    setAcountToDelete(undefined);
  };

  const handleNextPage = () => {
    navigate("/producto/creditosimple/waiting_room_revision");
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      if (acountToDelete === undefined) return;

      const account_to_delete = formik.values.accounts[acountToDelete];

      if (!account_to_delete) {
        setAcountToDelete(undefined);
        return;
      }

      const delete_promises: Promise<any>[] = [];

      if (account_to_delete.bank_statment_first_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_first_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.bank_statment_second_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_second_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.bank_statment_third_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_third_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.id) {
        delete_promises.push(
          deleteBankAccount({
            solicitudId: applicationData.id,
            accountId: account_to_delete.id,
          }),
        );
      }

      await Promise.all(delete_promises);

      const new_accounts = formik.values.accounts.filter((_, index) => index !== acountToDelete);
      formik.setFieldValue("accounts", new_accounts);
      setAcountToDelete(undefined);
    } catch (error) {
      console.log("Error al eliminar la cuenta bancaria:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <Box mt={4} mb={2}>
            <Typography textAlign={"center"} color={theme?.palette?.primary?.dark} fontSize={22} fontWeight={500}>
              ¡Hola!{" "}
              <span>
                {" "}
                <br />
                {companySection.legal_name ?? "Nombre del Cliente"}
              </span>
            </Typography>
          </Box>
          <InactivityHandler />
          <Box mt={3} mb={2}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.main}
              variant={!isMobileOrTablet ? "body1" : "body2"}
              fontWeight={600}
            >
              <span>
                Te informamos que no pudimos autorizar tu <br /> solicitud con nuestro proceso automatizado, <br />
                por lo que será necesaria una revisión adicional.
              </span>
            </Typography>
          </Box>

          <Box mt={3} mb={2}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
              variant={!isMobileOrTablet ? "body1" : "body2"}
              fontWeight={300}
            >
              <span>
                No te preocupes, solo necesitamos algunos documentos para continuar con el proceso de evaluación. <br />
                Estos serán revisados en un plazo máximo de 24 horas hábiles.
              </span>
            </Typography>
          </Box>

          <Box mt={3} mb={2}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
              variant={!isMobileOrTablet ? "body1" : "body2"}
              fontWeight={600}
            >
              Últimos 3 estados de cuenta
            </Typography>
          </Box>

          <Box mr={3} ml={3} mt={3} mb={2}>
            <form onSubmit={formik.handleSubmit}>
              {formik.values.accounts.map((account: any, index: number) => (
                <Box key={index} mt={index > 0 ? 4 : 0}>
                  <TextField
                    name={`accounts[${index}].clabe`}
                    label="Cuenta CLABE"
                    margin="normal"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCLABE(event, index)}
                    value={formik.values.accounts[index].clabe}
                    error={
                      formik.touched.accounts?.[index]?.clabe &&
                      Boolean((formik.errors.accounts as any)?.[index]?.clabe)
                    }
                    helperText={
                      formik.touched.accounts?.[index]?.clabe
                        ? (formik.errors.accounts as any)?.[index]?.clabe ||
                          (formik.errors.accounts as any)?.[index]?.bank_id
                        : ""
                    }
                    inputProps={{ maxLength: 18 }}
                    disabled={formik.isSubmitting}
                  />

                  <TextField
                    name={`accounts[${index}].bank_name`}
                    label="Banco"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.accounts[index].bank_name}
                    error={
                      formik.touched.accounts?.[index]?.bank_name &&
                      Boolean((formik.errors.accounts as any)?.[index]?.bank_name)
                    }
                    helperText={
                      formik.touched.accounts?.[index]?.bank_name
                        ? (formik.errors.accounts as any)?.[index]?.bank_name
                        : ""
                    }
                    disabled={true}
                  />

                  <CustomUploadFileV2
                    mt={2}
                    inputName={`accounts[${index}].bank_statment_first_documents_id`}
                    uploadedFile={
                      listDocuments.find((item) => item.id === account.bank_statment_first_documents_id) || undefined
                    }
                    setUploadedFile={(newUpload) => {
                      formik.setFieldValue(`accounts[${index}].bank_statment_first_documents_id`, newUpload?.id || "");

                      if (newUpload) {
                        setListDocuments((prev) => [...prev, newUpload]);
                      }
                    }}
                    optionalName="1er estado de cuenta bancario"
                    optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                    registerFormik={() => {}}
                    metadata={{
                      taxCode: TAX_SYSTEMS.PM.code,
                      fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario_zona_gris.code,
                      uploadType: "client",
                      loanApplicationId: applicationData.id,
                      regimen: applicationData.type,
                      personId: undefined,
                      clientDetailsId: personalData.id,
                      personType: undefined,
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />

                  <CustomUploadFileV2
                    mt={2}
                    inputName={`accounts[${index}].bank_statment_second_documents_id`}
                    uploadedFile={
                      listDocuments.find((item) => item.id === account.bank_statment_second_documents_id) || undefined
                    }
                    setUploadedFile={(newUpload) => {
                      if (newUpload) {
                        setListDocuments((prev) => [...prev, newUpload]);
                      } else {
                        setListDocuments((prev) =>
                          prev.filter((item) => item.id !== account.bank_statment_second_documents_id),
                        );
                      }
                      formik.setFieldValue(`accounts[${index}].bank_statment_second_documents_id`, newUpload?.id);
                    }}
                    optionalName="2do estado de cuenta bancario"
                    optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                    metadata={{
                      taxCode: TAX_SYSTEMS.PM.code,
                      fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario_zona_gris.code,
                      uploadType: "client",
                      loanApplicationId: applicationData.id,
                      regimen: applicationData.type,
                      personId: undefined,
                      clientDetailsId: personalData.id,
                      personType: undefined,
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />

                  <CustomUploadFileV2
                    mt={2}
                    inputName={`accounts[${index}].bank_statment_third_documents_id`}
                    uploadedFile={
                      listDocuments.find((item) => item.id === account.bank_statment_third_documents_id) || undefined
                    }
                    setUploadedFile={(newUpload) => {
                      if (newUpload) {
                        setListDocuments((prev) => [...prev, newUpload]);
                      } else {
                        setListDocuments((prev) =>
                          prev.filter((item) => item.id !== account.bank_statment_third_documents_id),
                        );
                      }
                      formik.setFieldValue(`accounts[${index}].bank_statment_third_documents_id`, newUpload?.id);
                    }}
                    optionalName="3er estado de cuenta bancario"
                    optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                    metadata={{
                      taxCode: TAX_SYSTEMS.PM.code,
                      fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario_zona_gris.code,
                      uploadType: "client",
                      loanApplicationId: applicationData.id,
                      regimen: applicationData.type,
                      personId: undefined,
                      clientDetailsId: personalData.id,
                      personType: undefined,
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />

                  {index > 0 && (
                    <Box textAlign={"left"} my={2} mt={2}>
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        onClick={() => handleOnOpenModal(index)}
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                        color={theme?.palette?.primary?.main}
                      >
                        - Eliminar cuenta bancaria
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}

              <Box mt={2} mb={2}>
                <Typography textAlign={"center"} variant="body2" fontWeight={500} color={theme?.palette?.primary?.dark}>
                  Si tienes mas de una cuenta principal, puedes agregar las que consideres necesarias.
                </Typography>
              </Box>

              <Box textAlign={"left"} my={2}>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  onClick={handleOnAddAccountBank}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                  color={theme?.palette?.primary?.main}
                >
                  + Agregar otra cuenta bancaria
                </Typography>
              </Box>

              <Box mt={3} mb={2}>
                <Typography
                  textAlign={"center"}
                  color={theme?.palette?.primary?.dark}
                  variant={!isMobileOrTablet ? "body1" : "body2"}
                  fontWeight={600}
                >
                  Estados Financieros
                </Typography>
              </Box>
              <CustomUploadFileV2
                mt={2}
                inputName={"annual_closing_id"}
                uploadedFile={listDocuments.find((item) => item.id === formik.values.annual_closing_id) || undefined}
                setUploadedFile={(newUpload) => {
                  formik.setFieldValue(`annual_closing_id`, newUpload?.id || "");

                  if (newUpload) {
                    setListDocuments((prev) => [...prev, newUpload]);
                  }
                }}
                optionalName="Cierre Anual"
                optionalDescription=""
                registerFormik={() => {}}
                metadata={{
                  taxCode: TAX_SYSTEMS.PM.code,
                  fileCode: DOCUMENTS_CATALOG.cierre_anual.code,
                  uploadType: "client",
                  loanApplicationId: applicationData.id,
                  regimen: applicationData.type,
                  personId: undefined,
                  clientDetailsId: personalData.id,
                  personType: undefined,
                }}
                disabled={isLoading || formik.isSubmitting}
              />

              <CustomUploadFileV2
                mt={2}
                inputName={"last_partial_closing_id"}
                uploadedFile={
                  listDocuments.find((item) => item.id === formik.values.last_partial_closing_id) || undefined
                }
                setUploadedFile={(newUpload) => {
                  formik.setFieldValue(`last_partial_closing_id`, newUpload?.id || "");

                  if (newUpload) {
                    setListDocuments((prev) => [...prev, newUpload]);
                  }
                }}
                optionalName="Ultimo Cierre Parcial"
                optionalDescription=""
                registerFormik={() => {}}
                metadata={{
                  taxCode: TAX_SYSTEMS.PM.code,
                  fileCode: DOCUMENTS_CATALOG.ultimo_cierre_parcial.code,
                  uploadType: "client",
                  loanApplicationId: applicationData.id,
                  regimen: applicationData.type,
                  personId: undefined,
                  clientDetailsId: personalData.id,
                  personType: undefined,
                }}
                disabled={isLoading || formik.isSubmitting}
              />
              {formik.values.another_document.map((document: AnotherDocumentProps, index: number) => (
                <Box key={index}>
                  <CustomUploadFileV2
                    mt={2}
                    inputName={`another_document[${index}].financial_statements_id`}
                    uploadedFile={
                      listDocuments.find((item) => item.id === document.financial_statements_id) || undefined
                    }
                    setUploadedFile={(newUpload) => {
                      if (newUpload) {
                        setListDocuments((prev) => [...prev, newUpload]);
                      } else {
                        setListDocuments((prev) => prev.filter((item) => item.id !== document.financial_statements_id));
                      }
                      formik.setFieldValue(`another_document[${index}].financial_statements_id`, newUpload?.id);
                    }}
                    optionalName="Estados Financieros"
                    optionalDescription=""
                    metadata={{
                      taxCode: TAX_SYSTEMS.PM.code,
                      fileCode: DOCUMENTS_CATALOG.estados_finacieros.code,
                      uploadType: "client",
                      loanApplicationId: applicationData.id,
                      regimen: applicationData.type,
                      personId: undefined,
                      clientDetailsId: personalData.id,
                      personType: undefined,
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Box>
              ))}

              <Box textAlign={"left"} my={2}>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  onClick={handleOnAddAnotherDocument}
                  sx={{ cursor: isDisabled ? "not-allowed" : "pointer", textDecoration: "underline" }}
                  color={theme?.palette?.primary?.main}
                >
                  + Agregar otro documento
                </Typography>
              </Box>

              <Box mt={2} mb={2}>
                <Typography textAlign={"center"} variant="body2" fontWeight={500} color={theme?.palette?.primary?.dark}>
                  El ultimo cierre parcial no podrá tener mas de 3 meses de antigüedad.
                </Typography>
              </Box>

              <Box mt={4} justifyContent={"center"} display="flex">
                <Button type="submit" variant="default" disabled={isLoading || !formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomDialog
        colorClose={theme?.palette?.primary?.light}
        open={acountToDelete !== undefined}
        onClose={handleOnCloseModal}
        itemTitle={<></>}
        itemContent={
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
              fontWeight={600}
              variant={isMobileOrTablet ? "h5" : "h6"}
            >
              ¿Estás seguro de eliminar la cuenta bancaria?
            </Typography>
            <Box display={"flex"} columnGap={2} justifyContent={"center"} mt={3}>
              <Button
                variant="blue_outlined"
                sx={{ py: 0, px: 1, width: "110px" }}
                onClick={handleOnCloseModal}
                disabled={isDeleting}
              >
                Regresar
              </Button>

              <Button
                variant="default"
                sx={{ width: "110px" }}
                onClick={() => handleDeleteAccount()}
                disabled={isDeleting}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default ZonaGris;
