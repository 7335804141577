/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import CustomRadioGroup from "@components/CustomRadioGroup";
import { usePutApplicationCompletedMutation, usePutPaymentDetailsMutation } from "@api/applications";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { updateApplicationCompleted } from "@store/slices/registerSlice";
import { setPfaePepInfo } from "@store/slices/bofuSlicePfae";
import {
  useLazyGetCargoPublicoQuery,
  useLazyGetDependeciasQuery,
  useLazyGetPepsTypeQuery,
  useLazyGetRelationshipQuery,
} from "@api/catalogs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  usePotsPepsDataMutation,
  useLazyGetPepsByPersonQuery,
  usePatchPepsDataMutation,
  useDeletePepsMutation,
} from "@api/pepsApi";
import { CargoPublicoData, DependenciasData, RelationshipData } from "@interfaces/index";
import { InactivityHandler } from "@components/InactivityHandler";
import { getDynamicUrl } from "@helpers/funciones";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

const SolicitudCreditoPart3PFAE: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const img = getDynamicUrl("img_tofu/img_tofu_5.svg");

  const companyData = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae.transactional_info);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const userData = useSelector((state: RootState) => state.register.personal_data);

  const [patchPepsData] = usePatchPepsDataMutation();
  const [deletePeps] = useDeletePepsMutation();
  const [triggerPutPaymentDetails] = usePutPaymentDetailsMutation();
  const [postPeps] = usePotsPepsDataMutation();
  const [getPepsType, { data: dataPeps, isLoading: loadingPeps }] = useLazyGetPepsTypeQuery();
  const [pepsIdSocio, setPepsSocioId] = useState("");
  const [pepsIdFamiliar, setPepsFamiliarId] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState<any>(null);
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const [getPepsByPersonSocio, { data: pepsDataSocio }] = useLazyGetPepsByPersonQuery();
  const [getPepsByPersonConyuge, { data: pepsDataConyuge, isSuccess: isSuccessConyugue }] =
    useLazyGetPepsByPersonQuery();

  const [listDependecias, setListDependecias] = useState<DependenciasData[]>([]);
  const [listCargo, setListCargo] = useState<CargoPublicoData[]>([]);
  const [listFamiliar, setListFamiliar] = useState<RelationshipData[]>([]);

  const [getFamiliar, { data: dataFamiliar, isLoading: loadingFamiliar }] = useLazyGetRelationshipQuery();
  const [getDependeciasLista, { data: dataDependencia, isLoading: loadingDependencia }] = useLazyGetDependeciasQuery();
  const [getCargoPulico, { data: dataCargo, isLoading: loadingCargo }] = useLazyGetCargoPublicoQuery();
  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues] = useState({
    socioFuncionario:
      companyData.details.pep_shareholder === true
        ? "true"
        : companyData.details.pep_shareholder === false
        ? "false"
        : "",

    conyuge:
      companyData.details.pep_members === true ? "true" : companyData.details.pep_members === false ? "false" : "",
    dependenciaSocio: "",
    cargoSocio: "",
    actualmenteSocio: "",
    start_dateSocio: "",
    end_dateSocio: "",

    peps_conyuge: [
      {
        parentesco_conyuge: "",
        nombre_conyuge: "",
        ap_paterno_conyuge: "",
        ap_materno_conyuge: "",
        dependencia_conyuge: "",
        cargo_conyuge: "",
        actualmente_conyuge: "",
        start_date_conyuge: "",
        end_date_conyuge: "",
        id: "",
      },
    ],
  });

  const validationSchema = yup.object({
    socioFuncionario: yup.string().required("Requerido"),
    conyuge: yup.string().required("Requerido"),
    peps_socio: yup.array().of(
      yup.object().shape({
        nombre: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        ap_paterno: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        ap_materno: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        dependencia: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        cargo: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        actualmente: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        start_date: yup
          .string()
          .when("socioFuncionario", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$socioFuncionario", ([socioFuncionario], schema) =>
            socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        end_date: yup.string().when("actualmente", {
          is: "false",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    ),
    peps_conyuge: yup.array().of(
      yup.object().shape({
        parentesco_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        nombre_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        ap_paterno_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        ap_materno_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        dependencia_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        cargo_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        actualmente_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        start_date_conyuge: yup
          .string()
          .when("conyuge", {
            is: "true",
            then: (schema) => schema.required("Campo requerido"),
            otherwise: (schema) => schema.notRequired(),
          })
          .when("$conyuge", ([conyuge], schema) =>
            conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
          ),
        end_date_conyuge: yup.string().when("actualmente_conyuge", {
          is: "false",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (!companyData.details.id) {
          return;
        }

        await triggerPutPaymentDetails({
          solicitudId: applicationData.id,
          detailId: companyData.details.id,
          body: {
            pep_shareholder: values.socioFuncionario === "true",
            pep_members: values.conyuge === "true",
          },
        }).unwrap();

        if (values.conyuge === "true") {
          for (const pep of values.peps_conyuge) {
            const dataPepsFamiliar = {
              name: `${pep.nombre_conyuge} ${pep.ap_paterno_conyuge} ${pep.ap_materno_conyuge}`,
              position: pep.cargo_conyuge,
              dependency: pep.dependencia_conyuge,
              active: pep.actualmente_conyuge === "true",
              start_date: pep.start_date_conyuge,
              loan_application_id: applicationData.id,
              pep_type_id: pepsIdFamiliar,
              end_date: pep.end_date_conyuge === "" ? null : pep.end_date_conyuge,
              client_details_id: String(applicationData.client_details_id),
              relationship_id: pep.parentesco_conyuge,
            };

            if (pep.id !== "" && pep.id !== undefined) {
              await patchPepsData({ id: pep.id, body: dataPepsFamiliar }).unwrap();
            } else {
              await postPeps(dataPepsFamiliar).unwrap();
            }
          }
        }

        if (values.socioFuncionario === "true") {
          if (pepsDataSocio && pepsDataSocio.length > 0) {
            const filteredData = pepsDataSocio.filter((item) => item.pep_type_id === pepsIdSocio);
            const payloadSocio = {
              id: filteredData[0].id,
              body: {
                name: `${userData.name} ${userData.first_lastname} ${userData.second_lastname}`,
                position: values.cargoSocio,
                dependency: values.dependenciaSocio,
                active: values.actualmenteSocio === "true",
                start_date: values.start_dateSocio,
                end_date: values.end_dateSocio ? values.end_dateSocio : null,
                loan_application_id: applicationData.id,
                pep_type_id: pepsIdSocio,
                client_details_id: applicationData.client_details_id,
              },
            };

            await patchPepsData(payloadSocio).unwrap();
          } else {
            const payloadSocio = {
              name: `${userData.name} ${userData.first_lastname} ${userData.second_lastname}`,
              position: values.cargoSocio,
              dependency: values.dependenciaSocio,
              active: values.actualmenteSocio === "true",
              start_date: values.start_dateSocio,
              end_date: values.end_dateSocio ? values.end_dateSocio : null,
              loan_application_id: applicationData.id,
              pep_type_id: pepsIdSocio,
              client_details_id: applicationData.client_details_id,
            };
            await postPeps(payloadSocio).unwrap();
          }
        }

        const data = {
          loan_application_completed: true,
        };

        await putAapplicationCompleted({ id_sol: applicationData.id, body: data }).unwrap();

        dispatch(updateApplicationCompleted(true));
        dispatch(
          setPfaePepInfo({
            pepMembers: values.conyuge === "true",
            pepShareholder: values.socioFuncionario === "true",
          }),
        );

        navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    },
    validateOnMount: true,
    validateOnChange: true,
  });

  useEffect(() => {
    getPeps();
  }, []);

  const getPeps = async () => {
    try {
      await getPepsType();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };
  const getDependecia = async () => {
    try {
      await getDependeciasLista();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  const getCargo = async () => {
    try {
      await getCargoPulico();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  useEffect(() => {
    if (dataPeps) {
      const pepsTypeId = dataPeps.find((entity) => entity.name === "Familiar");
      const pepsSocioTypeId = dataPeps.find((entity) => entity.name === "Socio");
      setPepsFamiliarId(String(pepsTypeId?.id));
      setPepsSocioId(String(pepsSocioTypeId?.id));
    }
  }, [dataPeps, loadingPeps]);

  useEffect(() => {
    if (dataDependencia) {
      setListDependecias(dataDependencia);
    }
  }, [dataDependencia, loadingDependencia]);

  useEffect(() => {
    if (dataCargo) {
      setListCargo(dataCargo);
    }
  }, [dataCargo, loadingCargo]);

  useEffect(() => {
    if (dataFamiliar) {
      setListFamiliar(dataFamiliar);
    }
  }, [dataFamiliar, loadingFamiliar]);

  const getFamiliares = async () => {
    try {
      await getFamiliar();
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  useEffect(() => {
    getDependecia();
    getCargo();
    getPeps();
    getFamiliares();
  }, []);

  const handleRemoveConyuge = async (index: number, id: string) => {
    if (id) {
      try {
        await deletePeps({ id: id }).unwrap();
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      }
    }

    const updatedPepsConyuge = formik.values.peps_conyuge.filter((_, i) => i !== index);
    formik.setFieldValue("peps_conyuge", updatedPepsConyuge);

    if (updatedPepsConyuge.length === 0) {
      formik.setFieldValue("conyuge", "false");
    }

    setDisableAdd(updatedPepsConyuge.length >= 5);
  };

  const handleAddConyuge = () => {
    if (formik.values.peps_conyuge.length < 5) {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
        },
      ]);
    } else {
      setDisableAdd(true);
    }
  };

  useEffect(() => {
    if (dataPeps) {
      const pepsTypeId = dataPeps.find((entity) => entity.name === "Familiar");
      const pepsSocioTypeId = dataPeps.find((entity) => entity.name === "Socio");
      setPepsFamiliarId(String(pepsTypeId?.id));
      setPepsSocioId(String(pepsSocioTypeId?.id));
    }
  }, [dataPeps, loadingPeps]);

  useEffect(() => {
    if (applicationData.client_details_id && pepsIdSocio) {
      getPepsByPersonSocio({ personId: applicationData.client_details_id, pepsId: pepsIdSocio });
    }
  }, [applicationData.client_details_id, pepsIdSocio]);

  useEffect(() => {
    if (applicationData.client_details_id && pepsIdFamiliar) {
      getPepsByPersonConyuge({ personId: applicationData.client_details_id, pepsId: pepsIdFamiliar });
    }
  }, [applicationData.client_details_id, pepsIdFamiliar]);

  useEffect(() => {
    if (pepsDataSocio && pepsDataSocio.length > 0) {
      setTimeout(() => {
        const filteredData = pepsDataSocio.filter((item) => item.pep_type_id === pepsIdSocio);

        const pep = filteredData[0];
        formik.setFieldValue("dependenciaSocio", pep.dependency);
        formik.setFieldValue("cargoSocio", pep.position);
        formik.setFieldValue("actualmenteSocio", pep.end_date ? "false" : "true");
        formik.setFieldValue("parentesco_socio", pep.relationship_id);
        if (pep.start_date) {
          const startDate = dayjs(pep.start_date);
          formik.setFieldValue("start_dateSocio", startDate);
          setSelectedDate(startDate);
        }

        if (pep.end_date) {
          const endDate = dayjs(pep.end_date);
          formik.setFieldValue("end_dateSocio", endDate);
          setSelectedDateEnd(endDate);
        } else {
          formik.setFieldValue("end_dateSocio", null);
        }
      }, 1000);
    }
  }, [pepsDataSocio, pepsIdSocio]);

  const separarNombres = (nombreCompleto: string) => {
    const partes = nombreCompleto.split(" ");

    let nombre = "";
    let apellido1 = "";
    let apellido2 = "";

    if (partes.length >= 2) {
      nombre = partes.slice(0, partes.length - 2).join(" ");
      apellido1 = partes[partes.length - 2];
      apellido2 = partes[partes.length - 1];
    } else if (partes.length === 1) {
      nombre = partes[0];
    }

    return {
      nombre,
      apellido1,
      apellido2,
    };
  };

  useEffect(() => {
    if (pepsDataConyuge) {
      const dataFamiliar = pepsDataConyuge.filter((item) => item.pep_type_id === pepsIdFamiliar);

      if (dataFamiliar.length > 0) {
        const pepsConyugeData = dataFamiliar.map((item) => {
          const nombreCompleto = separarNombres(item.name);
          return {
            parentesco_conyuge: item.relationship_id,
            nombre_conyuge: nombreCompleto.nombre,
            ap_paterno_conyuge: nombreCompleto.apellido1 || "",
            ap_materno_conyuge: nombreCompleto.apellido2 || "",
            dependencia_conyuge: item.dependency,
            cargo_conyuge: item.position,
            actualmente_conyuge: item.active ? "true" : "false",
            start_date_conyuge: item.start_date,
            end_date_conyuge: item.end_date === null ? "" : item.end_date,
            id: item.id,
          };
        });
        formik.setFieldValue("peps_conyuge", pepsConyugeData);
      }
    }
  }, [pepsDataConyuge, isSuccessConyugue, pepsIdFamiliar]);

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.peps_conyuge?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} maxWidth="sm" mx="auto">
          <Typography
            mt={4}
            mb={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
          >
            Solicitud de crédito
          </Typography>
          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={800}
            color={theme?.palette?.primary?.dark}
          >
            3{" "}
            <Typography
              component="span"
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={400}
              color={theme?.palette?.primary?.dark}
            >
              de 3
            </Typography>
          </Typography>
          <Typography
            my={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
          >
            Datos del negocio
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box mt={4}>
              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  name="socioFuncionario"
                  label="¿Desempeñas o has desempeñado funciones públicas destacadas en México o el extranjero, altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos?​"
                  options={[
                    { value: "true", label: "Sí" },
                    { value: "false", label: "No" },
                  ]}
                  value={formik.values.socioFuncionario}
                  onChange={formik.handleChange}
                  error={formik.touched.socioFuncionario && Boolean(formik.errors.socioFuncionario)}
                  helperText={formik.touched.socioFuncionario ? String(formik.errors.socioFuncionario) : ""}
                />
              </Box>

              {formik.values.socioFuncionario === "true" && (
                <>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      mt: 2,
                    }}
                    error={formik.touched.dependenciaSocio && Boolean(formik.errors.dependenciaSocio)}
                  >
                    <InputLabel id="dependenciaSocio">Dependencia del cargo</InputLabel>
                    <Select
                      labelId="dependenciaSocio"
                      id="dependenciaSocio"
                      name="dependenciaSocio"
                      fullWidth
                      required
                      label="Dependencia del cargo"
                      value={formik.values.dependenciaSocio}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.dependenciaSocio && Boolean(formik.errors.dependenciaSocio)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listDependecias.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.dependenciaSocio && formik.errors.dependenciaSocio && (
                      <FormHelperText>{formik.errors.dependenciaSocio}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      mt: 2,
                    }}
                    error={formik.touched.cargoSocio && Boolean(formik.errors.cargoSocio)}
                  >
                    <InputLabel id="cargoSocio">Cargo público</InputLabel>
                    <Select
                      labelId="cargoSocio"
                      id="cargoSocio"
                      name="cargoSocio"
                      fullWidth
                      required
                      label="Cargo público"
                      value={formik.values.cargoSocio}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.cargoSocio && Boolean(formik.errors.cargoSocio)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listCargo.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.cargoSocio && formik.errors.cargoSocio && (
                      <FormHelperText>{formik.errors.cargoSocio}</FormHelperText>
                    )}
                  </FormControl>

                  {formik.values.socioFuncionario === "true" && (
                    <>
                      <Box textAlign={"left"} my={2}>
                        <CustomRadioGroup
                          label={"¿Actualmente esta en ese cargo?​"}
                          name={"actualmenteSocio"}
                          value={formik.values.actualmenteSocio}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.actualmenteSocio && Boolean(formik.errors.actualmenteSocio)}
                          helperText={formik.touched.actualmenteSocio && formik.errors.actualmenteSocio}
                          options={[
                            {
                              label: "Si",
                              value: "true",
                            },
                            {
                              label: "No",
                              value: "false",
                            },
                          ]}
                        />
                      </Box>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                          <DatePicker
                            label="Fecha de Inicio"
                            value={selectedDate}
                            onChange={(date) => {
                              setSelectedDate(date);
                              formik.setFieldValue("start_dateSocio", dayjs(date).format("YYYY-MM-DD"));
                            }}
                            maxDate={dayjs()}
                            slotProps={{
                              textField: {
                                name: "start_dateSocio",
                                onBlur: formik.handleBlur,
                                error: formik.touched.start_dateSocio && Boolean(formik.errors.start_dateSocio),
                                helperText: formik.touched.start_dateSocio ? formik.errors.start_dateSocio : "",
                                required: true,
                                inputProps: {
                                  "aria-invalid":
                                    formik.touched.start_dateSocio && Boolean(formik.errors.start_dateSocio),
                                  "aria-hidden": false,
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </>
                  )}

                  {formik.values.actualmenteSocio === "false" && (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DatePicker
                          label="Fecha de termino"
                          value={selectedDateEnd}
                          onChange={(date) => {
                            setSelectedDateEnd(date);
                            formik.setFieldValue("end_dateSocio", dayjs(date).format("YYYY-MM-DD"));
                          }}
                          maxDate={dayjs()}
                          minDate={selectedDate ? dayjs(selectedDate) : dayjs()}
                          slotProps={{
                            textField: {
                              name: "end_dateSocio",
                              onBlur: formik.handleBlur,
                              error: formik.touched.end_dateSocio && Boolean(formik.errors.end_dateSocio),
                              helperText: formik.touched.end_dateSocio ? formik.errors.end_dateSocio : "",
                              required: true,
                              inputProps: {
                                "aria-invalid": formik.touched.end_dateSocio && Boolean(formik.errors.end_dateSocio),
                                "aria-hidden": false,
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                </>
              )}
              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  name="conyuge"
                  label="¿El cónyuge o alguno de los padres, abuelos, hijos, nietos, hermanos, suegros, hijos políticos, abuelos políticos o cuñados de los socios, accionistas o propietarios reales, desempeña o ha desempeñado funciones públicas destacadas en México o en el extranjero, altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos?"
                  options={[
                    { value: "true", label: "Sí" },
                    { value: "false", label: "No" },
                  ]}
                  value={formik.values.conyuge}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.conyuge && Boolean(formik.errors.conyuge)}
                  helperText={formik.touched.conyuge ? String(formik.errors.conyuge) : ""}
                />
                {formik.values.conyuge === "true" && (
                  <>
                    {formik.values.peps_conyuge.map((pep, index) => (
                      <Box key={index}>
                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                              getFieldError("parentesco_conyuge", index),
                          )}
                        >
                          <InputLabel id={`parentesco_conyuge-${index}`}>Parentesco</InputLabel>
                          <Select
                            labelId={`parentesco_conyuge-${index}`}
                            id={`parentesco_conyuge-${index}`}
                            name={`peps_conyuge[${index}].parentesco_conyuge`}
                            fullWidth
                            required
                            label="Parentesco"
                            value={pep.parentesco_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                                getFieldError("parentesco_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listFamiliar.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                            getFieldError("parentesco_conyuge", index) && (
                              <FormHelperText>{getFieldError("parentesco_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <TextField
                          id={`nombre_conyuge-${index}`}
                          name={`peps_conyuge[${index}].nombre_conyuge`}
                          label="Nombre(s)"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.nombre_conyuge &&
                              getFieldError("nombre_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.nombre_conyuge &&
                            getFieldError("nombre_conyuge", index)
                          }
                          value={pep.nombre_conyuge}
                        />

                        <TextField
                          id={`ap_paterno_conyuge-${index}`}
                          name={`peps_conyuge[${index}].ap_paterno_conyuge`}
                          label="Apellido paterno"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                              getFieldError("ap_paterno_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                            getFieldError("ap_paterno_conyuge", index)
                          }
                          value={pep.ap_paterno_conyuge}
                        />

                        <TextField
                          id={`ap_materno_conyuge-${index}`}
                          name={`peps_conyuge[${index}].ap_materno_conyuge`}
                          label="Apellido materno"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                              getFieldError("ap_materno_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                            getFieldError("ap_materno_conyuge", index)
                          }
                          value={pep.ap_materno_conyuge}
                        />

                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                              getFieldError("dependencia_conyuge", index),
                          )}
                        >
                          <InputLabel id={`dependencia_conyuge-${index}`}>Dependencia del cargo</InputLabel>
                          <Select
                            labelId={`dependencia_conyuge-${index}`}
                            id={`dependencia_conyuge-${index}`}
                            name={`peps_conyuge[${index}].dependencia_conyuge`}
                            fullWidth
                            required
                            label="Dependencia del cargo"
                            value={pep.dependencia_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                                getFieldError("dependencia_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listDependecias.map((item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                            getFieldError("dependencia_conyuge", index) && (
                              <FormHelperText>{getFieldError("dependencia_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                              getFieldError("cargo_conyuge", index),
                          )}
                        >
                          <InputLabel id={`cargo_conyuge-${index}`}>Cargo público</InputLabel>
                          <Select
                            labelId={`cargo_conyuge-${index}`}
                            id={`cargo_conyuge-${index}`}
                            name={`peps_conyuge[${index}].cargo_conyuge`}
                            fullWidth
                            required
                            label="Cargo público"
                            value={pep.cargo_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                                getFieldError("cargo_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listCargo.map((item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                            getFieldError("cargo_conyuge", index) && (
                              <FormHelperText>{getFieldError("cargo_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <Box textAlign={"left"} my={2}>
                          <CustomRadioGroup
                            label={"¿Actualmente esta en ese cargo?​"}
                            name={`peps_conyuge[${index}].actualmente_conyuge`}
                            value={pep.actualmente_conyuge}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                                getFieldError("actualmente_conyuge", index),
                            )}
                            helperText={
                              formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                              getFieldError("actualmente_conyuge", index)
                            }
                            options={[
                              {
                                label: "Si",
                                value: "true",
                              },
                              {
                                label: "No",
                                value: "false",
                              },
                            ]}
                          />
                        </Box>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker
                              label="Fecha de Inicio"
                              value={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : null}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `peps_conyuge[${index}].start_date_conyuge`,
                                  dayjs(date).format("YYYY-MM-DD"),
                                );
                              }}
                              slotProps={{
                                textField: {
                                  name: `peps_conyuge[${index}].start_date_conyuge`,
                                  onBlur: formik.handleBlur,
                                  error: Boolean(
                                    formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                      getFieldError("start_date_conyuge", index),
                                  ),
                                  helperText: formik.touched.peps_conyuge?.[index]?.start_date_conyuge
                                    ? getFieldError("start_date_conyuge", index)
                                    : "",
                                  required: true,
                                  inputProps: {
                                    "aria-invalid": Boolean(
                                      formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                        getFieldError("start_date_conyuge", index),
                                    ),
                                    "aria-hidden": false,
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        {formik.values.peps_conyuge[index].actualmente_conyuge === "false" && (
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                              <DatePicker
                                label="Fecha de termino"
                                value={pep.end_date_conyuge ? dayjs(pep.end_date_conyuge) : null}
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `peps_conyuge[${index}].end_date_conyuge`,
                                    dayjs(date).format("YYYY-MM-DD"),
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    name: `peps_conyuge[${index}].end_date_conyuge`,
                                    onBlur: formik.handleBlur,
                                    error: Boolean(
                                      formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                        getFieldError("end_date_conyuge", index),
                                    ),
                                    helperText: formik.touched.peps_conyuge?.[index]?.end_date_conyuge
                                      ? getFieldError("end_date_conyuge", index)
                                      : "",
                                    required: true,
                                    inputProps: {
                                      "aria-invalid": Boolean(
                                        formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                          getFieldError("end_date_conyuge", index),
                                      ),
                                      "aria-hidden": false,
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        )}

                        <Typography
                          onClick={() => handleRemoveConyuge(index, pep.id)}
                          color={"#F2704F"}
                          fontSize={"15px"}
                          fontWeight={400}
                          mt={3}
                          sx={{ cursor: "pointer", textDecoration: "" }}
                        >
                          - Remover Persona
                        </Typography>
                        <hr />
                      </Box>
                    ))}
                    <Typography
                      onClick={handleAddConyuge}
                      color={theme?.palette?.primary?.main}
                      fontSize={"15px"}
                      fontWeight={400}
                      mt={3}
                      sx={{ cursor: disableAdd ? "not-allowed" : "pointer" }}
                    >
                      + Agregar otro
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Grid container spacing={2} mt={4}>
              <Grid item xs={6}>
                <Button
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  fullWidth
                  onClick={() => navigate("/producto/creditosimple/avance_expediente_digitalPFAE")}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="default"
                  fullWidth
                  color="primary"
                  disabled={!formik.isValid || isLoading}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SolicitudCreditoPart3PFAE;
