import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    default: true;
    default_disabled: true;
    orange: true;
    black: true;
    blue_outlined: true;
    blue_demon: true;
    green: true;
    expediente_validado: true;
    expediente: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    fontWeights?: {
      light: number;
      regular: number;
      medium: number;
      semibold: number;
      bold: number;
    };
  }

  interface PaletteOptions {
    fontWeights?: {
      light: number;
      regular: number;
      medium: number;
      semibold: number;
      bold: number;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    backgroundRight?: {
      main: string;
      dark: string;
      light: string;
      tab: string;
    };
  }

  interface PaletteOptions {
    backgroundRight?: {
      main: string;
      dark: string;
      light: string;
      tab: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    phoneInput?: {
      main: string;
    };
  }

  interface PaletteOptions {
    phoneInput?: {
      main: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    otherColors?: {
      main: string;
      backgroundsColors: string;
      tabText: string;
    };
  }

  interface PaletteOptions {
    otherColors?: {
      main: string;
      backgroundsColors: string;
      tabText: string;
    };
  }
}

export const CustomTheme = createTheme({
  palette: {
    primary: {
      main: "#D45D00",
      dark: "#333F48",
      light: "#FFC72C",
    },
    secondary: {
      main: "#F2704F",
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    backgroundRight: {
      main: "#D45D00",
      dark: "#333F48",
      light: "#FFC72C",
      tab: "#FFF9E9",
    },
    phoneInput: {
      main: "#FEF5DD",
    },
    otherColors: {
      main: "#FFFFFF",
      backgroundsColors: "#333F48",
      tabText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ["Outfit"].join(","),
    h1: {
      fontSize: 39,
      fontWeight: 300,
    },
    h2: {
      fontSize: 31,
      fontWeight: 300,
    },
    h3: {
      fontSize: 25,
      fontWeight: 300,
    },
    h4: {
      fontSize: 20,
      fontWeight: 300,
    },
    h5: {
      fontSize: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 13,
      fontWeight: 300,
    },
    body3: {
      fontSize: 11,
      fontWeight: 300,
      fontFamily: "Outfit",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#FFC72C",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#FFC72C",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFC72C",
          },
          "& .MuiInput-underline::before": {
            borderColor: "#FFC72C",
          },
          "& .MuiFilledInput-underline::before": {
            borderColor: "#FFC72C",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#CD0202",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: "10px 25px",
          textTransform: "none",
          fontSize: 16,
          fontWeight: 700,
        },
      },
      variants: [
        {
          props: {
            variant: "default",
          },
          style: {
            backgroundColor: "#D45D00",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#D45D00",
              opacity: 0.4,
              color: "#FFF",
            },
            "&:hover": {
              backgroundColor: "#333F48",
              color: "#FFC72C",
            },
            "&.MuiButton-defaultSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },
        {
          props: {
            variant: "default_disabled",
          },
          style: {
            backgroundColor: "#528CD6",
            opacity: 0.4,
            color: "#FFF",
            // "&.Mui-disabled": {
            //   backgroundColor: "#528CD6",
            //   opacity: 0.4,
            //   color: "#FFF",
            // },
            "&:hover": {
              backgroundColor: "#528CD6",
              color: "#FFF",
            },
            "&.MuiButton-defaultSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },

        {
          props: {
            variant: "contained",
          },
          style: {
            "&.Mui-disabled": {
              backgroundColor: "#D45D00",
              opacity: 0.4,
              color: "#FFF",
            },
          },
        },
        {
          props: {
            variant: "orange",
          },
          style: {
            backgroundColor: "#F2704F",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#F2704F",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#F2704F",
            },
          },
        },
        {
          props: {
            variant: "black",
          },
          style: {
            backgroundColor: "#2C2C2C",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#2C2C2C",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#2C2C2C",
            },
          },
        },
        {
          props: {
            variant: "blue_outlined",
          },
          style: {
            color: "#333F48",
            backgroundColor: "#FFF",
            border: "2px solid #333F48",
            lineHeight: "22px",
            "&.Mui-disabled": {
              color: "#bdbdbd",
              border: "2px solid #bdbdbd",
              backgroundColor: "transparent",
            },
            "&.MuiButton-blue_outlinedSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },
        {
          props: {
            variant: "blue_demon",
          },
          style: {
            // color: "#FFC72C",
            color: "#FFFFFF",
            backgroundColor: "#333F48",
            "&.Mui-disabled": {
              color: "#FFFFFF",
              backgroundColor: "#333F48",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#333F48",
            },
          },
        },
        {
          props: {
            variant: "green",
          },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#00BB2D",
            "&.Mui-disabled": {
              backgroundColor: "#009A24",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#009A24",
            },
          },
        },
        {
          props: {
            variant: "expediente_validado",
          },
          style: {
            width: "150px",
            height: "30px",
            fontSize: 15,
            color: "#D45D00",
            backgroundColor: "#FFC72C",
            "&.Mui-disabled": {
              backgroundColor: "#FFC72C",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#FFC72C",
            },
          },
        },
        {
          props: {
            variant: "expediente_validado",
            size: "small",
          },
          style: {
            width: "90px",
            height: "23px",
            fontSize: 13,
          },
        },
        {
          props: {
            variant: "expediente",
          },
          style: {
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "150px",
            height: "30px",
            fontSize: 15,
            backgroundColor: "#D45D00",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#D45D00",
              opacity: 0.4,
              color: "#FFF",
            },
            "&:hover": {
              backgroundColor: "#333F48",
              color: "#FFC72C",
            },
          },
        },
        {
          props: {
            variant: "expediente",
            size: "small",
          },
          style: {
            width: "90px",
            height: "23px",
            fontSize: 13,
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#FF6060",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-asterisk": {
            color: "#CD0202",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#FFC72C", // Borde azul cuando no está enfocado
            },
            "&:hover fieldset": {
              borderColor: "#FFC72C", // Borde azul al pasar el cursor
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFC72C", // Borde azul cuando está enfocado
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFC72C", // Color del icono de selección
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // "& .MuiSelect-icon": {
          //   color: "#FFC72C",
          // },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFC72C",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "14px",
          boxShadow: "8px 4px 20px 0px rgba(82, 140, 214, 0.25)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          // border: "2px red solid"
          // ".aria-labelledby": {
          //   border: "2px blue solid"
          // }
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        determinate: {
          color: "#FFC72C",
        },
        indeterminate: {
          color: "#D45D00",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#333F48",
        },
      },
    },
  },
});
