import { LegalAgent } from "@interfaces/index";
import { UploadedFile } from "@interfaces/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "crypto";

export interface BankAccount {
  id: UUID | undefined | string;
  clabe: string;
  bank_name: string;
  bank_statements: Array<UploadedFile>;
}

export interface TransactionalInfo {
  payment_methods: Array<UUID>;
  details: {
    id: UUID | undefined | string;
    advance_payments: boolean | undefined;
    own_resources: boolean | undefined;
    pep_shareholder: boolean | undefined;
    pep_members: boolean | undefined;
  };
}

export interface Reference {
  id: UUID | string | undefined;
  client_id: string;
  email: string;
  code_phone: string | undefined;
  phone_number: string | undefined;
}

export interface BofuSlice {
  digital_file: {
    current_step: "pfae";
    pfae: {
      completed: boolean;
      files: Array<UploadedFile>;
      bank_accounts: Array<BankAccount>;
      transactional_info: TransactionalInfo;
      references: {
        clients: Array<Reference>;
        suppliers: Array<Reference>;
        complete: boolean;
      };
      loan_application: {
        legal_agent: LegalAgent;
      };
    };
  };
}

const initialState: BofuSlice = {
  digital_file: {
    current_step: "pfae",
    pfae: {
      completed: false,
      files: [],
      bank_accounts: [
        {
          id: undefined,
          clabe: "",
          bank_name: "",
          bank_statements: [
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
          ],
        },
      ],
      transactional_info: {
        payment_methods: [],
        details: {
          id: undefined,
          advance_payments: undefined,
          own_resources: undefined,
          pep_shareholder: undefined,
          pep_members: undefined,
        },
      },
      references: {
        clients: [],
        suppliers: [],
        complete: false,
      },
      loan_application: {
        legal_agent: {
          code_phone: undefined,
          phone_number: undefined,
          email: "",
          company: {
            activity_id: undefined,
            description: "",
          },
          operative_address: {
            postal_code: "",
            street: "",
            no_ext: "",
            no_int: "",
            colonia_id: 0,
            state_id: 0,
            city_id: 0,
            country_id: 0,
            id: undefined,
            other_neighborhood: null,
          },
        },
      },
    },
  },
};

export const bofuSlicePfae = createSlice({
  name: "bofuPfae",
  initialState,
  reducers: {
    setPfaeDocument: (state, action: PayloadAction<UploadedFile>) => {
      if (!state.digital_file.pfae.files) {
        state.digital_file.pfae.files = [];
      }
      state.digital_file.pfae.files.push(action.payload);
    },
    removePfaeDocument: (state, action: PayloadAction<UUID>) => {
      state.digital_file.pfae.files = state.digital_file.pfae.files.filter((docs) => docs.id !== action.payload);
    },

    setPfeaBankAccount: (state) => {
      const bankAccount: BankAccount = {
        id: "00000000-0000-0000-0000-000000000000",
        clabe: "",
        bank_name: "",
        bank_statements: [],
      };
      if (!state.digital_file.pfae.bank_accounts) {
        state.digital_file.pfae.bank_accounts = [];
      }
      state.digital_file.pfae.bank_accounts.push(bankAccount);
    },
    setPfeaBankAccountUpdate: (state, action: PayloadAction<{ index: number; clabe: string; bank_name: string }>) => {
      state.digital_file.pfae.bank_accounts[action.payload.index].clabe = action.payload.clabe;
      state.digital_file.pfae.bank_accounts[action.payload.index].bank_name = action.payload.bank_name;
    },
    setPfeaBankAccountUpdateId: (state, action: PayloadAction<{ index: number; id: string }>) => {
      state.digital_file.pfae.bank_accounts[action.payload.index].id = action.payload.id;
    },
    removePfaeBankAccount: (state, action: PayloadAction<number>) => {
      state.digital_file.pfae.bank_accounts = state.digital_file.pfae.bank_accounts.filter(
        (_, index) => index !== action.payload,
      );
    },
    setPfaeBankAccountFile: (
      state,
      action: PayloadAction<{
        parentIndex: number;
        indexFile: number;
        uploadedFile: UploadedFile;
      }>,
    ) => {
      if (state.digital_file.pfae.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile]) {
        state.digital_file.pfae.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile] =
          action.payload.uploadedFile;
      } else {
        state.digital_file.pfae.bank_accounts[action.payload.parentIndex].bank_statements.push(
          action.payload.uploadedFile,
        );
      }
    },
    removePfaeBankAccountFile: (state, action: PayloadAction<{ parentIndex: number; indexFile: number }>) => {
      state.digital_file.pfae.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile] = {
        id: undefined,
        file_category_id: "00000000-0000-0000-0000-000000000000",
        url: "",
        status: true,
        created_at: "",
        updated_at: "",
        file_code: "",
        file_name: "",
        validated: null,
      };
    },
    // Operaciones relacionadas con referencias
    setPfaeClientReference: (state, action: PayloadAction<{ clientNumber: number; reference: Reference }>) => {
      state.digital_file.pfae.references.clients[action.payload.clientNumber] = action.payload.reference;
    },
    setPafeSupplierReference: (state, action: PayloadAction<{ clientNumber: number; reference: Reference }>) => {
      state.digital_file.pfae.references.suppliers[action.payload.clientNumber] = action.payload.reference;
    },
    setReferencePafeComplete: (state, action: PayloadAction<{ complete: boolean }>) => {
      state.digital_file.pfae.references.complete = action.payload.complete;
    },
    // Operaciones relacionadas con información transaccional
    setPfaeTransactionalInfo: (
      state,
      action: PayloadAction<{
        paymentMethods: Array<UUID>;
        paymentDetailsId: UUID;
        advancePayments: boolean;
        resourcesTypes: boolean;
      }>,
    ) => {
      // Asegúrate de que transactional_info está definido
      if (!state.digital_file.pfae.transactional_info) {
        state.digital_file.pfae.transactional_info = {
          payment_methods: [],
          details: {
            id: undefined,
            advance_payments: undefined,
            own_resources: undefined,
            pep_shareholder: undefined,
            pep_members: undefined,
          },
        };
      }

      // Ahora puedes actualizar los valores
      state.digital_file.pfae.transactional_info.payment_methods = action.payload.paymentMethods;
      state.digital_file.pfae.transactional_info.details.id = action.payload.paymentDetailsId;
      state.digital_file.pfae.transactional_info.details.advance_payments = action.payload.advancePayments;
      state.digital_file.pfae.transactional_info.details.own_resources = action.payload.resourcesTypes;
    },
    setPfaePepInfo: (state, action: PayloadAction<{ pepShareholder: boolean; pepMembers: boolean }>) => {
      state.digital_file.pfae.transactional_info.details.pep_shareholder = action.payload.pepShareholder;
      state.digital_file.pfae.transactional_info.details.pep_members = action.payload.pepMembers;
    },
    setPfaeTransactionalInfoId: (state, action: PayloadAction<{ id: string }>) => {
      state.digital_file.pfae.transactional_info.details.id = action.payload.id;
    },
    // Restauración de datos
    restoreBofuPfaeData: (
      state,
      action: PayloadAction<{
        files: Array<UploadedFile>;
        bank_accounts: Array<BankAccount> | undefined;
        transactional_info: TransactionalInfo | undefined;
        loan_application: { legal_agent: LegalAgent };
      }>,
    ) => {
      state.digital_file.pfae.files = action.payload.files;

      // Si no hay cuentas bancarias, asigna la estructura vacía inicial
      state.digital_file.pfae.bank_accounts = action.payload.bank_accounts?.length
        ? action.payload.bank_accounts
        : [
            {
              id: undefined,
              clabe: "",
              bank_name: "",
              bank_statements: [
                {
                  id: undefined,
                  file_category_id: "00000000-0000-0000-0000-000000000000",
                  url: "",
                  status: true,
                  created_at: "",
                  updated_at: "",
                  file_code: "",
                  file_name: "",
                  validated: null,
                },
                {
                  id: undefined,
                  file_category_id: "00000000-0000-0000-0000-000000000000",
                  url: "",
                  status: true,
                  created_at: "",
                  updated_at: "",
                  file_code: "",
                  file_name: "",
                  validated: null,
                },
                {
                  id: undefined,
                  file_category_id: "00000000-0000-0000-0000-000000000000",
                  url: "",
                  status: true,
                  created_at: "",
                  updated_at: "",
                  file_code: "",
                  file_name: "",
                  validated: null,
                },
              ],
            },
          ];

      // Verificación y asignación de transactional_info con estructura vacía si no hay datos
      state.digital_file.pfae.transactional_info = action.payload.transactional_info
        ? {
            payment_methods: action.payload.transactional_info.payment_methods?.length
              ? action.payload.transactional_info.payment_methods
              : ["00000000-0000-0000-0000-000000000000"],
            details: {
              id: action.payload.transactional_info.details?.id || undefined,
              advance_payments:
                action.payload.transactional_info.details?.advance_payments !== undefined
                  ? action.payload.transactional_info.details.advance_payments
                  : undefined,
              own_resources:
                action.payload.transactional_info.details?.own_resources !== undefined
                  ? action.payload.transactional_info.details.own_resources
                  : undefined,
              pep_shareholder:
                action.payload.transactional_info.details?.pep_shareholder !== undefined
                  ? action.payload.transactional_info.details.pep_shareholder
                  : undefined,
              pep_members:
                action.payload.transactional_info.details?.pep_members !== undefined
                  ? action.payload.transactional_info.details.pep_members
                  : undefined,
            },
          }
        : {
            payment_methods: ["00000000-0000-0000-0000-000000000000"],
            details: {
              id: undefined,
              advance_payments: undefined,
              own_resources: undefined,
              pep_shareholder: undefined,
              pep_members: undefined,
            },
          };

      state.digital_file.pfae.loan_application = action.payload.loan_application;
    },

    setPfaeCompleted: (state) => {
      state.digital_file.pfae.completed = true;
    },
  },
});

export const {
  setPfaeDocument,
  removePfaeDocument,
  setPfeaBankAccount,
  setPfeaBankAccountUpdate,
  setPfeaBankAccountUpdateId,
  setPfaeBankAccountFile,
  removePfaeBankAccountFile,
  setPfaeClientReference,
  setPafeSupplierReference,
  setReferencePafeComplete,
  setPfaeTransactionalInfo,
  setPfaePepInfo,
  setPfaeTransactionalInfoId,
  restoreBofuPfaeData,
  setPfaeCompleted,
  removePfaeBankAccount,
} = bofuSlicePfae.actions;

export default bofuSlicePfae.reducer;
