import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  IconButton,
  Theme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import LogoSmall from "@components/Images/LogoSmall";
import CloseIcon from "@mui/icons-material/Close";
import IconError500 from "@components/Images/IconError500";
import IconErrorNetwork from "@components/Images/IconErrorNetwork";
import { setModalErrorVisible } from "@store/slices/appSlice";

export const ModalErrors = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessageBody, setErrorMessageBody] = useState("");
  const [errorMessageFooter, setErrorMessageFooter] = useState("");
  const [errorImage, setErrorImage] = useState("");
  const [open, setOpen] = useState(false);

  const app = useSelector((state: RootState) => state.app);

  const handleClose = () => {
    setOpen(false);
    dispatch(setModalErrorVisible({ open: false, error: null }));
  };

  useEffect(() => {
    switch (app.modal_error?.type) {
      case "FETCH_ERROR":
        setErrorMessageTitle("¡Ups!");
        setErrorMessageBody("Parece que tu conexión a internet  está fallando");
        setErrorMessageFooter("Verifica tu conexión Wi-Fi o intenta recargar la página");
        setErrorImage("error_network");
        break;
      default:
        setErrorMessageTitle("Una disculpa, algo salió mal");
        setErrorMessageBody("Parece que nuestro servidor no responde, seguramente el equipo ya lo esta resolviendo");
        setErrorMessageFooter("Inténtalo nuevamente más tarde");
        setErrorImage("error_500");
        break;
    }
  }, [app.modal_error?.type]);

  useEffect(() => {
    setOpen(app.modal_error.visible);
  }, [app.modal_error.visible]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobileOrTablet}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          margin: "20px",
          width: isMobileOrTablet ? "100%" : "90%",
          height: isMobileOrTablet ? "80%" : "80%",
          maxHeight: "calc(100% - 40px)",
          backgroundColor: theme.palette.phoneInput?.main,
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>
        <LogoSmall />
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: "20px",
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant={isMobileOrTablet ? "h3" : "h2"} mt={2} color={theme.palette.primary.dark}>
          {errorMessageTitle}
        </Typography>

        <Typography variant={isMobileOrTablet ? "h5" : "h4"} mt={2} color={theme.palette.primary.dark}>
          {errorMessageBody}
        </Typography>

        <Box mt={5}>
          {errorImage === "error_network" && <IconErrorNetwork fill={theme.palette.primary.main} />}
          {errorImage === "error_500" && <IconError500 fill={theme.palette.primary.main} />}
        </Box>

        <Typography variant={isMobileOrTablet ? "h5" : "h4"} mt={5}>
          {errorMessageFooter}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
