/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  Stack,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useBuroValidatorMutation, BuroValidatorRequest } from "@api/sat_apis_new/satApsi";
import { CustomSwitch, CustomDialog, ProgressBar } from "@components/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { getDynamicUrl } from "@helpers/index";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";
import { CustomTextCiecBuro } from "@components/CustomTextCiecBuro";
import { ApiError } from "@interfaces/response";

interface FormValues {
  prestamo_hipotecario: boolean;
  credito_automotriz: boolean;
  tarjeta_credito: boolean;
  tarjeta: string;
  authorize: boolean;
}

const ConsultaBuroPfae = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const img = getDynamicUrl("right_9.svg");
  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [closeModalError, setCloseModalError] = useState<boolean>(true);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [buroValidator] = useBuroValidatorMutation();

  const companyData = useSelector((state: RootState) => state.register.company);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const addressData = useSelector((state: RootState) => state.register.address_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [initialValues] = useState<FormValues>({
    prestamo_hipotecario: false,
    credito_automotriz: false,
    tarjeta_credito: false,
    tarjeta: "",
    authorize: false,
  });

  const validationSchema = yup.object().shape({
    credito_automotriz: yup.boolean(),
    prestamo_hipotecario: yup.boolean(),
    tarjeta_credito: yup.boolean(),
    authorize: yup.boolean().isTrue("Se requiere aceptar"),
    tarjeta: yup.string().when("tarjeta_credito", {
      is: true,
      then: (schema) => schema.required("Campo requerido").min(4, "Formato de tarjeta no válido"),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const names = (companyData.pfae_name ?? "").split(" ");
        const first_name = names[0] || "";
        const second_name = names.slice(1).join(" ") || "";

        const payload_buro: BuroValidatorRequest = {
          person_Type: "pf",
          data: {
            rfc: companyData.rfc,
            transaction_number: String(applicationData.id_application),
            application_code: applicationData.company_application_code,
            legal_name: companyData.legal_name,
            first_name: first_name,
            second_name: second_name,
            father_last_name: companyData.pfae_last_name ?? "",
            mother_last_name: companyData.pfae_last_name_2 ?? "",
            reenter_NIP: true,
            authorization_label_response: true,
            loan_application_id: String(applicationData.id),
            pf: {
              mortgage_credit: values.prestamo_hipotecario,
              automotive_credit: values.credito_automotriz,
              credit_card: values.tarjeta_credito,
              last_four_digits: values.tarjeta,
            },
            address: {
              street: addressData.street,
              exteriorNumber: addressData.no_ext,
              interiorNumber: addressData.no_int,
              state: addressData.state_name,
              municipality: addressData.city_name,
              locality: addressData.city_name,
              postalCode: addressData.postal_code,
            },
          },
        };

        await buroValidator(payload_buro).unwrap();

        const next_status = STATUS_CATALOG.waiting_room.code;
        const next_url = STATUS_CATALOG.waiting_room.url;

        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `TOFU - ${accountUser.email}`,
        }).unwrap();

        dispatch(setLoanStatus(next_status));

        navigate(next_url, {
          replace: true,
        });
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  const handleOnCloseModalError = () => {
    setCloseModalError(true);
  };

  useEffect(() => {
    if (formik.values.authorize) {
      setCloseModal(false);
    } else {
      setCloseModal(true);
    }
  }, [formik.values.authorize]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={500}
              textAlign="center"
              color={theme?.palette?.primary?.dark}
            >
              Consulta Buró
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={8} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
                Mientras hacemos tu consulta al SAT como PFAE, necesitamos consultar tu Buró de Crédito como Persona
                Física.
              </Typography>
            </Box>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container rowSpacing={1}>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme?.palette?.primary?.dark }}
                    control={<Checkbox id="one" name="prestamo_hipotecario" onChange={formik.handleChange} />}
                    label="¿Tiene un préstamo hipotecario?"
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme?.palette?.primary?.dark }}
                    control={<Checkbox id="two" name="credito_automotriz" onChange={formik.handleChange} />}
                    label="¿Ha tenido un crédito automotriz?"
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme?.palette?.primary?.dark }}
                    control={<Checkbox id="three" name="tarjeta_credito" onChange={formik.handleChange} />}
                    label="¿Es titular de una tarjeta de crédito?"
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                {formik.values.tarjeta_credito && (
                  <Grid item xs={12}>
                    <TextField
                      id="tarjeta"
                      name="tarjeta"
                      label="Últimos 4 dígitos tarjeta de crédito"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      inputProps={{ maxLength: 4, inputMode: "numeric" }}
                      disabled={!formik.values.tarjeta_credito || formik.isSubmitting}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.match(/[^0-9]/)) {
                          event.preventDefault();
                          return;
                        }
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.tarjeta}
                      error={formik.touched.tarjeta && Boolean(formik.errors.tarjeta)}
                      helperText={formik.touched.tarjeta && formik.errors.tarjeta}
                    />
                  </Grid>
                )}
                <Grid item xs={12} py={2} mx={1}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomSwitch
                      id="authorize"
                      name="authorize"
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                    />
                    <CustomTextCiecBuro type="textBuro" />
                  </Stack>
                  {Boolean(formik.touched.authorize) && Boolean(formik.errors.authorize) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.authorize}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <CustomTextCiecBuro type="avisoTerminosTextBuro" />
              </Box>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={theme?.palette?.primary?.dark}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 78px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomDialog
        colorClose={theme?.palette?.primary?.light}
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600} color={theme?.palette?.primary?.dark}>
            Consulta a Buró de Crédito
          </Typography>
        }
        itemContent={<CustomTextCiecBuro type="modalBuro" />}
      />

      <CustomDialog
        colorClose={theme?.palette?.primary?.light}
        open={!closeModalError}
        onClose={handleOnCloseModalError}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600} color={"#F2704F"}>
            Datos Incorrectos
          </Typography>
        }
        itemContent={
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark}>
            <span>
              Los datos ingresados no coinciden con la <br /> información registrada en Buró de Crédito. <br /> Por
              favor, revisa la información <br /> proporcionada y vuelve a intentarlo.
            </span>
          </Typography>
        }
      />
    </>
  );
};

export default ConsultaBuroPfae;
