/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { useGetAddressTypeQuery } from "@api/catalogs";
import { usePostAddressMutation, useLazyGetAddressQuery } from "@api/address";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePostDocumentMutation } from "@api/documents";
import { UUID } from "crypto";
import { useGetFilesCatalogQuery } from "@api/catalogs";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { getDynamicUrl } from "@helpers/funciones";
import { STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";

interface Colonia {
  id: number;
  postal_code: string;
  name: string;
}

const AgendaVisita: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const img = getDynamicUrl("img_tofu/img_tofu_1.svg");
  const imgPuerta = getDynamicUrl("img_tofu/icon_puerta.svg");
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const companySection = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostDocument] = usePostDocumentMutation();
  const { data: fileCategoriesData } = useGetFilesCatalogQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [getDocumentByAppId, { data: dataDocuments }] = useLazyGetDocumentAppIdQuery();
  const [getAddressByPerson, { data: rawAddressData }] = useLazyGetAddressQuery();
  const { data: addressTypes } = useGetAddressTypeQuery();
  const [createAddress] = usePostAddressMutation();
  const [getByCp] = useLazyGetByCpQuery();

  const [fullAddress, setFullAddress] = useState<string>("");
  const [documentsRequired, setDocumentsRequired] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const capitalizeWords = (str: string) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    getDocumentByAppId({ clientDetailsId: applicationData.client_details_id, loanApplicationId: applicationData.id });
  }, [applicationData]);

  useEffect(() => {
    if (personalData.id) {
      getAddressByPerson({
        accountId: personalData.id,
      });
    }
  }, [getAddressByPerson, personalData.id]);

  useEffect(() => {
    if (rawAddressData && rawAddressData.length > 0 && addressTypes) {
      const confirmedOperationalAddressType = addressTypes.find((type) => type.code === "CDPA");
      const operationalAddressType = addressTypes.find((type) => type.code === "CDOP");

      const confirmedOperationalAddress = rawAddressData.find(
        (address) => address.address_type_id === confirmedOperationalAddressType?.id,
      );
      const operationalAddress = rawAddressData.find(
        (address) => address.address_type_id === operationalAddressType?.id,
      );

      const selectedAddress = confirmedOperationalAddress || operationalAddress;

      if (selectedAddress) {
        const { street, no_ext, no_int, cp, colonia_id } = selectedAddress;

        if (cp) {
          getByCp(cp)
            .unwrap()
            .then((data) => {
              if (data) {
                const coloniaName =
                  data.colonias.find((colonia: Colonia) => colonia.id === colonia_id)?.name || "Desconocida";

                const completeAddress = `${street} No. ${no_ext} ${
                  no_int ? `No Int. ${no_int}` : ""
                }, Col. ${coloniaName}, ${data.cities[0]?.name || ""}, ${cp}, ${data.states[0]?.name || ""}`;

                setFullAddress(capitalizeWords(completeAddress));
              }
            })
            .catch((error) => {
              console.error("Error fetching postal data:", error);
            });
        }
      }
    }
  }, [rawAddressData, addressTypes, getByCp]);

  const handleContinue = async () => {
    try {
      setIsLoading(true);
      const solicitudId = applicationData.id;
      if (solicitudId) {
        const existingFile = companySection?.files.find((file) => file.file_code === "DCD");
        const newFileCategory = fileCategoriesData?.find((category) => category.code === "DCDC");

        if (existingFile && dataDocuments) {
          const documentExists = dataDocuments.some(
            (doc: any) =>
              doc.file_category_id === newFileCategory?.id && doc.loan_application_id === applicationData.id,
          );
          if (!documentExists) {
            const newDocumentData = {
              loan_application_id: applicationData.id,
              file_category_id: newFileCategory?.id as UUID,
              client_details_id: applicationData.client_details_id as UUID,
              url: existingFile.url,
              file_name: existingFile.file_name,
            };

            await triggerPostDocument(newDocumentData).unwrap();
          }
        }

        const confirmedOperationalAddressType = addressTypes?.find((type) => type.code === "CDOC");
        const confirmedOperationalAddress = rawAddressData?.find(
          (address) => address.address_type_id === confirmedOperationalAddressType?.id,
        );

        if (!confirmedOperationalAddress) {
          const operationalAddressType = addressTypes?.find((type) => type.code === "CDOP");
          const operationalAddress = rawAddressData?.find(
            (address) => address.address_type_id === operationalAddressType?.id,
          );

          if (operationalAddress) {
            const updatedValues = {
              street: operationalAddress.street,
              no_ext: operationalAddress.no_ext,
              no_int: operationalAddress.no_int,
              colonia_id: operationalAddress.colonia_id,
              state_id: operationalAddress.state_id,
              city_id: operationalAddress.city_id,
              cp: operationalAddress.cp,
              client_details_id: personalData.id,
              country_id: 1,
              address_type_id: confirmedOperationalAddressType?.id || "",
            };

            await createAddress(updatedValues).unwrap();
          }
        }

        const { code: next_status, url: next_url } = STATUS_CATALOG.confirmacion_domicilio;

        await triggerPostNextStage({
          applicationId: solicitudId,
          statusCode: next_status,
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();

        navigate(next_url);
      }
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickModifyAddress = () => {
    if (applicationData.type === TAX_SYSTEMS.PFAE.code) {
      navigate("/producto/creditosimple/agenda_visita_pfae");
    } else {
      navigate("/producto/creditosimple/agenda_visita_pm");
    }
  };

  useEffect(() => {
    if (applicationData.type === TAX_SYSTEMS.PFAE.code) {
      setDocumentsRequired([
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    } else {
      setDocumentsRequired([
        "Caratula",
        "Acta constitutiva",
        "Actas adicionales",
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    }
  }, []);

  return (
    <Grid container sx={{ height: "calc(100vh - 200px)" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box mx={4}>
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Agenda tu visita ocular
          </Typography>

          <NavBarOcularVisit currentStep={1} />

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
            textAlign="center"
          >
            Bienvenido a los últimos pasos para obtener tu crédito, a continuación realizaremos una visita ocular y
            cotejo de documentos
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <img src={imgPuerta} width={isMobileOrTablet ? "100px" : "150px"} />
          </Box>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
            textAlign="center"
          >
            Tu ejecutivo se pondrá en contacto para agendar la visita a tu domicilio operativo
          </Typography>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
            textAlign="center"
          >
            {fullAddress}
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <Button variant="blue_outlined" onClick={handleClickModifyAddress}>
              Modifica tu domicilio
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: theme?.palette?.phoneInput?.main,
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
              mt: 4,
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={600}
              color={theme?.palette?.primary?.dark}
              textAlign="center"
            >
              Recuerda que el día de la visita deberás presentar los siguientes documentos
            </Typography>

            <List>
              {documentsRequired.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "15px" }}>
                    <Box width={12} height={12} mr={1} sx={{ backgroundColor: theme?.palette?.primary?.main }}></Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant={isMobileOrTablet ? "body2" : "body1"}>{item}</Typography>}
                    sx={{
                      fontSize: isMobileOrTablet ? "13px" : "16px",
                      color: theme?.palette?.primary?.dark,
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box mt={4} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleContinue}
              sx={{ width: isMobileOrTablet ? "100%" : "250px" }}
              disabled={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AgendaVisita;
