/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { HOSTNAMES } from "@helpers/constantes";

const ScriptsComponent: React.FC = () => {
  useEffect(() => {
    if (import.meta.env.VITE_ENV === "DEV") {
      return;
    }
    const hostname = window.location.hostname;
    const foundHost = HOSTNAMES.find(({ host }) => host === hostname);

    if (foundHost && foundHost.value === "finbe") {
      // Google Analytics
      const scriptGoogle = document.createElement("script");
      scriptGoogle.async = true;
      scriptGoogle.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GOOGLE_TAG_ID_FINBE}`;
      document.head.appendChild(scriptGoogle);

      scriptGoogle.onload = () => {
        const scriptInline = document.createElement("script");
        scriptInline.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${import.meta.env.VITE_GOOGLE_TAG_ID_FINBE}');
    `;
        document.head.appendChild(scriptInline);
      };
      // LinkedIn
      const linkedinScript = document.createElement("script");
      linkedinScript.type = "text/javascript";
      linkedinScript.innerHTML = `
        _linkedin_partner_id = "${import.meta.env.VITE_LINKEDIN_TAG_ID_FINBE}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `;
      document.head.appendChild(linkedinScript);

      // Hotjar
      const hotjarScript = document.createElement("script");
      hotjarScript.innerHTML = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${import.meta.env.VITE_HOTJAR_TAG_ID_FINBE},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(hotjarScript);

      // Meta Pixel
      const metaPixelScript = document.createElement("script");
      metaPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s) {
          if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s);
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${import.meta.env.VITE_META_PIXEN_TAG_ID_FINBE}');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(metaPixelScript);

      // Noscript for Meta Pixel
      const noscript = document.createElement("noscript");
      noscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${import.meta.env.VITE_META_PIXEN_TAG_ID_FINBE}&ev=PageView&noscript=1"/>
      `;
      document.body.appendChild(noscript);
    }
  }, []);

  return null;
};

export default ScriptsComponent;
