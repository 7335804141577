/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import {
  Alert,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { signIn, getCurrentUser, signOut, fetchAuthSession } from "aws-amplify/auth";

import { useAppDispatch } from "@store/store";
import { EMAIL, PASSWORD } from "@utils/expreciones";
import { CustomSwitch } from "@components/index";
import { useLazyGetSessionInfoQuery, useRequestPhoneCodeLoginMutation } from "@api/session";

import { getDynamicUrl, splitPhoneNumber } from "@helpers/funciones";

export const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const img = getDynamicUrl("right_1.svg");

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [triggerGetSessionInfo, sessionInfoResult] = useLazyGetSessionInfoQuery();
  const [requestPhoneCode] = useRequestPhoneCodeLoginMutation();
  const channel = useRef<BroadcastChannel | null>(null);

  const initialValues = {
    email: "",
    password: "",
  };

  const forceSignOut = async () => {
    try {
      await signOut();
      return true;
    } catch (error) {
      return false;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      password: yup
        .string()
        .required("Campo requerido")
        .matches(
          PASSWORD,
          "Formato incorrecto. Debe contener una letra mayúscula, número, carácter especial y más de 8 caracteres.",
        ),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          await forceSignOut();

          setLoading(true);
          setError(false);
          const signedResult = await signIn({
            username: values.email,
            password: values.password,
          });

          if (signedResult.isSignedIn && signedResult.nextStep.signInStep == "DONE") {
            localStorage.removeItem("isLoggedOut");
            channel.current?.postMessage("login");

            const session = await fetchAuthSession();
            const payload = session?.tokens?.accessToken?.payload || {};
            const userRole = String(payload["custom:role"] || "");
            const userPhone = String(payload["phone_number"] || "");

            if (userRole === "client") {
              const { phone_number, country_code } = splitPhoneNumber(userPhone);
              await requestPhoneCode({
                country_code,
                phone_number,
              }).unwrap();
              navigate("/validacion_codigo_login");
            } else {
              const currentUser = await getCurrentUser();
              await triggerGetSessionInfo(currentUser.userId).unwrap();
            }
          }
        } catch (error) {
          dispatch({ type: "CLEAN_STORE" });
          setError(true);
          await signOut();
          setLoading(false);
        }
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangeEmail = (value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue("email", low);
    setRememberMe(!(low == localStorage.getItem("email")));
  };

  const handleOnChangeRememberMe = (value: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(!value.target.checked);
  };

  useEffect(() => {
    if (sessionInfoResult.isSuccess) {
      if (rememberMe) {
        if (formik.values.email == localStorage.getItem("email")) {
          localStorage.removeItem("email");
        }
      } else {
        localStorage.setItem("email", formik.values.email);
      }

      setLoading(false);
      if (sessionInfoResult.isSuccess) {
        navigate(sessionInfoResult.data.data.current_screen.url, { state: { from: location } });
      }
    }
  }, [sessionInfoResult]);

  useEffect(() => {
    dispatch({ type: "CLEAN_STORE" });
    if (localStorage.getItem("email")) {
      formik.setFieldValue("email", localStorage.getItem("email"));
    }
  }, []);

  useEffect(() => {
    channel.current = new BroadcastChannel("session_channel");

    return () => {
      channel.current?.close();
    };
  }, []);

  const handleForgotPasswordClick = () => {
    navigate("/cambia_contraseña");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            mt: isMobileOrTablet ? 4 : 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant={isMobileOrTablet ? "h3" : "h2"}
              fontWeight={theme?.palette?.fontWeights?.bold}
              color={theme?.palette?.primary?.main}
            >
              Te damos la bienvenida
            </Typography>
          </Box>

          <Box mt={isMobileOrTablet ? 2 : 3}>
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
              Ingresa los datos para iniciar sesión
            </Typography>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => onChangeEmail(e.target.value)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="password"
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  sx={{ my: 2 }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{ color: theme?.palette?.primary?.main }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              {error && (
                <Box sx={{ mt: 2 }}>
                  <Alert variant="outlined" severity="error">
                    Credenciales incorrectas. Verifica tu correo electrónico y contraseña.
                  </Alert>
                </Box>
              )}
              <Box mt={isMobileOrTablet ? 2 : 4}>
                <Link onClick={handleForgotPasswordClick}>
                  <Typography textAlign={"center"} variant="body2">
                    Olvidé mi contraseña
                  </Typography>
                </Link>
              </Box>

              <Box textAlign={"center"} mt={isMobileOrTablet ? 2 : 4}>
                <FormControlLabel
                  control={<CustomSwitch onChange={handleOnChangeRememberMe} checked={!rememberMe} />}
                  label={"Recordar usuario en este dispositivo"}
                />
              </Box>

              <Box textAlign={"center"} mt={isMobileOrTablet ? 2 : 4}>
                <LoadingButton variant="default" type="submit" disabled={!formik.isValid || !formik.dirty || loading}>
                  Continuar
                </LoadingButton>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"} bgcolor={theme?.palette?.primary?.dark}>
          <img src={img} alt="" style={{ height: "calc(100vh - 76px)" }} />
        </Grid>
      )}
    </Grid>
  );
};
