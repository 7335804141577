/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { PayLoadPeps, PepsData, ApiResponsePeps, PepsPatchPayload } from "@interfaces/pesps";
import { baseApi } from "./baseApi";

const pepsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    potsPepsData: build.mutation<Response, PayLoadPeps>({
      query: (data) => ({
        url: `peps`,
        method: "POST",
        body: data,
      }),
    }),
    patchPepsData: build.mutation<Response, any>({
      query: (data: { id: UUID; body: PepsData }) => ({
        url: `peps/${data.id}`,
        method: "PATCH",
        body: {
          name: data.body.name,
          position: data.body.position,
          dependency: data.body.dependency,
          start_date: data.body.start_date,
          end_date: data.body.end_date,
          updated_at: data.body.updated_at,
        },
      }),
    }),
    getPepsByPerson: build.query<PepsData[], { personId: string; pepsId: string }>({
      query: ({ personId, pepsId }) => `peps?q=client_details_id:${personId}&pep_type_id:${pepsId}`,
      transformResponse: (response: ApiResponsePeps) => {
        return response.data.data;
      },
    }),
    getPepsByPersonaId: build.query<PepsData[], { idPersona: string }>({
      query: ({ idPersona }) => `peps?q=person_id:${idPersona}`,
      transformResponse: (response: ApiResponsePeps) => {
        return response?.data?.data || [];
      },
    }),
    patchPeps: build.mutation<Response, { id: string; body: PepsPatchPayload }>({
      query: (data) => ({
        url: `peps/${data.id}`,
        method: "PATCH",
        body: data.body,
      }),
    }),
    deletePeps: build.mutation<Response, { id: string }>({
      query: (data) => ({
        url: `peps/${data.id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePotsPepsDataMutation,
  useLazyGetPepsByPersonQuery,
  usePatchPepsDataMutation,
  usePatchPepsMutation,
  useLazyGetPepsByPersonaIdQuery,
  useDeletePepsMutation,
} = pepsApi;
