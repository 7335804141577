import { HOSTNAMES } from "@helpers/constantes";
import { Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const TerminosCondiciones = () => {
  const [avisoUrl, setAvisoUrl] = useState("");
  const [terminosUrl, setTerminosUrl] = useState("second");

  useEffect(() => {
    const hostname = window.location.hostname;
    const foundHost = HOSTNAMES.find(({ host }) => host === hostname);

    if (foundHost?.value === "lendia") {
      setAvisoUrl(String(import.meta.env.VITE_URL_AVISO_PRIVACIDAD));
      setTerminosUrl(String(import.meta.env.VITE_URL_TERM_CONDICIONES));
    }

    if (foundHost?.value === "finbe") {
      setAvisoUrl(String(import.meta.env.VITE_URL_AVISO_PRIVACIDAD_FINBE));
      setTerminosUrl(String(import.meta.env.VITE_URL_TERM_CONDICIONES_FINBE));
    }
  }, []);

  return (
    <Typography textAlign={"center"}>
      Al continuar estas aceptando nuestros{" "}
      <Link href={terminosUrl} target="_blank">
        <Typography component={"span"}>términos y condiciones</Typography>
      </Link>{" "}
      y{" "}
      <Link href={avisoUrl} target="_blank">
        <Typography component={"span"}>políticas de privacidad</Typography>
      </Link>
    </Typography>
  );
};
