import { Navigate, useLocation } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import { useEffect } from "react";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { pathname } = location;
        const publicRoutes = [
          "/producto/creditosimple/crear_cuenta",
          "/producto/creditosimple/registro_datos_personales",
        ];
        if (!publicRoutes.includes(pathname)) {
          await getCurrentUser();
        }
      } catch (error) {
        return <Navigate to="/login" state={{ from: location }} />;
      }
    };

    checkAuth();
  }, [location]);

  return children;
};
